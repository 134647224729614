import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import classNames from 'classnames';
import PhoneInput from 'react-phone-number-input';
import {Label} from 'common/components/Label';
import {useMutation} from 'react-query';
import {signInCustomerRequest} from 'api/customer.api';
import {Icon} from 'shared/icon/icon.component';
import {useRecaptcha} from 'common/hooks';

interface SendVerificationCodeFormFields {
  phone: string;
}

interface SendVerificationCodeFormProps extends Omit<React.HTMLProps<HTMLFormElement>, 'onSubmit'> {
  onSubmitted?: (formData: Omit<SendVerificationCodeFormFields, 'recaptchaToken'>) => void;
  defaultValues?: Pick<SendVerificationCodeFormFields, 'phone'>;
}

export const SendVerificationCodeForm = React.forwardRef<HTMLFormElement, SendVerificationCodeFormProps>(
  ({onSubmitted, defaultValues, ...formProps}, ref) => {
    const {formState, handleSubmit, control, reset} = useForm<SendVerificationCodeFormFields>({
      defaultValues,
    });

    const {executeRecaptcha} = useRecaptcha();

    const sendVerificationCodeMutation = useMutation<{verificationCodeExpiration: string}, Error, {phone: string}>({
      mutationFn: async (body) => {
        const recaptchaToken = await executeRecaptcha('send_verification_code');

        return signInCustomerRequest({...body, 'g-recaptcha-response': recaptchaToken});
      },
      mutationKey: 'send-verification-code',
      onSuccess: (_, requestPayload) => {
        onSubmitted?.(requestPayload);
      },
      onError: (error) => {
        console.error(error);
      },
    });

    const onSubmit = (formData: SendVerificationCodeFormFields) => sendVerificationCodeMutation.mutate({phone: formData.phone});

    const onReset = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      reset({phone: ''});
    };

    return (
      <form ref={ref} onReset={onReset} onSubmit={handleSubmit(onSubmit)} {...formProps}>
        <div className={classNames('kl-input-group', {'kl-input-group-danger': formState.errors.phone})}>
          <Label htmlFor="phone" className="tw-sr-only">
            Phone number
          </Label>
          <div className="tw-mt-1">
            <Controller
              name="phone"
              control={control}
              rules={{required: true}}
              render={(props: any) => (
                <PhoneInput
                  placeholder="Type in your phone number"
                  name="phone"
                  defaultCountry="US"
                  value={props.field.value}
                  onChange={props.field.onChange}
                />
              )}
            />
          </div>
          {formState.errors.phone ? <p className="kl-input-helper">Phone is required</p> : null}
        </div>

        {sendVerificationCodeMutation.isError && (
          <div className="kl-callout kl-callout-danger">
            <Icon icon="error" />
            <div className="kl-callout-body">An error ocurred sending the verification code</div>
          </div>
        )}
      </form>
    );
  }
);

SendVerificationCodeForm.displayName = 'SendVerificationCodeForm';
