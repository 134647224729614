import React, {useEffect, useState} from 'react';
import {useAppContext} from 'contexts/app.context';
import {PaymentMethod} from 'models/payment-method.model';
import {ReactComponent as CardIcon} from 'assets/icons/card.icon.svg';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
} from 'common/components/Dialog';
import {Button} from 'common/components/Button';

import {CreatePaymentMethodDialog} from './CreatePaymentMethodDialog';
import {SelectPaymentMethodTabs, useSelectPaymentMethodTabs} from './SelectPaymentMethodTabs';
import {getPaymentMethodActiveTab} from 'payment-methods/utils';

interface SelectPaymentMethodDialogProps extends DialogProps {
  paymentMethods: PaymentMethod[];
  onContinue?: () => void;
}

export function SelectPaymentMethodDialog({onContinue, paymentMethods, ...dialogProps}: SelectPaymentMethodDialogProps) {
  const {location, cartReducer, paymentGateway, cart} = useAppContext();
  const [isCreatePaymentMethodDialogOpen, setIsCreatePaymentMethodDialogOpen] = useState(false);

  const {activeTab, setActiveTab, roomChargeFormController, selectPaymentMethodFormController} = useSelectPaymentMethodTabs({
    onContinue,
    paymentMethods,
  });

  useEffect(() => {
    if (dialogProps.open) {
      setActiveTab(getPaymentMethodActiveTab(cart?.payment, cart?.roomGuestLastName, cart?.roomNumber));
    }
  }, [dialogProps.open]);

  const onPaymentMethodCreated = (paymentMethod: PaymentMethod) => {
    cartReducer.setPayment({
      cardBrand: paymentMethod.cardBrand,
      cardDigits: paymentMethod.cardLastFourDigits,
      cardName: paymentMethod.cardholderName,
      stripePaymentMethodId: paymentGateway === 'stripe' ? paymentMethod.stripePaymentMethodId : undefined,
      freedompayPaymentMethodId: paymentGateway === 'freedompay' ? paymentMethod.freedompayToken : undefined,
      isWallet: false,
    });
    setIsCreatePaymentMethodDialogOpen(false);
    onContinue?.();
  };

  return (
    <>
      <Dialog {...dialogProps}>
        <DialogContent>
          <DialogHeader className="tw-items-center tw-mb-6">
            <div className="tw-pt-4 tw-pb-6">
              <CardIcon />
            </div>
            <DialogTitle>Payment method</DialogTitle>
            <DialogDescription>Choose or add a payment method</DialogDescription>
          </DialogHeader>
          <SelectPaymentMethodTabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            roomChargeFormController={roomChargeFormController}
            selectPaymentMethodFormController={selectPaymentMethodFormController}
            paymentMethods={paymentMethods}
            allowsRoomPayment={Boolean(location?.section.store?.settings?.allowsRoomPayment)}
            paymentGateway={paymentGateway}
          />
          <DialogFooter className="tw-space-y-2">
            {activeTab === 'card' && (
              <>
                <Button onClick={() => setIsCreatePaymentMethodDialogOpen(true)} variant="ghost">
                  {/* <PlusIcon className="tw-w-4 tw-h-4 tw-mr-2" /> */}
                  Add new payment method
                </Button>
                <Button size="lg" form={selectPaymentMethodFormController.formId} type="submit">
                  Continue
                </Button>
              </>
            )}

            {activeTab === 'room-charge' && (
              <Button size="lg" form={roomChargeFormController.formId}>
                Continue
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <CreatePaymentMethodDialog
        open={isCreatePaymentMethodDialogOpen}
        onOpenChange={setIsCreatePaymentMethodDialogOpen}
        onCreated={onPaymentMethodCreated}
      />
    </>
  );
}
