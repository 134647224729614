import React, {useEffect, useState} from 'react';

import {PaymentRequestButtonElement, useStripe} from '@stripe/react-stripe-js';
import {PaymentRequest} from '@stripe/stripe-js';
import {createStripeWalletPaymentIntent} from 'api/payment.api';
import {useAppContext} from 'contexts/app.context';
import {useStateRef} from 'hooks/use-state-ref.hook';
import {round} from 'lodash';
import {WalletPaymentIntentData} from 'models/payment-method.model';

import {CountryCode, Currency} from 'utils/types';

interface StripeWalletPaymentButton {
  onPaymentSuccess: (walletPaymentData: WalletPaymentIntentData, cardholderName?: string) => void;
  onPaymentFailed: () => void;
  country: CountryCode;
  currency: Currency;
  amount: number;
  fee: number;
}

export const StripeWalletPaymentButton: React.FC<StripeWalletPaymentButton> = ({
  onPaymentSuccess,
  onPaymentFailed,
  country = 'US',
  currency = 'USD',
  amount,
  fee,
}) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const {customer, location, event} = useAppContext();

  const [, setAmountRef, amountRef] = useStateRef(amount);

  useEffect(() => {
    if (stripe) {
      const paymentRequest = stripe.paymentRequest({
        country,
        currency: currency.toLowerCase(),
        total: {
          label: 'Your Kontactless order',
          amount: round(amount * 100),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      paymentRequest.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(paymentRequest);

          paymentRequest.on('paymentmethod', async (e) => {
            try {
              const {clientSecret}: any = await createStripeWalletPaymentIntent(customer!.token, {
                amount: amountRef.current,
                currency,
                fee,
                storeId: (location?.section.storeId || event?.storeId)!,
              });

              const {paymentMethod} = e;
              const {error, paymentIntent} = await stripe.confirmCardPayment(
                clientSecret,
                {payment_method: paymentMethod.id},
                {handleActions: false}
              );

              if (error) {
                e.complete('fail');
                console.error(error);
                onPaymentFailed();
              } else {
                e.complete('success');

                // TODO: Set payment method data
                onPaymentSuccess(
                  {
                    stripePaymentIntentId: paymentIntent?.id!,
                    stripePaymentStatus: paymentIntent?.status,
                  },
                  paymentMethod.billing_details.name!
                );
              }
            } catch (error) {
              console.error(error);
              onPaymentFailed();
            }
          });
        }
      });
    }
  }, [stripe]);

  useEffect(() => {
    try {
      setAmountRef(amount);
      paymentRequest?.update({
        total: {
          label: 'Your Kontactless order',
          amount: Math.round(amount * 100),
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, [amount]);

  if (!paymentRequest) {
    return null;
  }

  return (
    <PaymentRequestButtonElement
      options={{
        paymentRequest,
        style: {
          paymentRequestButton: {
            height: '50px',
            theme: 'dark',
          },
        },
      }}
    />
  );
};
