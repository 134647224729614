import React from 'react';

import {ReactComponent as AsapPickupIcon} from 'assets/icons/asap-pickup.icon.svg';

import {Button} from 'common/components/Button';

import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogProps, DialogTitle} from 'common/components/Dialog';
import {LocationDetailsForm} from './LocationDetailsForm';

interface LocationDetailsDialogProps extends DialogProps {
  onContinue?: () => void;
  location: {name: string; attributes: {name: string; required: boolean}[]};
}

export function LocationDetailsDialog({onContinue, location, ...dialogProps}: LocationDetailsDialogProps) {
  return (
    <Dialog {...dialogProps}>
      <DialogContent>
        <DialogHeader className="tw-items-center tw-mb-6">
          <div className="tw-pt-4 tw-pb-6">
            <AsapPickupIcon />
          </div>
          <DialogTitle>Please, enter your location details</DialogTitle>
        </DialogHeader>

        <LocationDetailsForm
          id="location-details-form"
          locationAttributes={location.attributes}
          locationType={location.name}
          prefixInputLabels
          onSubmitted={() => onContinue?.()}
        />

        <DialogFooter>
          <Button size="lg" form="location-details-form">
            Continue
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
