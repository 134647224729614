import React, {ReactElement} from 'react';
import {Dialog as MuiDialog} from '@material-ui/core';
import classNames from 'classnames';
import {Icon} from 'shared/icon/icon.component';

export interface AlertProps {
  title?: string;
  body?: ReactElement;
  isOpen: boolean;
  showCloseButton?: boolean;
  className?: string;
  onClose?: () => void;
}

export const Alert: React.FC<AlertProps> = ({title, body, showCloseButton = true, isOpen = false, onClose, className}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <MuiDialog open={isOpen} onClose={onClose} PaperProps={{className: classNames('kl-alert', className)}}>
      {!!title && <h1>{title}</h1>}
      {showCloseButton && <Icon className="close-handle handle" icon="cross" onClick={() => onClose?.()} />}
      <div className="kl-alert-body">{body}</div>
    </MuiDialog>
  );
};
