import React from 'react';

interface BadgeProps {
  text: string;
}

export const Badge: React.FC<BadgeProps> = ({text, ...props}) => {
  return (
    <span {...props} className={'badge badge--transparent'}>
      {text}
    </span>
  );
};
