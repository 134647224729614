import {DateTime} from 'luxon';
import {useState} from 'react';
import {AppState} from 'contexts/app.context';
import {Customer} from 'models/customer.model';
import jwtDecode from 'jwt-decode';

export function useLocalStorage<T extends Partial<AppState>>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      const storedValue = item ? (JSON.parse(item) as T) : initialValue;

      if (hasCartExpired(storedValue.lastUpdate)) {
        storedValue.cart = {
          items: [],
          total: 0,
          totalWithDiscount: undefined,
          customerNotes: '',
          pickupName: '',
          pickupInfo: '',
          roomNumber: '',
          roomGuestLastName: '',
          couponCode: '',
          selectedTip: {value: 0, type: 'initial'},
        };
      }

      if (hasLocationExpired(storedValue.lastUpdate)) {
        storedValue.location = undefined;
      }

      if (hasCustomerTokenExpired(storedValue.customer?.token)) {
        storedValue.customer = undefined;
      }

      return storedValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };
  return [storedValue, setValue] as const;
}

const hasCartExpired = (lastUpdate?: string) => {
  return lastUpdate && DateTime.fromISO(lastUpdate).diffNow('minutes').minutes > 20;
};

const hasLocationExpired = (lastUpdate?: string) => {
  return lastUpdate && DateTime.fromISO(lastUpdate).diffNow('minutes').minutes > 60;
};

const hasCustomerTokenExpired = (token?: string) => {
  if (token) {
    const customer = jwtDecode(token) as Customer & {
      exp: number;
    };

    return DateTime.fromSeconds(customer.exp) <= DateTime.now();
  }

  return true;
};
