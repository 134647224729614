import React from 'react';
import {ReactComponent as LocationInvalidIcon} from 'assets/icons/location-invalid.icon.svg';
import {useHistory} from 'react-router-dom';
import {paths} from 'utils/paths';
import {NonIdealState} from 'shared/non-ideal-state.component';

const NotFoundScene: React.FC = () => {
  const history = useHistory();

  return (
    <section className="scene scene-not-found">
      <NonIdealState
        icon={<LocationInvalidIcon />}
        title="We couldn't find your location"
        description="Please, scan the QR code again"
        action={{
          text: 'Scan QR code',
          callback: () => history.push(paths.qrScanner.route),
        }}
      />
    </section>
  );
};

export default NotFoundScene;
