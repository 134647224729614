import {Customer, CustomerSignInRequest, CustomerUpdateRequest, CustomerVerifyCodeRequest} from 'models/customer.model';
import {callApi} from './api';

export const signInCustomerRequest = async (body: CustomerSignInRequest): Promise<{verificationCodeExpiration: string}> => {
  return callApi<{verificationCodeExpiration: string}>(`/customers`, {
    method: 'POST',
    body: JSON.stringify(body),
  }).then(({data}) => data);
};

export const verifyCustomerCodeRequest = async (body: CustomerVerifyCodeRequest): Promise<Customer> => {
  return callApi<Customer>(`/customers/verify`, {
    method: 'POST',
    body: JSON.stringify(body),
  }).then(({data}) => data);
};

export const updateCustomerRequest = async (token: string, body: CustomerUpdateRequest): Promise<Customer> => {
  return callApi<Customer>(`/customers`, {
    token,
    method: 'PATCH',
    body: JSON.stringify(body),
  }).then(({data}) => data);
};
