import React, { ReactElement } from 'react';
import {Button} from 'shared/button.component';

interface NonIdealStateProps {
  icon?: ReactElement,
  title: string;
  description: string;
  action?: {
    text: string;
    callback: () => any;
  }
}

export const NonIdealState: React.FC<NonIdealStateProps> = ({icon, title, description, action}) => {
  return (
    <div className="non-ideal-state">
      {!!icon && <div className="non-ideal-state__icon">{icon}</div>}
      <h1 className="non-ideal-state__title">{title}</h1>
      <p className="non-ideal-state__description">{description}</p>
      {!!action && <div className="non-ideal-state__action">
        <Button onClick={action.callback} text={action.text} />
      </div>}
    </div>
  );
};
