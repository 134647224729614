import {DateTime, Interval} from 'luxon';
import {Controller, useForm} from 'react-hook-form';
import {ReactComponent as WatchIcon} from 'assets/icons/watch.icon.svg';
import {useAppContext} from 'contexts/app.context';
import {Button} from 'common/components/Button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
} from 'common/components/Dialog';
import {Tabs, TabsContent, TabsList, TabsTrigger} from 'common/components/Tabs';
import {RadioGroup, RadioGroupItem} from 'common/components/RadioGroup';
import {cn} from 'common/utils';
import {isDateInOpeningHours} from 'utils/helpers.utils';

interface ScheduleOrderDialogProps extends DialogProps {
  onContinue?: () => void;
}

interface PickupDetailsFormFields {
  scheduleTime: string;
}

export default function ScheduleOrderDialog({onContinue, ...dialogProps}: ScheduleOrderDialogProps) {
  const {location, cartReducer} = useAppContext();

  const minutesToShowScheduledOrders = location?.section.minutesToShowScheduledOrders ?? 20;

  const {
    control,
    register,
    handleSubmit,
    formState: {isValid, errors},
  } = useForm<PickupDetailsFormFields>({
    mode: 'onBlur',
  });

  const initialDate = DateTime.now();
  const roundedUp = Math.ceil(initialDate.minute / 30) * 30;

  const allSlots = Interval.fromDateTimes(
    //
    initialDate.plus({minutes: minutesToShowScheduledOrders}).set({minute: roundedUp}),
    DateTime.now().startOf('hour').plus({days: 7})
  )
    .splitBy({minutes: 30})
    .map((i) => i.start);

  const availableSlots = allSlots.filter((slot) => isDateInOpeningHours(slot, location?.section.openingHours ?? []));

  const availableSlotsPerDay = availableSlots.reduce((acc, slot) => {
    const slotDate = slot.toISODate();
    if (acc.has(slotDate)) {
      const currentSlotsForDate = acc.get(slotDate) ?? [];
      currentSlotsForDate.push(slot);
      acc.set(slotDate, currentSlotsForDate);
    } else {
      acc.set(slotDate, [slot]);
    }
    return acc;
  }, new Map<string, DateTime[]>());

  const nextSevenDays = Interval.fromDateTimes(DateTime.now(), DateTime.now().plus({days: 7}))
    .splitBy({days: 1})
    .map((i) => i.start);

  const onContinueButtonClick = (pickupDetailsFormData: PickupDetailsFormFields) => {
    const {scheduleTime} = pickupDetailsFormData;
    const dateTime = DateTime.fromISO(scheduleTime);
    const formattedDate = `${dateTime.toISODate()}T${dateTime.toFormat('HH:mm:ss')}`;

    if (!isValid) {
      return null;
    }

    cartReducer.setScheduleOrderDetails(formattedDate);
    onContinue?.();
  };

  return (
    <Dialog {...dialogProps}>
      <DialogContent className="tw-overflow-y-auto no-scrollbar md:tw-max-h-[700px]  tw-py-0">
        <DialogHeader className="tw-items-center">
          <div className="tw-pt-4 tw-pb-6">
            <WatchIcon />
          </div>
          <DialogTitle>Schedule your order</DialogTitle>
          <DialogDescription>When should your order be ready?</DialogDescription>
        </DialogHeader>

        <Tabs>
          {/* Tabs */}
          <div className="tw-bg-white tw-sticky tw-top-0 tw-pt-6">
            <TabsList className="tw-w-full tw-mb-6  ">
              {nextSevenDays.map((date) => (
                <TabsTrigger
                  key={date.toISODate()}
                  disabled={!availableSlotsPerDay.has(date.toISODate())}
                  value={date.toISODate()}
                >
                  <p>{date.toFormat('ccc') + ' ' + date.toFormat('dd')}</p>
                </TabsTrigger>
              ))}
            </TabsList>
          </div>
          <div>
            {[...availableSlotsPerDay.entries()].map(([date, slots]) => (
              <TabsContent value={date} key={date}>
                <form>
                  <Controller
                    control={control}
                    rules={{required: 'Please select a schedule time'}}
                    name="scheduleTime"
                    defaultValue={slots[0].toISO()}
                    render={({field}) => {
                      return (
                        <RadioGroup
                          className="tw-grid md:tw-grid-cols-1 tw-grid-cols-1 tw-gap-4"
                          value={field.value}
                          onValueChange={(selectedTime: string) => {
                            field.onChange(selectedTime);
                          }}
                        >
                          {slots.map((slot) => {
                            const value = slot.toISO();

                            return (
                              <div key={value}>
                                <label
                                  className={cn(
                                    'tw-flex tw-cursor-pointer tw-rounded-lg tw-p-3 focus:tw-outline-none tw-justify-center',
                                    field.value === value
                                      ? 'tw-border-none tw-bg-blue-700 tw-text-white'
                                      : 'tw-border tw-border-gray-300tw-bg-gray-50'
                                  )}
                                >
                                  <RadioGroupItem
                                    checked={field.value === value}
                                    className="tw-group"
                                    value={value}
                                    key={value}
                                    id={value}
                                    {...register('scheduleTime', {
                                      required: true,
                                      validate: (selectedTime) => {
                                        const now = DateTime.local();
                                        const pickupDateTime = DateTime.fromISO(selectedTime);
                                        const minutesToShowScheduledOrders = location?.section.minutesToShowScheduledOrders || 20;
                                        if (pickupDateTime.diff(now).as('minutes') < minutesToShowScheduledOrders) {
                                          return `Pickup time should be at least ${minutesToShowScheduledOrders} minutes from now`;
                                        }
                                      },
                                    })}
                                  />
                                  {slot.toFormat('t')}
                                </label>
                                {errors.scheduleTime ? <p className="kl-input-helper">{errors.scheduleTime.message}</p> : null}
                              </div>
                            );
                          })}
                        </RadioGroup>
                      );
                    }}
                  ></Controller>
                </form>
              </TabsContent>
            ))}
          </div>
        </Tabs>
        <DialogFooter className="tw-sticky tw-bottom-0 tw-bg-white tw-py-6">
          <Button size="lg" onClick={handleSubmit(onContinueButtonClick)}>
            Continue
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
