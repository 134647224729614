import {groupBy, sortBy} from 'lodash';
import {DateTime} from 'luxon';
import {CartLocationDetails} from 'models/cart.model';
import {Customer} from 'models/customer.model';
import {Discount} from 'models/discount.model';
import {Location} from 'models/location.model';
import {OrderItem} from 'models/order.model';
import {Product, ProductVersion} from 'models/product.model';
import {Store} from 'models/store.model';
import {DayOfWeek, OrderStatus, WalletBrand} from './types';
import storeGenericImage from '../assets/store-generic.svg';
import {Timeframe} from 'models/timeframe.model';

export const isSentryEnabled = (): boolean => {
  // TODO: Disable Sentry in development
  return ['development', 'pre-production', 'production'].includes(process.env.REACT_APP_NODE_ENV ?? '');
};

export function getStoreImageUrl(store: Store | undefined) {
  return store?.imageUrl ?? store?.company?.imageUrl ?? storeGenericImage;
}

export const getGoogleMapsAddressLink = (address: string): string => {
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
};

export const slugify = (s: string): string => {
  return s
    .replace(/[^a-z0-9\s]/gi, '')
    .replace(/\s\s+/g, '')
    .replace(/\s/g, '-')
    .trim()
    .toLowerCase();
};

export const getOrderStatusLabel = (status: OrderStatus, isPickup?: boolean): string => {
  switch (status) {
    case 'received':
      return 'Received';
    case 'preparing':
      return 'Preparing';
    case 'ready':
      return 'Ready';
    case 'delivered':
      return isPickup ? 'Picked up' : 'Delivered';
    case 'cancelled-by-store':
    case 'cancelled-by-customer':
      return 'Cancelled';
    case 'partially-refunded':
    case 'refunded':
      return 'Refunded';
  }
};

export const sortOrderItems = (items?: OrderItem[]): OrderItem[] => {
  const groupedLines = groupBy(items, ({type}) => type);

  let result: OrderItem[] = [];

  result = result.concat(groupedLines['product'] ?? []);
  delete groupedLines['product'];

  result = result.concat(groupedLines['discount'] ?? []);
  delete groupedLines['discount'];

  result = result.concat(groupedLines['tax'] ?? []);
  delete groupedLines['tax'];

  result = result.concat(groupedLines['tip'] ?? []);
  delete groupedLines['tip'];

  result = result.concat(groupedLines['surcharge'] ?? []);
  delete groupedLines['surcharge'];

  result = result.concat(groupedLines['fee'] ?? []);
  delete groupedLines['fee'];

  result = result.concat(groupedLines['refund'] ?? []);
  delete groupedLines['refund'];

  result = result.concat(
    sortBy(
      Object.keys(groupedLines)
        .map((type) => groupedLines[type])
        .flat(),
      ({id}) => id
    )
  );

  return result;
};

export const canElementBeScrolledToBottom = (element: HTMLElement): boolean => {
  return element.scrollHeight - element.clientHeight !== element.scrollTop;
};

export const getDayOfWeekLabel = (day: DayOfWeek): string => {
  switch (day) {
    case 'monday':
      return 'Monday';
    case 'tuesday':
      return 'Tuesday';
    case 'wednesday':
      return 'Wednesday';
    case 'thursday':
      return 'Thursday';
    case 'friday':
      return 'Friday';
    case 'saturday':
      return 'Saturday';
    case 'sunday':
      return 'Sunday';
    default:
      return day;
  }
};

export const getWalletBrandLabel = (walletBrand: WalletBrand) => {
  if (walletBrand === 'google-pay') {
    return 'Google Pay';
  } else if (walletBrand === 'apple-pay') {
    return 'Apple Pay';
  }
};

export const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(() => resolve(undefined), ms));
};

export const getApplyingPromotion = (discounts: Discount[], menuCategoryProductId?: number): Discount | undefined => {
  for (const discount of discounts) {
    // Check is enabled
    if (!discount.isEnabled) {
      continue;
    }

    // Check it is a promotion
    if (!['promotion-flat-price'].includes(discount.type)) {
      continue;
    }

    // Check it hasn't expired
    if (discount.endsAt && DateTime.fromISO(discount.endsAt) < DateTime.local()) {
      continue;
    }

    if (menuCategoryProductId != null && !discount.menuCategoryProducts?.find(({id}) => id === menuCategoryProductId)) {
      // Check this product is included in the promotion
      continue;
    }

    return discount;
  }

  return undefined;
};

export const getPriceWithDiscount = (product: Product | ProductVersion, discount: Discount): number | undefined => {
  const discountAmount = Math.max(product.price - discount.discount, 0);

  if (product.price !== product.price - discountAmount) {
    return product.price - discountAmount; // Only one discount applies at a time
  }

  return undefined;
};

export const getPriceText = (price: number): string => {
  return `${price < 0 ? '- ' : ''}$${Math.abs(price).toFixed(2)}`;
};

export const getLocationName = (locationDetails?: CartLocationDetails) => {
  if (locationDetails) {
    const {type, attributes} = locationDetails;

    if (attributes.length === 1) {
      return `${type} ${attributes[0].value}`;
    }

    return `${type} `.concat(attributes.map(({name, value}) => `${name} ${value}`).join(', '));
  }
};

export function extractLocationAttributes(location: Location) {
  const {name, locationType} = location;
  const locationAttributesLines = name!.replace(`${locationType?.name} `, '').split(', ');
  const res: Array<{name: string; value: string}> = [];

  for (const locationAttributeLine of locationAttributesLines) {
    for (const attribute of locationType?.attributes!) {
      if (locationAttributeLine.indexOf(attribute.name) === 0) {
        const name = locationAttributeLine.slice(0, attribute.name.length).trim();
        const value = locationAttributeLine.slice(attribute.name.length).trim();
        if (!!name && !!value) {
          res.push({name, value});
        }
      }
    }
  }

  return res;
}

export const getCustomerFullName = (customer?: Customer) =>
  customer ? `${customer?.firstName} ${customer?.lastName}`.trim() : '';

export function isDateInOpeningHours(date: DateTime, openingHours: Timeframe[]): boolean {
  let isWithinOpeningHours = false; // assume the date is not within any opening hour

  if (!openingHours.length) {
    isWithinOpeningHours = true;
  }

  for (const openingHour of openingHours) {
    const {startTime, endTime, daysOfWeek, startDate, endDate} = openingHour;
    // if the date is not in the current opening hour, continue to  next one
    if (
      (!startDate || (startDate && DateTime.fromISO(startDate) <= date)) &&
      (!endDate || (endDate && DateTime.fromISO(endDate) >= date)) &&
      daysOfWeek.includes(date.toFormat('cccc').toLowerCase() as DayOfWeek) &&
      date.toFormat('HH:mm:ss') > startTime &&
      (endTime === '00:00:00' || date.toFormat('HH:mm:ss') < endTime)
    ) {
      isWithinOpeningHours = true; // if we get here, the date is within at least one opening hour
      break;
    }
  }

  return isWithinOpeningHours;
}
