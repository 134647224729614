import React from 'react';

import {DateTime} from 'luxon';
import {Timeframe} from 'models/timeframe.model';
import {TIME_FORMAT} from 'utils/constants';
import {getDayOfWeekLabel} from 'utils/helpers.utils';

import {DayOfWeek} from 'utils/types';

interface TimeframeRowProps {
  day: DayOfWeek;
  timeframes: Timeframe[];
}

export const TimeframeRow: React.FC<TimeframeRowProps> = ({day, timeframes}) => {
  const isOpenAllDay = !!timeframes.find(({startTime, endTime}) => startTime === '00:00:00' && endTime === '00:00:00');
  const isClosed = timeframes.length === 0;

  return (
    <div className="timeframe-row">
      <div className="column-day">{getDayOfWeekLabel(day)}</div>
      <div className="column-timeframes">
        {isOpenAllDay ? <p className="day-open">Open all day</p> : null}
        {isClosed ? <p className="day-closed">Closed all day</p> : null}
        {!isOpenAllDay && !isClosed
          ? timeframes.map((timeframe) => (
              <p key={timeframe.id}>
                {getFormattedTime(timeframe.startTime)} to {getFormattedTime(timeframe.endTime)}
              </p>
            ))
          : null}
      </div>
    </div>
  );
};

const getFormattedTime = (time: string): string => {
  return DateTime.fromFormat(time, 'HH:mm:ss').toFormat(TIME_FORMAT);
};
