import React, {useEffect} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useAppContext} from 'contexts/app.context';
import {Order, OrderCreateRequest} from 'models/order.model';
import {createOrderRequest, getOrderSummaryRequest, getUpsellingItemsRequest} from 'api/order.api';

import {isDateInOpeningHours, slugify} from 'utils/helpers.utils';
import {paths} from 'utils/paths';
import {Redirect, useHistory} from 'react-router-dom';
import {fetchPaymentMethods} from 'api/payment.api';

import {Checkout} from 'checkout/components/Checkout';
import {Tip} from 'checkout/components/TipSelector';
import {useStripeWalletPaymentMethod} from 'checkout/components/stripe/useStripeWalletPaymentMethod';
import {useFreedompayWalletPaymentMethod} from 'checkout/components/freedompay/useFreedompayWalletPaymentMethod';
import {PaymentGateway} from 'models/payment-method.model';
import {TipsConfiguration} from 'models/tips.configuration.model';
import {DateTime} from 'luxon';
import {useToast} from '../../hooks/use-toast.hook';
import {useRecaptcha} from 'common/hooks';

function useWalletPaymentMethod({paymentGateway}: {paymentGateway?: PaymentGateway | null}) {
  const stripePaymentMethod = useStripeWalletPaymentMethod();
  const freedompayWalletPaymentMethod = useFreedompayWalletPaymentMethod();

  if (paymentGateway === 'stripe') {
    return stripePaymentMethod;
  }

  if (paymentGateway === 'freedompay') {
    return freedompayWalletPaymentMethod;
  }

  return undefined;
}

export function CheckoutPage() {
  const history = useHistory();
  const queryClient = useQueryClient();
  const {cart, cartReducer, customer, location, event, paymentGateway} = useAppContext();
  const toast = useToast();
  const {executeRecaptcha} = useRecaptcha();

  const timezone = (location?.section?.store?.timezone ?? event?.store?.timezone)!;

  const isOrderingAsapAllowed =
    location?.section?.openingHours &&
    location?.section?.store?.timezone &&
    isDateInOpeningHours(DateTime.local().setZone(location?.section?.store?.timezone), location?.section?.openingHours);

  const walletPaymentMethod = useWalletPaymentMethod({paymentGateway});

  const menuIds = location?.section?.menus?.map((menu) => menu.id) ?? event?.menus?.map((menu) => menu.id) ?? [];
  const areOptionalTipsEnabled = location?.section?.areOptionalTipsEnabled ?? event?.areOptionalTipsEnabled ?? false;
  const arePromotionsEnabled = location?.section?.arePromotionsEnabled ?? event?.hasActiveCoupons ?? false;
  const tipsConfiguration = location?.section?.tipsConfiguration ?? event?.tipsConfiguration;

  // Remote data
  const paymentMethodsQuery = useQuery(['payment-methods', paymentGateway, customer?.id], {
    queryFn: () => fetchPaymentMethods(customer!.token, paymentGateway!),
    enabled: !!customer && (!!location || !!event),
  });

  useEffect(() => {
    const tipDefaultValue: Tip | undefined =
      !areOptionalTipsEnabled || !tipsConfiguration
        ? undefined
        : {value: tipsConfiguration.defaultTipValue || tipsConfiguration.tip1, type: 'default'};

    if (!!tipDefaultValue && !cart?.selectedTip) {
      cartReducer?.setSelectedTip(tipDefaultValue);
    }
  }, [areOptionalTipsEnabled, tipsConfiguration]);

  const paymentMethods = walletPaymentMethod
    ? [walletPaymentMethod].concat(paymentMethodsQuery.data ?? [])
    : paymentMethodsQuery.data ?? [];

  const orderSummaryQuery = useQuery({
    queryKey: ['order-summary', cart?.selectedTip?.value, cart?.couponCode],
    queryFn: () => {
      return getOrderSummaryRequest({
        locationId: location?.type === 'location' ? location.id : undefined,
        locationGroupId: location?.type === 'location-group' ? location.id : undefined,
        eventId: !!event ? event.id : undefined,
        tip: cart!.selectedTip?.value ?? 0,
        couponCode: cart!.couponCode,
        customerNotes: cart?.customerNotes || '',
        type: location?.section.orderingFlowType === 'pickup' ? 'takeaway' : 'dine-in',
        items: cart!.items.map((item) => ({
          productId: item.product.id,
          menuCategoryProductId: item.menuCategoryProductId,
          quantity: item.quantity,
          customerNotes: item.customerNotes,
          addedInUpselling: item.addedInUpselling,
          modifierGroups: item.modifierGroups.map((group) => ({
            modifierGroupId: group.modifierGroupId,
            modifiers: group.modifiers.map((modifier) => ({
              modifierId: modifier.modifierId,
            })),
          })),
        })),
      });
    },
    enabled: (!!event || !!location) && !!cart?.items.length,
    keepPreviousData: true,
    onError: (response: any) => {
      if (response.error.message === 'invalid_coupon_code') {
        cartReducer.setCouponCode(undefined);
        toast('The promo code entered is not valid', 'error');
      }
      if (response?.error?.message) {
        toast('An error ocurred fetching the order summary. Please, reload the page.', 'error');
      }
    },
  });

  const {data: orderSummary} = orderSummaryQuery;
  const menuCategoryProductIds = cart?.items.map((item) => item.menuCategoryProductId).toString() || '';

  const upsellingItemsQuery = useQuery({
    queryKey: ['upselling-items', menuCategoryProductIds],
    queryFn: () => getUpsellingItemsRequest(menuCategoryProductIds.toString(), menuIds.toString()),
  });

  useEffect(() => {
    orderSummaryQuery.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart?.items]);

  const createOrderMutation = useMutation<Order, Error, OrderCreateRequest>(
    async (body) => {
      const recaptchaToken = await executeRecaptcha('create_order');
      return createOrderRequest(customer!.token, body, recaptchaToken);
    },
    {
      onSuccess: (order) => {
        history.push(paths.checkoutSuccess.generatePath(order.id));
      },
      onError: (response: any) => {
        console.error(response);
        queryClient.invalidateQueries(['location']);
        queryClient.invalidateQueries(['order-summary']);
        toast('An error ocurred placing your order, please try again.', 'error');
      },
    }
  );

  const menuHref = !!event
    ? paths.event.generatePath(event.id)
    : paths.location.generatePath(slugify(location?.section?.store?.name!));

  if (!cart?.items.length) {
    return <Redirect to={menuHref} />;
  }

  return (
    <>
      <Checkout
        locationId={location?.type === 'location' ? location.id : undefined}
        locationGroupId={location?.type === 'location-group' ? location.id : undefined}
        locationType={location?.locationType}
        pricesIncludeTax={location?.pricesIncludeTax}
        discounts={event?.discounts ?? []}
        locationQrType={location?.type}
        eventId={event?.id}
        createOrder={(payload: OrderCreateRequest) => createOrderMutation.mutate(payload)}
        isCreatingOrder={createOrderMutation.isLoading}
        menuHref={menuHref}
        isFetchingOrderSummary={orderSummaryQuery.isFetching}
        orderItems={orderSummary?.items ?? []}
        upsellingItems={upsellingItemsQuery.data ?? []}
        totals={{
          productsSubtotal: orderSummary?.productsSubtotal ?? 0,
          feesSubtotal: orderSummary?.feesSubtotal ?? 0,
          surchargesSubtotal: orderSummary?.surchargesSubtotal ?? 0,
          discountsSubtotal: orderSummary?.discountsSubtotal ?? 0,
          tipsSubtotal: orderSummary?.tipsSubtotal ?? 0,
          refundsSubtotal: orderSummary?.refundsSubtotal ?? 0,
          subtotal: orderSummary?.subtotal ?? 0,
          tax: orderSummary?.tax ?? 0,
          total: orderSummary?.total ?? 0,
        }}
        unavailableItems={orderSummary?.unavailableItems}
        needsAgeVerification={Boolean(cart?.items?.some((item) => item?.product?.needsAgeVerification))}
        hasPaymentMethods={Boolean(
          (paymentGateway ||
            location?.section?.store?.settings?.allowsRoomPayment ||
            event?.store?.settings?.allowsRoomPayment) &&
            orderSummary?.total
        )}
        allowsRoomPayment={Boolean(
          location?.section?.store?.settings?.allowsRoomPayment || event?.store?.settings?.allowsRoomPayment
        )}
        // isPreorder={DateTime.fromISO(event?.preorderEndDatetime!) > DateTime.local() || false}
        isPreorder={Boolean(event)}
        areSchedulableOrdersAllowed={Boolean(location?.section.areSchedulableOrdersAllowed)}
        orderingFlowType={location?.section.orderingFlowType || event?.orderingFlowType}
        isOrderingAsapAllowed={Boolean(isOrderingAsapAllowed)}
        orderAttributeGroup={event?.store?.orderAttributeGroups?.[0] || location?.section.store?.orderAttributeGroups?.[0]}
        customer={customer}
        paymentMethods={paymentMethods}
        termsAndConditionsMessage={
          location?.section.store?.company?.termsAndConditionsMessage || event?.store?.company?.termsAndConditionsMessage
        }
        // Tips
        areTipsEnabled={areOptionalTipsEnabled}
        tipOptions={
          !tipsConfiguration || !areOptionalTipsEnabled || tipsConfiguration.isDefaultTipOptionFixed
            ? []
            : getTipOptions(tipsConfiguration)
        }
        // Discounts
        availableDiscounts={arePromotionsEnabled ? location?.section?.discounts ?? event?.discounts ?? [] : []}
        hasActiveCoupons={Boolean(arePromotionsEnabled && (location?.section?.hasActiveCoupons || event?.hasActiveCoupons))}
        // Currency and country
        currency={location?.section?.store?.company?.currency ?? event?.store?.company?.currency ?? 'USD'}
        country={location?.section?.store?.company?.country ?? event?.store?.company?.country ?? 'US'}
        timezone={timezone}
      />
    </>
  );
}

function getTipOptions(tipsConfiguration: TipsConfiguration): Tip[] {
  const {tip1, tip2, tip3} = tipsConfiguration;
  return [
    {value: tip1, type: 'default'},
    {value: tip2, type: 'default'},
    {value: tip3, type: 'default'},
    {value: 0, type: 'custom'},
  ];
}
