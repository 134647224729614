import {Slide, TypeOptions, toast} from 'react-toastify';

/**
 * react-toastify with some defaults
 * */
export const useToast = () => {
  return (message: string, type: TypeOptions = 'success') => {
    toast(message, {
      autoClose: 4000,
      closeButton: false,
      hideProgressBar: true,
      position: 'bottom-center',
      transition: Slide,
      type,
    });
  };
};
