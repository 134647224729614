import {fetchStoreRequest} from 'api/store.api';
import {ReactComponent as EmptyOrdersIcon} from 'assets/icons/empty-orders.icon.svg';
import {Input} from 'common/components/Input';
import {Spinner} from 'components/ui/spinner.component';
import {useAppContext} from 'contexts/app.context';
import {CalendarDays} from 'lucide-react';
import {DateTime} from 'luxon';
import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useHistory, useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {NonIdealState} from 'shared/non-ideal-state.component';
import {getStoreImageUrl, slugify} from 'utils/helpers.utils';
import {paths} from 'utils/paths';
import {fetchEventsRequest} from './events.api';
import {MagnifyingGlassIcon} from '@heroicons/react/24/solid';

export default function EventsListPage() {
  const {location, setContextState} = useAppContext();
  const {storeId} = useParams<{storeId: string}>();
  const [search, setSearch] = useState('');

  const eventsQuery = useQuery(['events', storeId], {
    queryFn: () => fetchEventsRequest(+storeId),
    refetchInterval: 1000 * 60,
  });

  const {data: store} = useQuery(['stores', storeId], {
    queryFn: () => fetchStoreRequest(+storeId),
    refetchInterval: 1000 * 60,
  });

  useEffect(() => {
    if (store) {
      setContextState({
        location: undefined,
        store,
        navbar: {title: store.name, subtitle: 'Online Order', logoUrl: store.imageUrl ?? ''},
      });
    }
  }, [store]);

  const history = useHistory();

  if (store && process.env.NODE_ENV === 'production') {
    const paymentGateway = store.settings?.paymentGateway;
    const currentHost = window.location.host;
    if (currentHost !== process.env.REACT_APP_FREEDOMPAY_HOST && paymentGateway === 'freedompay') {
      window.location.assign(`https://${process.env.REACT_APP_FREEDOMPAY_HOST}/customer/stores/${store.id}/events`);
      return null;
    }
    if (currentHost !== process.env.REACT_APP_STRIPE_HOST && paymentGateway === 'stripe') {
      window.location.assign(`https://${process.env.REACT_APP_STRIPE_HOST}/stores/${store.id}/events`);
      return null;
    }
  }

  if (eventsQuery.isLoading) {
    return <Spinner />;
  }

  if (eventsQuery.isError) {
    return <div className="scene-error">An error ocurred loading the events</div>;
  }

  const filteredEvents = eventsQuery.data?.filter((event) => event.name.toLowerCase().includes(search.toLowerCase())) ?? [];

  if (!eventsQuery.data?.length) {
    return (
      <NonIdealState
        icon={<EmptyOrdersIcon />}
        title="No events here"
        description="There are no events available at the moment"
        action={{
          text: 'Make an order',
          callback: () => history.replace(paths.location.generatePath(slugify(location?.section.store?.name || ''))),
        }}
      />
    );
  }

  return (
    <div className="tw-flex tw-flex-1 tw-items-stretch tw-overflow-hidden">
      <main className="tw-flex-1 tw-scroll-pt-28 tw-overflow-y-auto tw-bg-white tw-px-6 lg:tw-px-0">
        <section className="tw-mx-auto tw-flex tw-min-w-0 tw-max-w-4xl tw-flex-1 tw-flex-col">
          {/* Store header */}
          <header>
            <div className="tw-mx-auto">
              <div className="sm:tw-flex sm:tw-items-end sm:tw-space-x-5 tw-mt-4">
                <div className="tw-flex">
                  <img
                    className="tw-h-20 tw-w-20 tw-rounded-full tw-ring-4 tw-ring-white tw-drop-shadow-sm sm:tw-h-32 sm:tw-w-32 tw-object-cover"
                    src={getStoreImageUrl(store)}
                    alt=""
                  />
                </div>
              </div>
              <div className="tw-mt-4 tw-block tw-min-w-0 tw-flex-1">
                <h1 className="tw-truncate tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-bold tw-text-slate-900">
                  {store?.name}
                </h1>
                <h2 className="tw-truncate tw-text-base md:tw-text-lg lg:tw-text-xl tw-font-medium tw-text-slate-900">
                  Upcoming events
                </h2>
              </div>
            </div>

            {/* Search events input */}

            <div className="tw-my-4">
              <div>
                <div className="tw-relative tw-mt-2 tw-rounded-md tw-shadow-sm">
                  <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-2">
                    <MagnifyingGlassIcon className="tw-h-5 tw-w-5 tw-text-gray-400" />
                  </div>
                  <Input
                    placeholder="Search upcoming events"
                    type="search"
                    className="tw-pl-8 tw-ml-0"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <hr />
          </header>

          {/* Event grid */}
          <ul
            role="list"
            className="tw-mx-auto tw-mt-4 tw-grid tw-grid-cols-1 tw-gap-8 sm:tw-grid-cols-2 lg:tw-mx-0 lg:tw-max-w-none lg:tw-grid-cols-3"
          >
            {filteredEvents.map((event) => (
              <li key={event.id} className="tw-border tw-rounded-xl tw-shadow-sm hover:tw-shadow">
                <Link to={paths.event.generatePath(event.id)}>
                  <img
                    className="tw-aspect-[11/5] tw-w-full tw-rounded-t-xl tw-object-cover"
                    src={event.coverImageUrl ?? ''}
                    alt=""
                  />
                  <div className="tw-p-4">
                    <h3 className="tw-text-lg tw-font-bold tw-mb-1 tw-tracking-tight tw-text-gray-900">{event.name}</h3>
                    <div className="tw-flex tw-items-center tw-text-sm tw-text-gray-600">
                      <CalendarDays className="tw-w-4 tw-h-4 tw-mr-2" />
                      <p>
                        {DateTime.fromISO(event.startDatetime)
                          .setZone(event.store?.timezone ?? 'America/Los_Angeles')
                          .toFormat('EEE LLL dd, hh:mm a')}
                      </p>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </section>
      </main>
    </div>
  );
}
