import React, {useState} from 'react';
import {RadioGroup, RadioGroupItem} from 'common/components/RadioGroup';
import {cn} from 'common/utils';
import CustomTipDialog from './CustomTipDialog';

export interface Tip {
  value: number;
  type: 'default' | 'custom' | 'initial';
}

interface TipSelectorProps {
  options: Tip[];
  value?: Tip;
  onChange: (value: Tip) => void;
  disabled?: boolean;
  orderAmount: number;
}

export function TipSelector({options, onChange, disabled, orderAmount, value}: TipSelectorProps) {
  const [openCustomTip, setOpenCustomTip] = useState(false);

  return (
    <>
      <RadioGroup
        disabled={disabled}
        className="tw-mt-2 tw-flex tw-w-full tw-gap-2"
        onValueChange={(tip: string) => {
          if (tip === 'custom') {
            setOpenCustomTip(true);
          } else {
            onChange({value: Number(tip), type: 'default'});
          }
        }}
      >
        {options.map((tip) => (
          <label
            key={tip.value.toString()}
            className={cn(
              'tw-inline-flex tw-items-center tw-rounded-full tw-px-2 tw-py-0.5 tw-text-xs tw-font-medium tw-cursor-pointer',
              (value?.value === tip.value && value?.type === tip.type) || (tip.type === 'custom' && value?.type === 'custom')
                ? 'tw-bg-blue-700 tw-text-white'
                : 'tw-text-gray-600 tw-bg-gray-100',
              disabled && 'tw-opacity-75'
            )}
          >
            {tip.type === 'custom' ? (
              <>
                <RadioGroupItem onClick={() => setOpenCustomTip(true)} value="custom" id={tip.type} />
                Custom
              </>
            ) : (
              <>
                <RadioGroupItem value={tip.value.toString()} id={tip.value.toString()} />
                {tip.value * 100}%
              </>
            )}
          </label>
        ))}
      </RadioGroup>

      <CustomTipDialog
        open={openCustomTip}
        onOpenChange={() => setOpenCustomTip(false)}
        onChange={onChange}
        orderAmount={orderAmount}
        onCloseDialog={() => setOpenCustomTip(false)}
      />
    </>
  );
}
