import {useEffect, useState} from 'react';

import {WalletBrand} from 'utils/types';

export function useFreedompayWalletPaymentMethod() {
  const [walletBrand, setWalletBrand] = useState<WalletBrand | undefined>();

  useEffect(() => {
    const freedompaySdk = (window as any).FreedomPay;
    let isApplepaySupported = false;
    let applepayCanMakePayments = false;
    if (freedompaySdk) {
      isApplepaySupported = freedompaySdk.Apm.ApplePay.isSupported();
      applepayCanMakePayments = freedompaySdk.Apm.ApplePay.canMakePayments();
      if (isApplepaySupported && applepayCanMakePayments) {
        setWalletBrand('apple-pay');
      } else {
        setWalletBrand('google-pay');
      }
    }
  }, []);

  if (!walletBrand) {
    return null;
  }

  return {
    freedompayToken: 'wallet', // This seems odd...
    cardBrand: walletBrand,
    cardCountry: 'US', // TODO: Get this from company
    cardExpirationYear: 0,
    cardExpirationMonth: 0,
    cardLastFourDigits: '0000', // This seems odd
    cardholderName: '',
    isWallet: true,
  };
}
