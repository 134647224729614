import {
  Order,
  OrderCreateRequest,
  OrderFeedback,
  OrderItem,
  OrderSummary,
  OrderSummaryRequest,
  UpsertStoreFeedbackRequest,
} from 'models/order.model';
import {UpsellingItem} from 'models/upselling-item.model';
import {callApi} from './api';

export const fetchOrdersRequest = async (token: string): Promise<Order[]> => {
  return callApi<Order[]>(`/orders`, {token}).then(({data}) => data);
};

export const fetchOrderRequest = async (token: string, orderId: number): Promise<Order> => {
  return callApi<Order>(`/orders/${orderId}`, {token}).then(({data}) => data);
};

export const createStoreFeedbackRequest = async (
  token: string,
  upsertStoreFeedbackRequest: UpsertStoreFeedbackRequest
): Promise<OrderFeedback> => {
  return callApi<OrderFeedback>(`/order-feedbacks`, {
    token,
    method: 'POST',
    body: JSON.stringify(upsertStoreFeedbackRequest),
  }).then(({data}) => data);
};

export const updateStoreFeedbackRequest = async (
  token: string,
  orderId: number,
  upsertStoreFeedbackRequest: UpsertStoreFeedbackRequest
): Promise<OrderFeedback> => {
  return callApi<OrderFeedback>(`/order-feedbacks/${orderId}`, {
    token,
    method: 'PATCH',
    body: JSON.stringify(upsertStoreFeedbackRequest),
  }).then(({data}) => data);
};

export const setOrderItemLikingRequest = async (token: string, orderItemId: number, liking: boolean): Promise<OrderItem> => {
  return callApi<OrderItem>(`/order-items/${orderItemId}/liking`, {token, method: 'POST', body: JSON.stringify({liking})}).then(
    ({data}) => data
  );
};

export const createOrderRequest = async (
  token: string,
  orderCreateRequest: OrderCreateRequest,
  recaptchaToken: string
): Promise<Order> => {
  return callApi<Order>(`/orders`, {
    token,
    method: 'POST',
    body: JSON.stringify({...orderCreateRequest, 'g-recaptcha-response': recaptchaToken}),
  }).then(({data}) => data);
};

export const cancelOrderRequest = async (token: string, orderId: number): Promise<Order> => {
  return callApi<Order>(`/orders/${orderId}`, {token, method: 'DELETE'}).then(({data}) => data);
};

export const getOrderSummaryRequest = async (orderSummaryRequest: OrderSummaryRequest, token?: string): Promise<OrderSummary> => {
  return callApi<OrderSummary>(`/orders/summary`, {token, method: 'POST', body: JSON.stringify(orderSummaryRequest)}).then(
    ({data}) => data
  );
};

export async function getUpsellingItemsRequest(menuCategoryProductIds: string, menuIds: string): Promise<UpsellingItem[]> {
  return callApi<UpsellingItem[]>(`/upselling?menuCategoryProductIds=${menuCategoryProductIds}&menuIds=${menuIds}`, {}).then(
    ({data}) => data
  );
}
