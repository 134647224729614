import {Icon} from 'shared/icon/icon.component';
import React from 'react';
import {Alert, AlertProps} from './alert.component';

interface ErrorAlertProps extends AlertProps {
  title?: string;
  message?: string;
}

export const ErrorAlert: React.FC<ErrorAlertProps> = ({title, message, ...dialogProps}) => {
  return (
    <Alert
      {...dialogProps}
      className="error-alert"
      body={
        <>
          <Icon icon="error" className="error-alert-icon" />
          <h1 className="error-alert-title">{title}</h1>
          <p className="error-alert-message">{message}</p>
        </>
      }
    />
  );
};
