import {generatePath} from 'react-router-dom';

interface PathDefinitions {
  location: {
    route: '/s/:storeName';
    params: [storeName: string];
  };
  storeOpeningHours: {
    route: '/s/:storeName/opening-hours';
    params: [storeName: string];
  };
  sectionOpeningHours: {
    route: '/s/:storeName/s/:sectionName/opening-hours-section';
    params: [storeName: string, sectionName: string];
  };
  orders: {
    route: '/orders';
    params: [];
  };
  checkout: {
    route: '/checkout';
    params: [];
  };
  checkoutSuccess: {
    route: '/checkout-success/:orderId';
    params: [orderId: number];
  };
  event: {
    route: '/events/:eventId';
    params: [eventId: number];
  };
  events: {
    route: '/stores/:storeId/events';
    params: [storeId: number];
  };
  orderDetails: {
    route: '/orders/:orderId';
    params: [orderId: number];
  };
  orderReceipt: {
    route: '/orders/:orderId/receipt';
    params: [orderId: number];
  };
  qrScanner: {
    route: '/qr-scanner';
    params: [];
  };
  qrResolver: {
    route: '/:qrCode';
    params: [qrCode: string];
  };
  faq: {
    route: '/faq';
    params: [];
  };
  privacyPolicy: {
    route: '/privacy-policy';
    params: [];
  };
  termsOfUse: {
    route: '/terms-of-use';
    params: [];
  };
  paymentMethods: {
    route: '/payment-methods';
    params: [];
  };
  home: {
    route: '/';
    params: [];
  };
  notFound: {
    route: '/not-found';
    params: [];
  };
}

export type PathName = keyof PathDefinitions;

export type PathsObject = {
  [key in PathName]: {
    route: PathDefinitions[key]['route'];
    generatePath: (...args: PathDefinitions[key]['params']) => string;
  };
};

export const paths: PathsObject = {
  location: {
    route: '/s/:storeName',
    generatePath: (storeName: string) => generatePath('/s/:storeName', {storeName}),
  },
  orders: {
    route: '/orders',
    generatePath: () => generatePath('/orders'),
  },
  checkout: {
    route: '/checkout',
    generatePath: () => generatePath('/checkout'),
  },
  checkoutSuccess: {
    route: '/checkout-success/:orderId',
    generatePath: (orderId: number) => generatePath('/checkout-success/:orderId', {orderId}),
  },
  event: {
    route: '/events/:eventId',
    generatePath: (eventId: number) => generatePath('/events/:eventId', {eventId}),
  },
  events: {
    route: '/stores/:storeId/events',
    generatePath: (storeId: number) => generatePath('/stores/:storeId/events', {storeId}),
  },
  orderDetails: {
    route: '/orders/:orderId',
    generatePath: (orderId: number) => generatePath('/orders/:orderId', {orderId}),
  },
  orderReceipt: {
    route: '/orders/:orderId/receipt',
    generatePath: (orderId: number) => generatePath('/orders/:orderId/receipt', {orderId}),
  },
  storeOpeningHours: {
    route: '/s/:storeName/opening-hours',
    generatePath: (storeName) => generatePath('/s/:storeName/opening-hours', {storeName}),
  },
  sectionOpeningHours: {
    route: '/s/:storeName/s/:sectionName/opening-hours-section',
    generatePath: (storeName, sectionName) =>
      generatePath('/s/:storeName/s/:sectionName/opening-hours-section', {storeName, sectionName}),
  },
  qrScanner: {
    route: '/qr-scanner',
    generatePath: () => generatePath('/qr-scanner'),
  },
  qrResolver: {
    route: '/:qrCode',
    generatePath: (qrCode: string) => generatePath('/:qrCode', {qrCode}),
  },
  faq: {
    route: '/faq',
    generatePath: () => generatePath('/faq'),
  },
  privacyPolicy: {
    route: '/privacy-policy',
    generatePath: () => generatePath('/privacy-policy'),
  },
  termsOfUse: {
    route: '/terms-of-use',
    generatePath: () => generatePath('/terms-of-use'),
  },
  paymentMethods: {
    route: '/payment-methods',
    generatePath: () => generatePath('/payment-methods'),
  },
  home: {
    route: '/',
    generatePath: () => generatePath('/'),
  },
  notFound: {
    route: '/not-found',
    generatePath: () => generatePath('/not-found'),
  },
} as const;
