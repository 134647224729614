import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

export function useRecaptcha() {
  const googleReCaptcha = useGoogleReCaptcha();

  const executeRecaptcha = async (action: 'send_verification_code' | 'verify_code' | 'create_order') => {
    if (!googleReCaptcha.executeRecaptcha) {
      console.error('Recaptcha service not ready yet.');
      throw new Error('Recaptcha service not ready yet.');
    }

    const token = await googleReCaptcha.executeRecaptcha(action);

    if (!token) {
      throw new Error('Recaptcha token not available.');
    }

    return token;
  };

  return {executeRecaptcha};
}
