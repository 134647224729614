import React from 'react';
import ContentLoader from 'react-content-loader';

export const ProductDialogPlacheolder = () => {
  return (
    <section className="product-dialog-placeholder">
      <div className="product-header-placeholder">
        <ContentLoader speed={2} width="100%" height={310} backgroundColor="#f3f3f3" foregroundColor="#e6e6e6">
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="192" />
          <rect x="16" y="208" rx="0" ry="0" width="140" height="24" />
          <rect x="16" y="240" rx="3" ry="3" width="220" height="8" />
          <rect x="16" y="256" rx="3" ry="3" width="200" height="8" />
          <rect x="16" y="276" rx="0" ry="0" width="40" height="20" />
        </ContentLoader>
      </div>

      {[0, 1].map((index) => (
        <React.Fragment key={index}>
          <div className="modifier-group-title-placeholder">
            <ContentLoader speed={2} width="100%" height={16} backgroundColor="#f3f3f3" foregroundColor="#e6e6e6">
              <rect x="0" y="0" rx="2" ry="2" width="130" height="16" />
            </ContentLoader>
          </div>
          <div className="modifiers-placeholder">
            <ContentLoader speed={2} width="100%" height={100} backgroundColor="#f3f3f3" foregroundColor="#e6e6e6">
              <rect x="0" y="0" rx="3" ry="3" width="50%" height="12" />
              <rect x="85%" y="0" rx="3" ry="3" width="15%" height="12" />
              <rect x="0" y="40" rx="3" ry="3" width="50%" height="12" />
              <rect x="85%" y="40" rx="3" ry="3" width="15%" height="12" />
              <rect x="0" y="80" rx="3" ry="3" width="50%" height="12" />
              <rect x="85%" y="80" rx="3" ry="3" width="15%" height="12" />
            </ContentLoader>
          </div>
        </React.Fragment>
      ))}
    </section>
  );
};
