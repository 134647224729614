import React, {useEffect, useState} from 'react';

import {useAppContext} from 'contexts/app.context';
import {PaymentGateway, PaymentMethod} from 'models/payment-method.model';
import {Tabs, TabsContent, TabsList, TabsTrigger} from 'common/components/Tabs';

import {findPaymentMethod, getPaymentMethodActiveTab} from 'payment-methods/utils';
import {RoomChargeForm, useRoomChargeFormController} from './RoomChargeForm';
import {SelectPaymentMethodForm, useSelectPaymentMethodFormController} from './SelectPaymentMethodForm';

import {BanknotesIcon} from '@heroicons/react/24/outline';

// Payment method tabs
export interface UseSelectPaymentMethodTabProps {
  paymentMethods: PaymentMethod[];
  onContinue?: () => void;
}

export function useSelectPaymentMethodTabs({onContinue, paymentMethods}: UseSelectPaymentMethodTabProps) {
  const {cart} = useAppContext();

  const [activeTab, setActiveTab] = useState(getPaymentMethodActiveTab(cart?.payment, cart?.roomGuestLastName, cart?.roomNumber));

  const selectedPaymentMethod = findPaymentMethod(
    paymentMethods,
    cart?.payment?.stripePaymentMethodId ?? cart?.payment?.freedompayPaymentMethodId ?? ''
  );

  const roomChargeFormController = useRoomChargeFormController({
    defaultValues: {roomNumber: cart?.roomNumber, roomGuestLastName: cart?.roomGuestLastName},
    onSubmitted: () => {
      selectPaymentMethodFormController.reset({});
      onContinue?.();
    },
  });

  const selectPaymentMethodFormController = useSelectPaymentMethodFormController({
    defaultValues: {paymentMethod: selectedPaymentMethod},
    onSubmitted: () => {
      roomChargeFormController.reset({});
      onContinue?.();
    },
  });

  useEffect(() => {
    if (cart?.payment || cart?.roomGuestLastName || cart?.roomNumber) {
      setActiveTab(getPaymentMethodActiveTab(cart?.payment, cart?.roomGuestLastName, cart?.roomNumber));
    }
  }, [cart?.payment, cart?.roomGuestLastName, cart?.roomNumber]);

  useEffect(() => {
    if (paymentMethods.length && selectedPaymentMethod) {
      selectPaymentMethodFormController.reset({paymentMethod: selectedPaymentMethod});
    }
  }, [paymentMethods, selectedPaymentMethod]);

  return {
    activeTab,
    setActiveTab,
    roomChargeFormController,
    selectPaymentMethodFormController,
  };
}

export interface SelectPaymentMethodTabProps {
  activeTab: string;
  setActiveTab: (value: string) => void;
  roomChargeFormController: ReturnType<typeof useRoomChargeFormController>;
  selectPaymentMethodFormController: ReturnType<typeof useSelectPaymentMethodFormController>;
  allowsRoomPayment: boolean;
  paymentGateway?: PaymentGateway | null;
  paymentMethods: PaymentMethod[];
}

export function SelectPaymentMethodTabs({
  activeTab,
  setActiveTab,
  roomChargeFormController,
  selectPaymentMethodFormController,
  allowsRoomPayment,
  paymentGateway,
  paymentMethods,
}: SelectPaymentMethodTabProps) {
  return (
    <Tabs defaultValue="card" value={activeTab} onValueChange={setActiveTab} className="tw-flex tw-h-full tw-flex-col">
      {/* Tabs */}
      {allowsRoomPayment && !!paymentGateway && (
        <TabsList className="tw-w-flex tw-mb-4 tw-w-full">
          <TabsTrigger className="tw-flex-1 tw-min-w-[100px]" value="card">
            Credit/Debit Card
          </TabsTrigger>
          <TabsTrigger className="tw-flex-1 tw-min-w-[100px]" value="room-charge">
            Room Charge
          </TabsTrigger>
        </TabsList>
      )}

      {/* Payment methods (Credit or Debit card) */}
      <TabsContent value="card">
        {paymentMethods.length === 0 ? (
          <div className="tw-text-center tw-my-6">
            <BanknotesIcon className="tw-w-12 tw-h-12 tw-mx-auto tw-text-gray-400" />
            <h3 className="tw-mt-2 tw-text-sm tw-font-semibold tw-text-gray-900">No payment methods available</h3>
            <p className="tw-mt-1 tw-text-sm tw-text-gray-500">You haven't added any payment methods yet.</p>
          </div>
        ) : (
          <SelectPaymentMethodForm
            id={selectPaymentMethodFormController.formId}
            onSubmit={selectPaymentMethodFormController.submit}
            formController={selectPaymentMethodFormController}
            paymentMethods={paymentMethods}
          />
        )}
      </TabsContent>

      {/* Room charge */}
      <TabsContent value="room-charge">
        <RoomChargeForm
          id={roomChargeFormController.formId}
          onSubmit={roomChargeFormController.submit}
          register={roomChargeFormController.register}
          errors={roomChargeFormController.formState.errors}
        />
      </TabsContent>
    </Tabs>
  );
}
