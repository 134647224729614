import * as React from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import {cn} from 'common/utils';
import {Circle} from 'lucide-react';

const RadioGroupIndicator = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Indicator>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Indicator>
>(({className, ...props}, ref) => {
  return <RadioGroupPrimitive.Indicator ref={ref} className={cn(className)} {...props} />;
});

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({className, ...props}, ref) => {
  return <RadioGroupPrimitive.Root className={cn(className)} {...props} ref={ref} />;
});

RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({className, children, ...props}, ref) => {
  return <RadioGroupPrimitive.Item ref={ref} className={cn(className)} {...props} />;
});

const RadioGroupItemWithIndicator = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({className, children, ...props}, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        'text:tw-fill-slate-50 tw-h-4 tw-w-4 tw-rounded-full tw-border focus:tw-outline-none disabled:tw-cursor-not-allowed disabled:tw-opacity-50 tw-border-gray-300 tw-text-slate-900 hover:tw-border-gray-400 focus:tw-border-rose-400',
        className
      )}
      {...props}
    >
      <RadioGroupIndicator className="tw-flex tw-items-center tw-justify-center">
        <Circle className="tw-h-2.5 tw-w-2.5 tw-fill-rose-500 tw-stroke-none" />
      </RadioGroupIndicator>
    </RadioGroupPrimitive.Item>
  );
});

RadioGroupItemWithIndicator.displayName = RadioGroupPrimitive.Item.displayName;

export {RadioGroup, RadioGroupItem, RadioGroupIndicator, RadioGroupItemWithIndicator};
