import React from 'react';
import ReactGA from 'react-ga';
import {ToastContainer} from 'react-toastify';

import {Spinner} from 'components/ui/spinner.component';

import {Settings} from 'luxon';
import {Redirect, Route, Switch, useLocation, useParams} from 'react-router-dom';
// import {Layout} from './components/layout/layout.component';
import {initialCart, useAppContext} from './contexts/app.context';
import {paths} from './utils/paths';
import NotFoundScene from 'scenes/not-found/not-found.scene';
import SectionOpeningHoursScene from 'scenes/section-opening-hours/section-opening-hours.scene';
import StoreOpeningHoursScene from 'scenes/store-opening-hours/store-opening-hours.scene';

import {LocationScenePlaceholder} from 'components/location-scene-placeholder.component';
import {PrivateRoute} from './components/layout/private-route.component';

import {useQuery} from 'react-query';

const QrScannerScene = React.lazy(() => import('scenes/qr-scanner/qr-scanner.scene'));
const OrderDetailsScene = React.lazy(() => import('scenes/order-details/order-details.scene'));
const OrdersScene = React.lazy(() => import('scenes/orders/orders.scene'));
const PaymentMethodsScene = React.lazy(() => import('scenes/payment-methods/payment-methods.scene'));
const CheckoutSuccessScene = React.lazy(() => import('checkout/pages/CheckoutSuccessPage'));
import {Layout} from 'common/components/Layout';
import {CheckoutPage} from 'checkout/pages/CheckoutPage';

import EventsListPage from 'events/EventsListPage';

import {fetchEventRequest} from 'events/events.api';
import {useLocationQuery} from 'locations/location.api';
import LocationStorefrontPage from 'locations/LocationStorefrontPage';
import EventStorefrontPage from 'events/EventStorefrontPage';
import {StripeProvider} from 'contexts/stripe.context';
import {FAQsPage} from 'faqs/FAQsPage';
import {OrderReceiptScene} from 'scenes/order-details/order-receipt';

Settings.defaultLocale = 'en'; // Remove this if we implement  i18n

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-171244703-2');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

export const App: React.FC = () => {
  const navLocation = useLocation<{referrer?: string}>();

  return (
    <Layout>
      <React.Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/customer" render={({location: {pathname}}) => <Redirect to={pathname.replace(/^\/customer/, '')} />} />
          <Route
            exact
            path={[
              paths.events.route,
              paths.event.route,
              paths.orders.route,
              paths.orderDetails.route,
              paths.orderReceipt.route,
              paths.checkout.route,
              paths.checkoutSuccess.route,
              paths.paymentMethods.route,
              paths.location.route,
              paths.faq.route,
              paths.privacyPolicy.route,
              paths.termsOfUse.route,
              paths.home.route,
              paths.notFound.route,
            ]}
            component={AppSwitch}
          />
          <Route exact path={paths.storeOpeningHours.route} component={StoreOpeningHoursScene} />
          <Route exact path={paths.sectionOpeningHours.route} component={SectionOpeningHoursScene} />
          <Route exact path={paths.qrScanner.route} component={QrScannerScene} />
          <Route exact path={paths.qrResolver.route} component={QrResolver} />
          <Redirect to={navLocation.state?.referrer ?? paths.home.generatePath()} />
        </Switch>
      </React.Suspense>
      <ToastContainer />
    </Layout>
  );
};

const isEventsUrl = (pathname: string) => pathname.startsWith('/stores') && pathname.endsWith('/events');

const AppSwitch: React.FC = () => {
  const {location} = useAppContext();

  useLocationQuery(location?.qrCode);

  // We need to change the host accordingly to the payment gateway of the store for Apple Pay to work
  if (location && !isEventsUrl(window.location.pathname) && process.env.NODE_ENV === 'production') {
    const paymentGateway = location.section?.store?.settings?.paymentGateway;
    const qrCode = location.qrCode;
    const currentHost = window.location.host;
    if (currentHost !== process.env.REACT_APP_FREEDOMPAY_HOST && paymentGateway === 'freedompay') {
      window.location.assign(`https://${process.env.REACT_APP_FREEDOMPAY_HOST}/customer/${qrCode}`);
      return null;
    }
    if (currentHost !== process.env.REACT_APP_STRIPE_HOST && paymentGateway === 'stripe') {
      window.location.assign(`https://${process.env.REACT_APP_STRIPE_HOST}/${qrCode}`);
      return null;
    }
  }

  return (
    <Switch>
      <PrivateRoute exact path={paths.orderDetails.route} component={OrderDetailsScene} />
      <PrivateRoute exact path={paths.orderReceipt.route} component={OrderReceiptScene} />
      <PrivateRoute exact path={paths.orders.route} component={OrdersScene} />
      <PrivateRoute exact path={paths.paymentMethods.route} component={PaymentMethodsScene} />
      <Route exact path={paths.checkoutSuccess.route} component={CheckoutSuccessScene} />
      <Route exact path={paths.events.route} component={EventsListPage} />
      <Route exact path={paths.event.route} component={EventSceneWrapper} />
      <Route exact path={paths.location.route} component={LocationStorefrontPage} />
      <Route exact path={paths.faq.route} component={FAQsPage} />
      <Route exact path={paths.notFound.route} component={NotFoundScene} />

      <Route
        exact
        path={paths.checkout.route}
        component={() => (
          <StripeProvider>
            <CheckoutPage />
          </StripeProvider>
        )}
      />
      <Redirect to={paths.qrScanner.route} />
    </Switch>
  );
};

const QrResolver: React.FC = () => {
  const {qrCode} = useParams<{qrCode: string}>();

  useLocationQuery(qrCode, true);

  return <LocationScenePlaceholder />;
};

function EventSceneWrapper() {
  const {setContextState, event: currentEvent} = useAppContext();
  const {eventId} = useParams<{eventId: string}>();

  if (currentEvent && process.env.NODE_ENV === 'production') {
    const paymentGateway = currentEvent?.store?.settings?.paymentGateway;

    const currentHost = window.location.host;
    if (currentHost !== process.env.REACT_APP_FREEDOMPAY_HOST && paymentGateway === 'freedompay') {
      window.location.assign(`https://${process.env.REACT_APP_FREEDOMPAY_HOST}/customer/events/${currentEvent.id}`);
      return null;
    }
    if (currentHost !== process.env.REACT_APP_STRIPE_HOST && paymentGateway === 'stripe') {
      window.location.assign(`https://${process.env.REACT_APP_STRIPE_HOST}/events/${currentEvent.id}`);
      return null;
    }
  }

  const {data: event} = useQuery(['events', eventId], {
    queryFn: () => fetchEventRequest(+eventId),
    refetchInterval: 1000 * 30,
    onSuccess: (event) => {
      setContextState({
        event,
        store: event.store,
        navbar: {
          title: event.store?.name ?? '',
          subtitle: 'Online Order',
          logoUrl: event.store?.imageUrl ?? '',
        },
        paymentGateway: event.store?.settings?.paymentGateway,
        ...(currentEvent?.id !== event.id ? {cart: initialCart} : {}),
        location: undefined,
      });
    },
  });

  if (!event) {
    return <></>;
  }

  return <EventStorefrontPage />;
}
