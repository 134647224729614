import React, {ReactElement} from 'react';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';

interface ButtonProps extends React.ComponentPropsWithRef<'button'> {
  circle?: boolean;
  fixed?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  gradient?: boolean;
  icon?: ReactElement;
  loading?: boolean;
  intent?: 'primary' | 'danger';
  sharp?: boolean;
  size?: 'small' | 'normal' | 'large';
  text?: string;
  variant?: 'solid' | 'outlined' | 'minimal';
}

/**
 * Buton
 */
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      circle,
      fixed = false,
      fullWidth = false,
      fullHeight = false,
      gradient = false,
      intent = 'primary',
      icon,
      loading = false,
      sharp = false,
      size = 'normal',
      text,
      variant = 'solid',
      ...props
    },
    ref
  ) => {
    const renderButtonContent = () => {
      if (loading) {
        return <FontAwesomeIcon icon={faCircleNotch} spin />;
      }

      return (
        <>
          {!!icon && <div className="button__icon-left">{icon}</div>}
          {!!text && <span className="button__text">{text}</span>}
        </>
      );
    };
    return (
      <button
        disabled={loading || props.disabled}
        className={classNames('button', `button--${variant}`, `button--${intent}`, `button--${size}`, {
          'button--sharp': sharp,
          'button--gradient': gradient,
          'button--full-width': fullWidth,
          'button--full-height': fullHeight,
          'button--fixed': fixed,
          'button--circle': circle,
        })}
        ref={ref}
        {...props}
      >
        {renderButtonContent()}
      </button>
    );
  }
);
