import React from 'react';

import {useAppContext} from 'contexts/app.context';
import {Dialog, DialogContent, DialogDescription, DialogHeader, DialogProps, DialogTitle} from 'common/components/Dialog';

import {ReactComponent as CardIcon} from 'assets/icons/card.icon.svg';
import {PaymentMethod} from 'models/payment-method.model';

import {StripeProvider} from 'contexts/stripe.context';
import {StripePaymentMethodForm} from './StripePaymentMethodForm';

import {FreedompayPaymentMethodForm} from './FreedompayPaymentMethodForm';

interface CreatePaymentMethodDialogProps extends DialogProps {
  onCreated?: (paymentMethod: PaymentMethod) => void;
}

export function CreatePaymentMethodDialog({onCreated, ...dialogProps}: CreatePaymentMethodDialogProps) {
  const {paymentGateway} = useAppContext();

  const onPaymentMethodCreated = (paymentMethod: PaymentMethod) => {
    onCreated?.(paymentMethod);
  };

  const renderGatewayCreatePaymentForm = () => {
    if (paymentGateway === 'stripe') {
      return (
        <StripeProvider>
          <StripePaymentMethodForm onCreated={onPaymentMethodCreated} />
        </StripeProvider>
      );
    }

    if (paymentGateway === 'freedompay') {
      return <FreedompayPaymentMethodForm onCreated={onPaymentMethodCreated} />;
    }
  };

  return (
    <Dialog {...dialogProps}>
      <DialogContent>
        <DialogHeader className="tw-items-center tw-mb-6">
          <div className="tw-pt-4 tw-pb-6">
            <CardIcon />
          </div>
          <DialogTitle>Add payment method</DialogTitle>
          <DialogDescription>Complete the details of your payment method</DialogDescription>
        </DialogHeader>

        {renderGatewayCreatePaymentForm()}
      </DialogContent>
    </Dialog>
  );
}
