import React from 'react';

import {ReactComponent as AsapPickupIcon} from 'assets/icons/asap-pickup.icon.svg';

import {Button} from 'common/components/Button';

import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogProps, DialogTitle} from 'common/components/Dialog';

import {OrderAttributesForm} from './OrderAttributesForm';
import {OrderAttributeGroup} from 'models/order-custom-attribute-group.model';

interface OrderAttributesDialogProps extends DialogProps {
  onContinue?: () => void;
  orderAttributeGroup: OrderAttributeGroup;
}

export function OrderAttributesDialog({onContinue, orderAttributeGroup, ...dialogProps}: OrderAttributesDialogProps) {
  return (
    <Dialog {...dialogProps}>
      <DialogContent>
        <DialogHeader className="tw-items-center tw-mb-6">
          <div className="tw-pt-4 tw-pb-6">
            <AsapPickupIcon />
          </div>
          <DialogTitle>{orderAttributeGroup.name}</DialogTitle>
          {/* <DialogDescription>{orderAttributeGroup.prompt}</DialogDescription> */}
        </DialogHeader>

        <OrderAttributesForm
          id="order-custom-attributes-form"
          onSubmitted={() => onContinue?.()}
          orderAttributeGroup={orderAttributeGroup}
        />

        <DialogFooter>
          <Button size="lg" form="order-custom-attributes-form">
            Continue
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
