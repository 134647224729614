import {DateTime} from 'luxon';
import React, {createContext, useCallback, useContext, useEffect, useMemo} from 'react';
import {useLocalStorage} from 'hooks/use-local-storage.hook';
import {Cart, CartItem, CartLocationDetails, CartPayment, OrderAttributeGroupWithAttributes} from 'models/cart.model';
import {Customer} from 'models/customer.model';
import {Location} from 'models/location.model';
import jwtDecode from 'jwt-decode';
import {Order} from 'models/order.model';
import {sumBy} from 'lodash';
import {PaymentGateway} from 'models/payment-method.model';
import {Event} from 'models/event.model';
import {Store} from 'models/store.model';
import {Tip} from 'checkout/components/TipSelector';

interface NavbarState {
  title: string;
  subtitle: string;
  logoUrl: string;
}

export interface AppState {
  location?: Location;
  event?: Event;
  customer?: Customer;
  order?: Order;
  cart?: Cart;
  paymentGateway?: PaymentGateway | null;
  lastUpdate: string;
  navbar?: NavbarState;
  store?: Store;
}

interface CartReducer {
  editProductQuantity: (itemIndex: number, newValue: number) => void;
  addItem: (cartItem: CartItem) => void;
  removeItem: (itemIndex: number) => void;
  removeProduct: (productId: number) => void;
  removeModifierGroup: (productId: number, modifierGroupId: number) => void;
  removeModifier: (productId: number, modifierGroupId: number, modifierId: number) => void;
  removeDiscounts: () => void;
  resetCart: () => void;
  setPickupDetails: (pickupName: string, pickupInfo: string) => void;
  setScheduleOrderDetails: (scheduleDate?: string) => void;
  setSelectedTip: (tip: Tip) => void;
  setCouponCode: (couponCode?: string) => void;
  setLocationDetails: (locationDetails: CartLocationDetails) => void;
  setRoomChargeDetails: (roomNumber: string, roomGuestLastName: string) => void;
  setPayment: (payment: CartPayment | undefined) => void;
  setOrderCustomAttributeValues: (orderCustomGroupsWithAttributes: Array<OrderAttributeGroupWithAttributes>) => void;
  setIsAgeVerified: (isAgeVerified: boolean) => void;
}

export interface AppContextState extends AppState {
  setContextState: (state: Partial<AppState>) => void;
  cartReducer: CartReducer;
}

export const initialCart: Cart = {
  items: [],
  total: 0,
  customerNotes: '',
  pickupName: '',
  pickupInfo: '',
  roomNumber: undefined,
  roomGuestLastName: undefined,
  payment: undefined,
  locationDetails: undefined,
  orderGroupsWithAttributes: [],
  isAgeVerified: false,
  itemsQuantity: 0,
  selectedTip: undefined,
  couponCode: undefined,
};

const initialState: AppState = {
  lastUpdate: DateTime.utc().toISO(),
};

const AppContext = createContext<AppContextState>({} as any);
AppContext.displayName = 'AppContext';

export const AppContextProvider: React.FC = ({children, ...props}) => {
  const [state, setState] = useLocalStorage<AppState>('APP_STATE', initialState);

  const setContextState = useCallback(
    (patch: Partial<AppState>) => setState({...state, ...patch, lastUpdate: DateTime.utc().toISO()}),
    [state]
  );
  const cartReducer: CartReducer = useMemo(
    () => ({
      editProductQuantity: (itemIndex: number, newValue: number) => {
        const cart = state.cart ?? initialCart;
        const items = cart.items
          .map((item, index) => {
            if (index === itemIndex) {
              return newValue <= 0
                ? undefined
                : {
                    ...item,
                    quantity: newValue,
                    total: (item.total / item.quantity) * newValue,
                    totalWithDiscount: item.totalWithDiscount ? (item.totalWithDiscount / item.quantity) * newValue : undefined,
                  };
            } else {
              return item;
            }
          })
          .filter(Boolean) as CartItem[];

        const total = sumBy(items, ({total}) => total);
        const totalWithDiscount = sumBy(items, ({total, totalWithDiscount}) => (totalWithDiscount ? totalWithDiscount : total));

        const itemsQuantity = sumBy(items, ({quantity}) => quantity);

        setContextState({
          cart: {...cart, items, total, totalWithDiscount, orderSummary: undefined, itemsQuantity},
        });
      },
      addItem: (cartItem: CartItem) => {
        const cart = state.cart ?? initialCart;
        const items = cart.items.concat([cartItem]);
        const total = sumBy(items, ({total}) => total);
        const totalWithDiscount = sumBy(items, ({total, totalWithDiscount}) => (totalWithDiscount ? totalWithDiscount : total));

        const itemsQuantity = sumBy(items, ({quantity}) => quantity);

        setContextState({
          cart: {...cart, items, total, totalWithDiscount, itemsQuantity},
        });
      },
      removeItem: (itemIndex: number) => {
        const cart = state.cart ?? initialCart;
        const items = cart.items.filter((_, index) => index !== itemIndex);
        const total = sumBy(items, ({total}) => total);
        const totalWithDiscount = sumBy(items, ({total, totalWithDiscount}) => totalWithDiscount ?? total);
        if (items.length === 0) {
          setContextState({cart: initialCart});
        } else {
          const itemsQuantity = sumBy(items, ({quantity}) => quantity);
          setContextState({
            cart: {...cart, items, total, totalWithDiscount, itemsQuantity},
          });
        }
      },
      removeProduct: (productId: number) => {
        const cart = state.cart ?? initialCart;
        const items = cart.items.filter(({product}) => product.id !== productId);
        const total = sumBy(items, ({total}) => total);
        const totalWithDiscount = sumBy(items, ({total, totalWithDiscount}) => totalWithDiscount ?? total);

        const itemsQuantity = sumBy(items, ({quantity}) => quantity);

        setContextState({
          cart: {...cart, items, total, totalWithDiscount, itemsQuantity},
        });
      },
      removeModifierGroup: (productId: number, modifierGroupId: number) => {
        const cart = state.cart ?? initialCart;
        const items = cart.items.map((item) =>
          item.product.id === productId
            ? {...item, modifierGroups: item.modifierGroups.filter((mg) => mg.modifierGroupId !== modifierGroupId)}
            : item
        );
        const total = sumBy(items, ({total}) => total);
        const totalWithDiscount = sumBy(items, ({total, totalWithDiscount}) => totalWithDiscount ?? total);
        setContextState({
          cart: {...cart, items, total, totalWithDiscount},
        });
      },
      removeModifier: (productId: number, modifierGroupId: number, modifierId: number) => {
        const cart = state.cart ?? initialCart;
        const items = cart.items ?? [];

        for (const item of items) {
          if (item.product.id === productId) {
            for (const mg of item.modifierGroups) {
              if (mg.modifierGroupId === modifierGroupId) {
                mg.modifiers = mg.modifiers.filter((m) => m.modifierId !== modifierId);
                if (mg.modifiers.length === 0) {
                  item.modifierGroups = item.modifierGroups.filter((mg) => mg.modifierGroupId !== modifierGroupId);
                }
              }
            }
          }
        }

        const total = sumBy(items, ({total}) => total);
        const totalWithDiscount = sumBy(items, ({total, totalWithDiscount}) => totalWithDiscount ?? total);
        setContextState({
          cart: {...cart, items, total, totalWithDiscount},
        });
      },
      removeDiscounts: () => {
        const cart = state.cart ?? initialCart;
        const items = cart.items.map((item) => ({...item, totalWithDiscount: undefined}));

        setContextState({
          cart: {...cart, items, totalWithDiscount: undefined},
        });
      },
      resetCart: () => {
        setContextState({cart: initialCart});
      },
      setPickupDetails: (pickupName: string, pickupInfo: string) => {
        const cart = state.cart ?? initialCart;
        setContextState({
          cart: {...cart, pickupName, pickupInfo},
        });
      },
      setScheduleOrderDetails: (scheduleDate?: string) => {
        const cart = state.cart ?? initialCart;
        setContextState({
          cart: {...cart, scheduleDate},
        });
      },
      setRoomChargeDetails: (roomNumber: string, roomGuestLastName: string) => {
        const cart = state.cart ?? initialCart;
        if (roomNumber && roomGuestLastName) {
          setContextState({
            cart: {...cart, roomNumber, roomGuestLastName, payment: undefined},
          });
        }
      },
      setPayment: (payment: CartPayment | undefined) => {
        const cart = state.cart ?? initialCart;
        if (payment) {
          setContextState({
            cart: {...cart, payment, roomNumber: '', roomGuestLastName: ''},
          });
        }
      },
      setLocationDetails: (locationDetails: CartLocationDetails) => {
        const cart = state.cart ?? initialCart;
        if (locationDetails) {
          setContextState({
            cart: {...cart, locationDetails},
          });
        }
      },
      setOrderCustomAttributeValues: (orderCustomGroupsWithAttributes: Array<OrderAttributeGroupWithAttributes>) => {
        const cart = state.cart ?? initialCart;
        if (orderCustomGroupsWithAttributes) {
          setContextState({
            cart: {...cart, orderGroupsWithAttributes: orderCustomGroupsWithAttributes},
          });
        }
      },
      setIsAgeVerified: (isAgeVerified: boolean) => {
        const cart = state.cart ?? initialCart;
        setContextState({
          cart: {...cart, isAgeVerified},
        });
      },
      setSelectedTip: (selectedTip: Tip) => {
        const cart = state.cart ?? initialCart;
        setContextState({
          cart: {...cart, selectedTip},
        });
      },
      setCouponCode: (couponCode?: string) => {
        const cart = state.cart ?? initialCart;
        setContextState({
          cart: {...cart, couponCode},
        });
      },
    }),
    [state]
  );

  useEffect(() => {
    let hasChanged = false;

    if (hasCartExpired(state.lastUpdate) || !state.cart) {
      state.cart = initialCart;
      hasChanged = true;
    }

    if (hasLocationExpired(state.lastUpdate)) {
      state.location = undefined;
      hasChanged = true;
    }

    if (hasCustomerTokenExpired(state.customer?.token)) {
      state.customer = undefined;
      hasChanged = true;
    }

    if (hasChanged) {
      setState({...state});
    }
  }, [state.lastUpdate]);

  return (
    <AppContext.Provider value={{...state, setContextState, cartReducer}} {...props}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error(`useAppContext must be used within an AppProvider`);
  }

  return context;
};

const hasCartExpired = (lastUpdate?: string) => {
  return lastUpdate && DateTime.fromISO(lastUpdate).diffNow('minutes').minutes > 20;
};

const hasLocationExpired = (lastUpdate?: string) => {
  return lastUpdate && DateTime.fromISO(lastUpdate).diffNow('minutes').minutes > 60;
};

const hasCustomerTokenExpired = (token?: string) => {
  if (token) {
    const customer = jwtDecode(token) as Customer & {
      exp: number;
    };

    return DateTime.fromSeconds(customer.exp) <= DateTime.now();
  }

  return true;
};
