import React from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {useAppContext} from '../../contexts/app.context';
import {SignInDialog} from '../dialogs/SignInDialog';

export interface PrivateRouteProps extends RouteProps {
  showTo?: string[];
}

export function PrivateRoute({showTo, ...routeProps}: PrivateRouteProps) {
  const {customer} = useAppContext();

  if (customer === undefined) {
    return <SignInDialog open={true} onOpenChange={(open) => !open && <Redirect to="/" />} />;
  }
  return <Route {...routeProps} />;
}
