import React from 'react';
import {Badge} from 'shared/badge.component';
import {getPriceText} from 'utils/helpers.utils';

interface ProductPriceProps {
  price: number;
  pricesIncludeTax: boolean | undefined;
  priceWithDiscount: number | undefined;
  taxValue: number;
}

export default function ProductPrice({price, pricesIncludeTax, priceWithDiscount, taxValue}: ProductPriceProps) {
  let productPrice = price;
  if (pricesIncludeTax) {
    productPrice = price + taxValue;
  }

  if (priceWithDiscount != null) {
    return (
      <div className="tw-flex tw-items-center tw-gap-1">
        <span className="tw-mx-1 tw-text-lg tw-font-bold tw-text-orange-300">{getPriceText(priceWithDiscount)}</span>
        <span className="tw-text-sm tw-font-bold tw-text-gray-900 tw-line-through">{getPriceText(productPrice)}</span>
      </div>
    );
  }

  return (
    <div>
      <span className="tw-text-sm tw-font-bold tw-text-gray-900">{getPriceText(productPrice)}</span>
      {pricesIncludeTax && <Badge text="Tax incl." />}
    </div>
  );
}
