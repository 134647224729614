import React from 'react';
import ContentLoader from 'react-content-loader';

export const LocationScenePlaceholder = () => {
  return (
    <section className="location-scene-placeholder">
      <div className="category-list-placeholder">
        {[0, 1, 2].map((index) => (
          <div key={index} className="category-link-placeholder">
            <ContentLoader speed={2} height={16} style={{width: '100%'}} backgroundColor="#f3f3f3" foregroundColor="#e6e6e6">
              <rect x="0" y="0" rx="2" ry="2" width="100%" height="16" />
            </ContentLoader>
          </div>
        ))}
      </div>
      <div>
        {[0, 1].map((index) => (
          <div key={index} className="category-placeholder">
            <div className="category-title-placeholder">
              <ContentLoader speed={2} style={{width: '100%'}} height={16} backgroundColor="#f3f3f3" foregroundColor="#e6e6e6">
                <rect x="0" y="0" rx="2" ry="2" width="130" height="16" />
              </ContentLoader>
            </div>
            <div className="product-list-placeholder">
              {[0, 1, 2].map((index) => (
                <div key={index} className="product-placeholder">
                  <ContentLoader
                    speed={2}
                    style={{width: '100%'}}
                    height={80}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#e6e6e6"
                  >
                    <rect x="0" y="0" width="80" height="80" />
                    <rect x="96" y="0" rx="2" ry="2" width="130" height="12" />
                    <rect x="96" y="24" rx="2" ry="2" width="200" height="6" />
                    <rect x="96" y="36" rx="2" ry="2" width="150" height="6" />
                    <rect x="96" y="68" rx="2" ry="2" width="40" height="16" />
                  </ContentLoader>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
