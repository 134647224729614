import {useAppContext} from 'contexts/app.context';
import {DateTime} from 'luxon';
import React from 'react';
import {useQueryClient} from 'react-query';
import {useParams} from 'react-router-dom';
import {getApplyingPromotion, getStoreImageUrl} from 'utils/helpers.utils';
import {useCountdown} from 'hooks/use-countdown.hook';
import {InformationCircleIcon} from '@heroicons/react/24/outline';
import {Storefront} from 'storefront/components/Storefront';

export default function EventStorefrontPage() {
  const {event, cartReducer} = useAppContext();
  const queryClient = useQueryClient();
  const {eventId} = useParams<{eventId: string}>();

  const applyingPromotion = getApplyingPromotion(event?.discounts ?? []);

  const preorderTimeLeft = useCountdown(
    event ? DateTime.fromISO(event.preorderEndDatetime).setZone(event.store?.timezone ?? 'America/Los_Angeles') : undefined,
    () => {
      cartReducer.resetCart();
      queryClient.refetchQueries(['event', eventId]);
      if (event?.isPreorderingEnabled) {
        window.location.reload();
      }
    }
  );

  const promotionTimeLeft = useCountdown(
    applyingPromotion?.endsAt && event
      ? DateTime.fromISO(applyingPromotion?.endsAt).setZone(event.store?.timezone ?? 'America/Los_Angeles')
      : undefined,
    () => {
      queryClient.invalidateQueries(['event']);
      cartReducer.removeDiscounts();
    }
  );

  if (event?.id !== +eventId) {
    return null;
  }

  const eventDate = DateTime.fromISO(event.startDatetime)
    .setZone(event.store?.timezone ?? 'America/Los_Angeles')
    .toFormat('EEE • LLL dd • hh:mm a');

  const renderNotifications = () => {
    return (
      <>
        <div className="tw-rounded-md tw-bg-blue-50 tw-p-4">
          <div className="tw-flex tw-items-center tw-gap-3">
            <div className="tw-shrink-0">
              <InformationCircleIcon className="tw-h-5 tw-w-5 tw-text-blue-400" aria-hidden="true" />
            </div>
            <div className="tw-flex-1 tw-text-sm tw-text-blue-700 md:tw-flex md:tw-justify-between">
              {preorderTimeLeft ? `Pre-ordering ends ${preorderTimeLeft}` : 'Pre-ordering ended'}
            </div>
          </div>
        </div>
        {!!promotionTimeLeft && applyingPromotion && (
          <div className="tw-rounded-md tw-bg-blue-50 tw-p-4 tw-mt-4">
            <div className="tw-flex tw-items-center tw-gap-3">
              <div className="tw-shrink-0">
                <InformationCircleIcon className="tw-h-5 tw-w-5 tw-text-blue-400" aria-hidden="true" />
              </div>
              <div className="tw-flex-1 tw-text-sm tw-text-blue-700 md:tw-flex md:tw-justify-between">
                {applyingPromotion?.name} ends {promotionTimeLeft}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <Storefront
        menus={event.menus}
        store={event.store}
        title={event.store?.name}
        avatarUrl={getStoreImageUrl(event.store)}
        subtitle={event.name}
        eventDate={eventDate}
        coverImage={event.coverImageUrl}
        discounts={event.discounts}
        renderNotifications={renderNotifications}
        showProductImagePlaceholder={event.store?.settings?.showProductImagePlaceholder}
        pricesIncludeTax={false}
        viewOnly={!event.isPreorderingEnabled}
      />
    </>
  );
}
