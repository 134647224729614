import {cn} from 'common/utils';
import * as React from 'react';

const Label = React.forwardRef<HTMLLabelElement, React.HTMLProps<HTMLLabelElement>>(({className, ...props}, ref) => (
  <label
    ref={ref}
    className={cn(
      'tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-leading-none peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70',
      className
    )}
    {...props}
  />
));
Label.displayName = 'Label';

export {Label};
