import React, {useState} from 'react';

import {ReactComponent as AsapPickupIcon} from 'assets/icons/asap-pickup.icon.svg';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
} from 'common/components/Dialog';
import {useAppContext} from 'contexts/app.context';
import {Button} from 'common/components/Button';

import {cn} from 'common/utils';

interface AgeVerificationDialogProps extends DialogProps {
  onContinue?: () => void;
}

export function AgeVerificationDialog({onContinue, ...dialogProps}: AgeVerificationDialogProps) {
  const [isAgeVerified, setIsAgeVerified] = useState(false);
  const {cartReducer} = useAppContext();

  const onContinueButtonClick = () => {
    if (!isAgeVerified) {
      return null;
    }

    cartReducer.setIsAgeVerified(isAgeVerified);
    onContinue?.();
  };

  return (
    <Dialog {...dialogProps}>
      <DialogContent>
        <DialogHeader className="tw-items-center tw-mb-6">
          <div className="tw-pt-4 tw-pb-6">
            <AsapPickupIcon />
          </div>
          <DialogTitle>Are you 21 or older?</DialogTitle>
          <DialogDescription>
            Some items in your cart require you to be at least 21 years old to place your order
          </DialogDescription>
        </DialogHeader>
        <label className={cn('tw-p-2 tw-mt-6 tw-flex tw-cursor-pointer tw-select-none tw-items-center tw-gap-4 tw-text-sm')}>
          <input
            defaultChecked={isAgeVerified}
            type="checkbox"
            onChange={({currentTarget: {checked}}) => setIsAgeVerified(checked)}
            className="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-blue-600 focus:tw-ring-blue-500"
          />
          I am 21 or older and I will show my ID to the server when I receive my order
        </label>

        <DialogFooter>
          <Button disabled={!isAgeVerified} size={'lg'} onClick={onContinueButtonClick}>
            Continue
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
