import queryString from 'query-string';

export type QueryParams = Record<string, string | number | string[] | number[] | undefined>;

export interface ApiCallConfig extends RequestInit {
  token?: string;
  params?: QueryParams;
  isMultipart?: boolean;
}

export interface ApiError<T> {
  response: Response;
  error?: Error;
  data?: T;
}

export interface ApiResponse<T, E = undefined> {
  data: T;
  error?: ApiError<E>;
}

export const getApiEndpoint = (endpoint: string, params: QueryParams = {}, isLegacy: boolean = false): string => {
  const host = process.env.REACT_APP_API_BASE_URL;
  // const host = 'http://localhost:3333';
  // const host = 'https://d8ee443162ec.ngrok.io';
  const queryParams = Object.keys(params ?? {}).length > 0 ? `?${queryString.stringify(params)}` : '';
  return `${host}/api/${isLegacy ? '' : 'v3'}${endpoint}${queryParams}`;
};

export const callApi = async <T, E = undefined>(
  endpoint: string,
  {token, params, ...config}: ApiCallConfig,
  isLegacy: boolean = false
): Promise<ApiResponse<T, E>> => {
  return fetch(getApiEndpoint(endpoint, params, isLegacy), {
    ...config,
    mode: 'cors',
    headers: {
      ...(config.headers ?? null),
      ...(token ? {Authorization: `Bearer ${token}`} : null),
      ...(config.body && !config.isMultipart
        ? {'Content-Type': (config.headers as any)?.['Content-Type'] ?? `application/json`}
        : null),
    },
  }).then(async (response) => {
    if (response.ok) {
      const responseJson = await response.json();

      if (isLegacy) {
        return {data: responseJson};
      }

      if (responseJson.data != null) {
        return {...responseJson, data: responseJson.data as T};
      }

      throw new Error('no_data_field_in_response');
    }

    const {error, data}: {error: Error; data: E} = await response.json().catch(() => {});
    throw {response, error, data};
  });
};
