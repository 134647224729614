import React, {Fragment, useState} from 'react';

import kontactlessSvg from '../../assets/kontactless.svg';
import {Dialog, Transition} from '@headlessui/react';
import {
  ArrowDownOnSquareIcon,
  ArrowLeftOnRectangleIcon,
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  CreditCardIcon,
  DocumentTextIcon,
  GlobeAltIcon,
  ListBulletIcon,
  QuestionMarkCircleIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {useHistory} from 'react-router-dom';
import {PathName, PathsObject, paths} from 'utils/paths';
import {useAppContext} from 'contexts/app.context';
import {slugify} from 'utils/helpers.utils';
import {SignInDialog} from 'components/dialogs/SignInDialog';
import {Button} from './Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './DropdownMenu';

interface NavigationItem {
  name: string;
  goTo: () => void;
  icon: React.ForwardRefExoticComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
      titleId?: string | undefined;
    }
  >;
}

function NavigationItem({item}: {item: NavigationItem}) {
  return (
    <div
      key={item.name}
      onClick={item.goTo}
      className="tw-flex tw-items-center tw-rounded-md tw-py-2 tw-px-3 tw-text-sm tw-font-medium tw-text-blue-700 hover:tw-bg-blue-700 hover:tw-text-white group"
    >
      <item.icon className="tw-mr-2 tw-h-6 tw-w-6 tw-text-blue-500 group-hover:tw-text-white" aria-hidden="true" />
      <span>{item.name}</span>
    </div>
  );
}

export const Layout: React.FC = ({children}) => {
  // const [isNavigatorOpen, setIsNavigatorOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSignInDialogOpen, setIsSignInDialogOpen] = useState(false);
  const {location, store, setContextState, customer} = useAppContext();
  const history = useHistory();

  const goTo = function <T extends PathName>(scene: T, ...args: Parameters<PathsObject[T]['generatePath']>) {
    // Hacky fix for KON-360 (Safari history back cache problem)
    setTimeout(() => {
      history.push(paths[scene].generatePath(...args));
    }, 150);
    setMobileMenuOpen(false);
  };

  const signOut = () => {
    setContextState({customer: undefined});
  };

  const sidebarNavigation: NavigationItem[] = [
    {
      name: 'Make an order',
      goTo: () => {
        if (!location && !!store) {
          return goTo('events', store.id);
        }
        return location?.section.menus ? goTo('location', slugify(location.section?.store?.name || '')) : goTo('qrScanner');
      },
      icon: ArrowDownOnSquareIcon,
    },
  ];

  const userNavigation = [
    {name: 'Payment methods', goTo: () => goTo('paymentMethods'), icon: CreditCardIcon},
    {name: 'Orders', goTo: () => goTo('orders'), icon: ListBulletIcon},
    {name: 'Sign out', goTo: () => signOut(), icon: ArrowLeftOnRectangleIcon},
  ];

  const kontactlessNavigation: NavigationItem[] = [
    {name: 'FAQs', goTo: () => goTo('faq'), icon: QuestionMarkCircleIcon},
    {
      name: 'Privacy policy',
      goTo: () => window.open('https://www.kontactless.io/privacy-policy', '_newtab'),
      icon: DocumentTextIcon,
    },
    {name: 'Terms of use', goTo: () => window.open('https://www.kontactless.io/terms-of-use', '_newtab'), icon: DocumentTextIcon},
    {name: 'About us', goTo: () => window.open('https://www.kontactless.io/', '_newtab'), icon: GlobeAltIcon},
  ];
  return (
    <>
      <div className="tw-flex tw-h-full">
        {/* Mobile menu */}
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog as="div" className="tw-relative tw-z-20" onClose={setMobileMenuOpen}>
            <Transition.Child
              as={Fragment}
              enter="tw-transition-opacity tw-ease-linear tw-duration-200"
              enterFrom="tw-opacity-0"
              enterTo="tw-opacity-100"
              leave="tw-transition-opacity tw-ease-linear tw-duration-200"
              leaveFrom="tw-opacity-100"
              leaveTo="tw-opacity-0"
            >
              <div className="tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-75" />
            </Transition.Child>

            <div className="tw-fixed tw-inset-0 tw-z-40 tw-flex">
              <Transition.Child
                as={Fragment}
                enter="tw-transition tw-ease-in-out tw-duration-200 tw-transform"
                enterFrom="-tw-translate-x-full"
                enterTo="tw-translate-x-0"
                leave="tw-transition tw-ease-in-out tw-duration-200 tw-transform"
                leaveFrom="tw-translate-x-0"
                leaveTo="-tw-translate-x-full"
              >
                <Dialog.Panel className="tw-relative tw-flex tw-w-full tw-max-w-xs tw-flex-1 tw-flex-col tw-bg-white tw-pt-5 tw-pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="tw-ease-in-out tw-duration-200"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in-out tw-duration-200"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                  >
                    <div className="tw-flex tw-items-center">
                      <div>
                        <img src={kontactlessSvg} alt="Kontactless" />
                      </div>
                      <button
                        type="button"
                        className="tw-mr-6 tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-white"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <XMarkIcon className="tw-h-6 tw-w-6 tw-text-gray-800" aria-hidden="true" />
                        <span className="tw-sr-only">Close sidebar</span>
                      </button>
                    </div>
                  </Transition.Child>

                  <div className="tw-mt-5 tw-h-0 tw-flex-1 tw-overflow-y-auto tw-px-2">
                    <nav className="tw-flex tw-h-full tw-flex-col tw-justify-between">
                      <div className="tw-space-y-1">
                        {sidebarNavigation.map((item) => (
                          <NavigationItem key={item.name} item={item} />
                        ))}
                        {!!customer && userNavigation.map((item) => <NavigationItem key={item.name} item={item} />)}
                      </div>

                      <div className="tw-space-y-1">
                        {kontactlessNavigation.map((item) => (
                          <NavigationItem key={item.name} item={item} />
                        ))}
                      </div>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Content area */}
        <div className="tw-flex tw-flex-1 tw-flex-col tw-overflow-hidden">
          <header className="tw-w-full">
            <div className="tw-relative tw-z-10 tw-flex tw-h-16 tw-shrink-0 tw-border-b tw-border-solid tw-border-gray-200 tw-bg-white">
              <button
                type="button"
                className=" tw-px-4 tw-text-gray-500 focus:tw-outline-none focus:tw-ring-0 focus:tw-ring-inset focus:tw-ring-indigo-500"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="tw-sr-only">Open sidebar</span>
                <Bars3Icon className="tw-h-6 tw-w-6" aria-hidden="true" />
              </button>
              <div className="tw-flex tw-flex-1 tw-justify-between tw-px-4 sm:tw-px-6">
                <div className="tw-flex tw-items-center tw-space-x-4 sm:tw-space-x-4 tw-ml-auto">
                  {/* Profile dropdown */}
                  {customer ? (
                    <div>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="subtle">
                            My account <UserCircleIcon className="tw-h-5 tw-w-5 tw-ml-2 " aria-hidden="true" />
                          </Button>
                        </DropdownMenuTrigger>

                        <DropdownMenuContent
                          side="bottom"
                          align="end"
                          className="tw-mt-2 tw-w-48 tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none"
                        >
                          <DropdownMenuLabel className="tw-px-4 tw-py-3">
                            <p className="tw-text-xs tw-text-gray-700">Signed in with</p>
                            <p className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-900">{customer.phone}</p>
                          </DropdownMenuLabel>
                          <DropdownMenuSeparator />
                          {userNavigation.map((item) => (
                            <DropdownMenuItem key={item.name} className="tw-px-4 tw-py-3">
                              <a onClick={() => item.goTo()}>{item.name}</a>
                            </DropdownMenuItem>
                          ))}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  ) : (
                    <Button type="button" variant="ghost" onClick={() => setIsSignInDialogOpen(true)}>
                      Sign in
                      <ArrowRightOnRectangleIcon className="tw-h-5 tw-w-5 tw-ml-1" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </header>

          {children}
        </div>

        {customer ? null : <SignInDialog open={isSignInDialogOpen} onOpenChange={(open) => setIsSignInDialogOpen(open)} />}
      </div>
    </>
  );
};
