import {useAppContext} from 'contexts/app.context';
import {useHistory} from 'react-router-dom';
import {useQueryClient} from 'react-query';
import {getApplyingPromotion, getStoreImageUrl, isDateInOpeningHours} from 'utils/helpers.utils';
import {NonIdealState} from 'shared/non-ideal-state.component';
import {ReactComponent as EmptyOrdersIcon} from 'assets/icons/empty-orders.icon.svg';
import {paths} from 'utils/paths';
import {InformationCircleIcon} from '@heroicons/react/24/outline';
import {useCountdown} from 'hooks/use-countdown.hook';
import {DateTime} from 'luxon';
import {Storefront} from 'storefront/components/Storefront';

export default function LocationStorefrontPage() {
  const {location, cartReducer} = useAppContext();
  const queryClient = useQueryClient();
  const history = useHistory();

  const applyingPromotion = getApplyingPromotion(location?.section.discounts ?? []);
  const promotionTimeLeft = useCountdown(
    applyingPromotion?.endsAt ? DateTime.fromISO(applyingPromotion?.endsAt) : undefined,
    () => {
      queryClient.invalidateQueries(['location']);
      cartReducer.removeDiscounts();
    }
  );

  const isStoreClosed =
    !isDateInOpeningHours(DateTime.local().setZone(location?.section?.store?.timezone!), location?.section?.openingHours ?? []) &&
    location?.section.areSchedulableOrdersAllowed;

  if (!location) {
    return (
      <NonIdealState
        icon={<EmptyOrdersIcon />}
        title="Location not found"
        description="The location was not found. Please, scan another QR code."
        action={{
          text: 'Scan another QR',
          callback: () => history.push(paths.qrScanner.route),
        }}
      />
    );
  }

  const renderNotifications = () => {
    return (
      <>
        {applyingPromotion && promotionTimeLeft && (
          <div className="tw-rounded-md tw-bg-blue-50 tw-p-4">
            <div className="tw-flex tw-items-center tw-gap-3">
              <div className="tw-shrink-0">
                <InformationCircleIcon className="tw-h-5 tw-w-5 tw-text-blue-400" aria-hidden="true" />
              </div>
              <div className="tw-flex-1 tw-text-sm tw-text-blue-700 md:tw-flex md:tw-justify-between">
                {applyingPromotion.name} ends {promotionTimeLeft}
              </div>
            </div>
          </div>
        )}

        {isStoreClosed && (
          <div className="tw-rounded-md tw-bg-blue-50 tw-p-4">
            <div className="tw-flex tw-items-center tw-gap-3">
              <div className="tw-shrink-0">
                <InformationCircleIcon className="tw-h-5 tw-w-5 tw-text-blue-400" aria-hidden="true" />
              </div>
              <div className="tw-flex-1 tw-text-sm tw-text-blue-700 md:tw-flex md:tw-justify-between">
                Store is closed but you can still order for later
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <Storefront
      avatarUrl={location.section.imageUrl ?? getStoreImageUrl(location.section.store)}
      menus={location.section.menus}
      store={location.section.store}
      pricesIncludeTax={location.pricesIncludeTax}
      discounts={location.section.discounts}
      title={location.section.store?.name}
      subtitle={location.name}
      renderNotifications={renderNotifications}
      showProductImagePlaceholder={location.section.store?.settings?.showProductImagePlaceholder}
      viewOnly={location.section.isViewOnly}
    />
  );
}
