import React from 'react';
import {FieldErrorsImpl, UseFormProps, UseFormRegister, useForm} from 'react-hook-form';
import classNames from 'classnames';

import {useAppContext} from 'contexts/app.context';

import {Label} from 'common/components/Label';
import {cn} from 'common/utils';
import {Input} from 'common/components/Input';

export interface RoomChargeDetails {
  roomNumber: string;
  roomGuestLastName: string;
}

export function useRoomChargeFormController(
  {onSubmitted, ...props}: UseFormProps<RoomChargeDetails> & {onSubmitted?: () => void} = {mode: 'onBlur'}
) {
  const {cartReducer} = useAppContext();

  const formController = useForm<RoomChargeDetails>({...props});

  const onSubmit = (pickupDetailsFormData: RoomChargeDetails) => {
    const {roomNumber, roomGuestLastName} = pickupDetailsFormData;

    cartReducer.setRoomChargeDetails(roomNumber, roomGuestLastName);

    onSubmitted?.();
  };

  const submit = formController.handleSubmit(onSubmit);

  return {submit, formId: 'room-charge-form', ...formController};
}

interface RoomChargeFormProps extends React.HTMLProps<HTMLFormElement> {
  errors: FieldErrorsImpl<RoomChargeDetails>;
  register: UseFormRegister<RoomChargeDetails>;
}

export const RoomChargeForm = React.forwardRef<HTMLFormElement, RoomChargeFormProps>(
  ({className, errors, register, ...props}, ref) => (
    <form ref={ref} className={cn(className)} {...props}>
      <div className={classNames('kl-input-group', {'kl-input-group-danger': errors.roomNumber})}>
        <div className="tw-mb-1">
          <Label htmlFor="roomNumber">Room number</Label>
        </div>
        <Input id="roomNumber" type="text" {...register('roomNumber', {required: true})} />
        {errors.roomNumber ? <p className="kl-input-helper">Room number is required</p> : null}
      </div>

      <div className={classNames('kl-input-group', {'kl-input-group-danger': errors.roomGuestLastName})}>
        <div className="tw-mb-1">
          <Label htmlFor="roomGuestLastName">Last name</Label>
        </div>
        <Input id="roomGuestLastName" type="text" {...register('roomGuestLastName', {required: true})} />
        {errors.roomGuestLastName ? <p className="kl-input-helper">Last name is required</p> : null}
      </div>
    </form>
  )
);
RoomChargeForm.displayName = 'RoomChargeForm';
