import React from 'react';
import {useCountdown} from 'hooks/use-countdown.hook';
import {DateTime} from 'luxon';
import {Discount} from 'models/discount.model';
import {getApplyingPromotion} from 'utils/helpers.utils';
import {InformationCircleIcon} from '@heroicons/react/20/solid';

interface ActiveDiscountNotificationProps {
  discounts?: Discount[];
  onFinished?: () => void;
}

export const ActiveDiscountNotification: React.FC<ActiveDiscountNotificationProps> = ({discounts, onFinished}) => {
  const applyingPromotion = getApplyingPromotion(discounts ?? []);
  const promotionTimeLeft = useCountdown(
    applyingPromotion?.endsAt ? DateTime.fromISO(applyingPromotion?.endsAt) : undefined,
    onFinished
  );

  if (applyingPromotion && promotionTimeLeft) {
    return (
      <div className="tw-rounded-md tw-bg-blue-50 tw-p-4">
        <div className="tw-flex tw-items-center tw-gap-3">
          <div className="tw-shrink-0">
            <InformationCircleIcon className="tw-h-5 tw-w-5 tw-text-blue-400" aria-hidden="true" />
          </div>
          <div className="tw-flex-1 tw-text-sm tw-text-blue-700 md:tw-flex md:tw-justify-between">
            {applyingPromotion.name} ends {promotionTimeLeft}
          </div>
        </div>
      </div>
    );
  }

  return null;
};
