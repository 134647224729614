import classNames from 'classnames';
import React from 'react';

interface SpinnerProps {
  className?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({className}) => {
  return (
    <div className={classNames('kl-spinner', className)}>
      <div className="kl-spinner-animation">
        <svg width="50" height="50" strokeWidth="8.00" viewBox="1.00 1.00 98.00 98.00">
          <path className="kl-spinner-track" d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90"></path>
          <path
            className="kl-spinner-head"
            d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90"
            pathLength="280"
            strokeDasharray="280 280"
            strokeDashoffset="210"
          ></path>
        </svg>
      </div>
    </div>
  );
};
