import {useAppContext} from 'contexts/app.context';
import {PaymentMethod} from 'models/payment-method.model';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import {
  SaveFreedompayPaymentMethodRequest,
  createFreedompayPaymentMethodIframe,
  saveFreedompayPaymentMethod,
} from 'api/payment.api';
import {useStateRef} from 'hooks/use-state-ref.hook';
import {Label} from 'common/components/Label';
import {Input} from 'common/components/Input';
import {Spinner} from 'components/ui/spinner.component';

// TODO: Load from a CSS file with raw-loader
const iframeStyles = `{
  label {
    color: #374151;
    font-size: 14px;
    font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  }
  input {
    color: #374151;
    font-size: 14px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    padding: .375rem .75rem;
    font-size: 1rem;
    outline: none;
    width: 100%;
    box-shadow: 0 1px 2px 0 #e5e7eb;
    background-color: white;
    font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  }
  .invalid input:not(:disabled) {
    border: 1px solid #EF5251;
    color: #EF5251;
    box-shadow: none;
  }
  .invalid input:not(:disabled):focus {
    border: 1px solid #EF5251;
    transition: all 150ms ease;
    box-shadow: none;
  }
  .valid input:not(:disabled) {
    border-color: 1px solid #898989;
    box-shadow: none;
  }
  .valid input:not(:disabled):focus {
    border: 1px solid #898989;
    transition: all 150ms ease;
    box-shadow: none;
  }
  .validation-message.feedback {
    color: #EF5251;
    font-size:12px;
    font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  }
  button {
    background-color: rgba(29, 78, 216, 1);
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
    font-weight: 700;
    border: 1px solid rgba(29, 78, 216, 1);
    border-radius: 6px;
    color: #FFF;
    font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  }
  button:disabled {
    background-color: #8ee0f5;
    border: 1px solid #8ee0f5;
  }
  button:hover {
    background-color: #19aed4;
    border: 1px solid #19aed4;
  }
  button:focus {
    box-shadow: none;
  }
}`;

interface FreedompayFormProps {
  onCreated: (paymentMethod: PaymentMethod) => void;
}

export function FreedompayPaymentMethodForm({onCreated}: FreedompayFormProps) {
  const {location, event, customer} = useAppContext();

  const queryClient = useQueryClient();

  const storeId = location?.section.storeId || event?.storeId;

  const [iframeSrc, setIframeSrc] = useState('');
  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const [iframeHeight, setIframeHeight] = useState(0);
  const [isCardholderIncomplete, setIsCardholderIncomplete] = useState(false);

  const [cardholderName, setCardholderName, cardholderNameRef] = useStateRef('');
  const [, setSessionKey, sessionKeyRef] = useStateRef('');

  const savePaymentMethod = useMutation<PaymentMethod, Error, SaveFreedompayPaymentMethodRequest>(
    (body) => saveFreedompayPaymentMethod(customer!.token, body),
    {
      onSuccess: (paymentMethod) => {
        queryClient.invalidateQueries('payment-methods');
        onCreated?.(paymentMethod);
      },
    }
  );

  useEffect(() => {
    const loadIframe = async () => {
      setIsIframeLoading(true);
      try {
        const response = await createFreedompayPaymentMethodIframe(customer?.token!, {iframeStyles, storeId: storeId!});
        const regex = /<iframe.*?src="(.*?)".*?<\/iframe>/;
        const resultRegex = regex.exec(response.iframe);
        const iframeSrc = resultRegex && resultRegex.length > 1 ? resultRegex[1] : '';
        setIframeSrc(iframeSrc);
        setSessionKey(response.sessionKey);
      } catch (error) {
        console.error(error);
      }
      setIsIframeLoading(false);
    };
    loadIframe();

    window.addEventListener('message', handleFormSubmit);

    return () => window.removeEventListener('message', handleFormSubmit);
  }, []);

  const onCardholderNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    if (!name) {
      setIsCardholderIncomplete(true);
    } else {
      setIsCardholderIncomplete(false);
    }
    setCardholderName(name);
  };

  const handleFormSubmit = (event: MessageEvent) => {
    // Since this is a handler for the "message" event of the window object, we need to check that who originated this event was Freedompay's HPC SDK
    if (
      event.origin === 'https://hpc.uat.freedompay.com' ||
      event.origin === 'https://hpc.freedompay.com' ||
      event.origin.indexOf('10.10') >= 0
    ) {
      const message = event.data;

      switch (message.type) {
        case 1: // Errors
          console.error(message); // TODO: Show toast
          break;
        case 2: // Frame height
          const {height} = message.data;
          setIframeHeight(height);
          break;
        case 3: // Payment key used to complete the payment request
          const data: {
            attributes: Array<{
              Key: 'CardIssuer' | 'MaskedCardNumber' | 'PostalCode';
              Value: 'Visa' | 'Mastercard';
            }>;
            paymentKeys: string[];
          } = message.data;

          const [paymentKey] = data.paymentKeys;
          const {Value: cardBrand} = data.attributes.find((attribute) => attribute.Key === 'CardIssuer')!;

          savePaymentMethod.mutate({
            sessionKey: sessionKeyRef.current,
            paymentKey,
            cardBrand,
            cardholderName: cardholderNameRef.current,
            storeId: storeId!,
          });
          break;
        case 4: // Fields validations.
          break;
      }
    }
  };

  if (isIframeLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="tw-mb-4">
        <div className="tw-mb-1">
          <Label htmlFor="cardholder-name">Cardholder Name</Label>
        </div>
        <Input placeholder="Your name" id="cardholder-name" value={cardholderName} onChange={onCardholderNameChange} />
        {isCardholderIncomplete ? <p className="kl-input-helper">Cardholder name is required</p> : null}
      </div>
      <iframe src={iframeSrc} width="100%" className="-tw-mx-2" height={iframeHeight} frameBorder="0" scrolling="no" />
    </div>
  );
}
