import {CouponCodeForm} from './CouponCodeForm';
import {Tip, TipSelector} from './TipSelector';
import {CheckCircleIcon, InformationCircleIcon} from '@heroicons/react/24/solid';
import {OrderAttributesDialog} from 'checkout/components/OrderAttributesDialog';
import {OrderAttributesForm} from 'checkout/components/OrderAttributesForm';
import {PickupDetailsDialog} from 'checkout/components/PickupDetailsDialog';
import {PickupDetailsForm} from 'checkout/components/PickupDetailsForm';
import {Button} from 'common/components/Button';
import {Collapsible, CollapsibleContent, CollapsibleTrigger} from 'common/components/Collapsible';
import {Popover, PopoverContent, PopoverTrigger} from 'common/components/Popover';
import {RadioGroup, RadioGroupItemWithIndicator} from 'common/components/RadioGroup';
import {cn} from 'common/utils';
import {Alert} from 'components/alerts/alert.component';
import {ErrorAlert} from 'components/alerts/error-alert.component';
import {SignInDialog, useSignInState} from 'components/dialogs/SignInDialog';
import {FreedompayWalletPaymentButton} from 'checkout/components/freedompay/FreedompayWalletPaymentButton';
import {StripeWalletPaymentButton} from 'checkout/components/stripe/StripeWalletPaymentButton';
import {ActiveDiscountNotification} from 'components/ui/active-discount-notification.component';
import {useAppContext} from 'contexts/app.context';
import {StripeProvider} from 'contexts/stripe.context';
import {useWindowSize} from 'hooks/use-window-size.hook';
import capitalize from 'lodash/capitalize';
import {ChevronRight, Loader2, PencilIcon, ShoppingCart} from 'lucide-react';
import {DateTime} from 'luxon';
import {Customer} from 'models/customer.model';
import {Discount} from 'models/discount.model';
import {Location} from 'models/location.model';
import {OrderCreateRequest, OrderSummary, OrderSummaryItem, UnavailableItems} from 'models/order.model';
import {PaymentMethod, WalletPaymentIntentData} from 'models/payment-method.model';
import {StoreOrderAttributeGorup} from 'models/store.model';
import {PaymentMethodItem} from 'payment-methods/components/PaymentMethodItems';
import {SelectPaymentMethodDialog} from 'payment-methods/components/SelectPaymentMethodDialog';
import {SelectPaymentMethodTabs, useSelectPaymentMethodTabs} from 'payment-methods/components/SelectPaymentMethodTabs';
import {useMemo, useState} from 'react';
import {useIsMutating, useQueryClient} from 'react-query';
import {Link} from 'react-router-dom';
import {MOBILE_BREAKPOINT_WIDTH} from 'utils/constants';
import {getCustomerFullName, getLocationName, getPriceText} from 'utils/helpers.utils';
import {CountryCode, Currency, OrderingFlowType} from 'utils/types';
import {SendVerificationCodeForm} from 'components/dialogs/SendVerificationCodeForm';
import {formatPhoneNumberIntl} from 'react-phone-number-input';
import {VerifyCodeForm} from 'components/dialogs/VerifyCodeForm';
import {CreatePaymentMethodDialog} from 'payment-methods/components/CreatePaymentMethodDialog';
import OrderItem from 'storefront/components/OrderItem';
import {getOrderItemModifiers} from 'storefront/components/CartSidebar';
import {LocationDetailsDialog} from './LocationDetailsComponent';
import {AgeVerificationDialog} from './AgeVerificationDialog';
import {LocationDetailsForm} from './LocationDetailsForm';
import ScheduleOrderDialog from './ScheduleOrderDialogComponent';

import Carousel from 'components/Carousel';
import ProductDialog from 'storefront/components/ProductDialog';
import {UpsellingItem} from 'models/upselling-item.model';

const CHECKOUT_STEPS = [
  'idle',
  'sign-in',
  'verify-age',
  // 'set-schedule-info',
  'set-pickup-info',
  'set-location-info',
  'set-order-custom-attributes',
  'select-payment-method',
] as const;

type CheckoutStep = typeof CHECKOUT_STEPS[number];

interface CheckoutProps {
  locationId?: number;
  locationGroupId?: number;
  locationQrType?: Location['type'];
  locationType?: Location['locationType'];
  pricesIncludeTax?: boolean;
  discounts: Discount[];
  eventId?: number;
  createOrder: (payload: OrderCreateRequest) => void;
  isCreatingOrder: boolean;
  menuHref: string;
  orderItems: OrderSummaryItem[];
  upsellingItems: UpsellingItem[];
  totals: Pick<
    OrderSummary,
    | 'productsSubtotal'
    | 'feesSubtotal'
    | 'surchargesSubtotal'
    | 'discountsSubtotal'
    | 'tipsSubtotal'
    | 'refundsSubtotal'
    | 'subtotal'
    | 'tax'
    | 'total'
  >;
  unavailableItems?: UnavailableItems;
  orderingFlowType?: OrderingFlowType;
  orderAttributeGroup?: StoreOrderAttributeGorup;
  needsAgeVerification: boolean;
  hasPaymentMethods: boolean;
  areSchedulableOrdersAllowed: boolean;
  isOrderingAsapAllowed: boolean;
  areTipsEnabled: boolean;
  tipOptions: Tip[];
  customer?: Customer;
  paymentMethods: PaymentMethod[];
  allowsRoomPayment: boolean;
  termsAndConditionsMessage?: string;
  currency: Currency;
  country: CountryCode;
  isFetchingOrderSummary: boolean;
  availableDiscounts: Discount[];
  hasActiveCoupons: boolean;
  isPreorder: boolean;
  timezone: string;
}

type CheckotStepStatus = {isRequired: boolean; isComplete: boolean; isCurrent?: boolean};

function getNextStep(steps: Record<CheckoutStep, CheckotStepStatus>): CheckoutStep {
  // iterate steps and find first next incomplete step
  return (Object.entries(steps).find(([, {isRequired, isComplete}]) => isRequired && !isComplete)?.[0] ?? 'idle') as CheckoutStep;
}

export function Checkout({
  locationId,
  locationGroupId,
  locationType,
  locationQrType,
  pricesIncludeTax = false,
  discounts,
  eventId,
  createOrder,
  isCreatingOrder,
  orderItems,
  upsellingItems,
  totals,
  unavailableItems,
  needsAgeVerification,
  orderingFlowType,
  orderAttributeGroup,
  areSchedulableOrdersAllowed,
  isOrderingAsapAllowed,
  isFetchingOrderSummary,
  areTipsEnabled,
  tipOptions,
  customer,
  hasPaymentMethods,
  paymentMethods,
  allowsRoomPayment,
  termsAndConditionsMessage,
  availableDiscounts,
  hasActiveCoupons,
  currency,
  country,
  menuHref,
  isPreorder,
  timezone,
}: CheckoutProps) {
  // UI misc
  const [requestError, setRequestError] = useState<{title: string; message: string} | undefined>();
  const {windowWidth} = useWindowSize();

  const isMobile = windowWidth < MOBILE_BREAKPOINT_WIDTH;

  const queryClient = useQueryClient();
  const {cart, paymentGateway, cartReducer, location} = useAppContext();
  const [isProductDialogOpen, setIsProductDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<{productId: number; menuCategoryProductId: number} | undefined>();

  const isUpdatingCustomer = Boolean(useIsMutating({mutationKey: 'update-customer'}));
  const [overrideCurrentStep, setOverrideCurrentStep] = useState<CheckoutStep | null>(null);
  const [isCreatePaymentMethodDialogOpen, setIsCreatePaymentMethodDialogOpen] = useState(false);
  const [isOpenOrderItemsCollapse, setIsOpenOrderItemsCollapse] = useState(false);

  const steps: Record<CheckoutStep, CheckotStepStatus> = useMemo(
    () => ({
      idle: {
        isRequired: true,
        isComplete: true,
      },
      'sign-in': {
        isRequired: true,
        isComplete: Boolean(customer),
      },
      'verify-age': {
        isRequired: needsAgeVerification,
        isComplete: Boolean(cart?.isAgeVerified),
      },
      'set-pickup-info': {
        isRequired: orderingFlowType === 'pickup',
        isComplete: Boolean(cart?.pickupName),
      },
      'set-location-info': {
        isRequired: locationQrType === 'location-group' && orderingFlowType !== 'pickup',
        isComplete: Boolean(cart?.locationDetails),
      },
      'set-order-custom-attributes': {
        isRequired: !!orderAttributeGroup,
        isComplete: Boolean(cart?.orderGroupsWithAttributes && cart?.orderGroupsWithAttributes?.length > 0),
      },
      'select-payment-method': {
        isRequired: hasPaymentMethods,
        isComplete: Boolean(cart?.payment || (cart?.roomGuestLastName && cart.roomNumber)),
      },
      'set-schedule-info': {
        isRequired: areSchedulableOrdersAllowed && !isOrderingAsapAllowed,
        isComplete: Boolean(cart?.scheduleDate),
      },
    }),
    [
      needsAgeVerification,
      locationQrType,
      cart?.isAgeVerified,
      cart?.pickupName,
      cart?.locationDetails,
      cart?.orderGroupsWithAttributes,
      cart?.payment,
      cart?.roomGuestLastName,
      cart?.roomNumber,
      cart?.scheduleDate,
      areSchedulableOrdersAllowed,
      orderingFlowType,
      orderAttributeGroup,
      hasPaymentMethods,
      customer,
      isOrderingAsapAllowed,
    ]
  );

  const currentStep = overrideCurrentStep || getNextStep(steps);
  const isReadyToPlaceOrder = Object.values(steps).every((step) => !step.isRequired || step.isComplete);

  // Form sections
  const selectPaymentMethodTabs = useSelectPaymentMethodTabs({
    onContinue: () => setOverrideCurrentStep(null),
    paymentMethods,
  });

  const signInState = useSignInState();

  const isCheckoutStepComplete = (step: CheckoutStep) => steps[step].isComplete ?? false;
  const isCheckoutStepRequired = (step: CheckoutStep) => steps[step].isRequired ?? false;

  const [isScheduleOrderDialogOpen, setIsScheduleOrderDialogOpen] = useState(false);
  const isSignInStepActive = currentStep === 'sign-in';
  const isAgeVerificationStepActive = currentStep === 'verify-age';
  const isPickupDetailsStepActive = currentStep === 'set-pickup-info';
  const isSelectPaymentMethodStepActive = currentStep === 'select-payment-method';
  const isOrderAttributesStepActive = currentStep === 'set-order-custom-attributes';
  const isLocationAttributesStepActive = currentStep === 'set-location-info';

  // Order items by type
  const productItems = cart?.items ?? [];
  const additionalCharges = orderItems.filter(({type}) => type === 'surcharge') ?? [];
  const appliedDiscounts = orderItems.filter(({type}) => type === 'discount') ?? [];

  // Ordering flow config
  const isPickup = orderingFlowType === 'pickup';
  const isSingleLocation = locationQrType === 'location-group';

  // Checkout required steps
  const canEnterCouponCode = appliedDiscounts.length === 0 && hasActiveCoupons;

  const isPaymentInfoComplete = isCheckoutStepComplete('select-payment-method');

  const orderCreateRequest: OrderCreateRequest = {
    locationId,
    locationGroupId,
    eventId,
    tip: cart?.selectedTip?.value ?? 0,
    couponCode: cart?.couponCode,
    customerNotes: cart!.customerNotes,
    type: isPickup ? 'takeaway' : 'dine-in',
    payment: cart?.payment,
    locationName: isSingleLocation ? (isPickup ? location?.name : getLocationName(cart?.locationDetails)) : undefined,
    pickupName: isPickup ? cart?.pickupName : undefined,
    pickupInfo: isPickup && cart?.pickupInfo ? cart?.pickupInfo : undefined,
    scheduleDate:
      cart?.scheduleDate && cart?.scheduleDate !== 'asap'
        ? DateTime.fromISO(cart?.scheduleDate, {zone: timezone}).toISO()
        : undefined,
    roomNumber: allowsRoomPayment && cart?.roomNumber && isPaymentInfoComplete ? cart?.roomNumber : undefined,
    roomGuestLastName:
      allowsRoomPayment && cart?.roomGuestLastName && isPaymentInfoComplete ? cart?.roomGuestLastName : undefined,
    totalShownToCustomer: totals.total,
    orderAttributeValues: cart?.orderGroupsWithAttributes?.flatMap((group) => group.attributes.map((attribute) => attribute)),
    isPreorder,
    items: cart!.items.map((cartItem) => {
      return {
        productId: cartItem.product.id,
        quantity: cartItem.quantity,
        customerNotes: cartItem.customerNotes,
        menuCategoryProductId: cartItem.menuCategoryProductId,
        addedInUpselling: cartItem.addedInUpselling,
        modifierGroups: cartItem.modifierGroups.map(({modifierGroupId, modifiers}) => ({
          modifierGroupId,
          modifiers: modifiers.map(({modifierId}) => ({modifierId})),
        })),
      };
    }),
  };

  const getUnavailableItemsMessages = (): Array<string> => {
    if (!!!unavailableItems) {
      return [];
    }

    const unavailableItemsList: Array<string> = [];
    const {products, modifierGroups, modifiers} = unavailableItems!;
    const currentItems = cart?.items ?? [];

    for (const {productId} of products) {
      const product = currentItems.find((item) => item.product.id === productId)?.product;
      unavailableItemsList.push(`${product?.name} is no longer available`);
    }

    for (const {productId, modifierGroupId} of modifierGroups) {
      const item = currentItems.find((item) => item.product.id === productId);
      if (item) {
        const modifierGroup = item.product.modifierGroups.find((group) => group.id === modifierGroupId);
        for (const modifier of modifierGroup?.modifiers ?? []) {
          const modifierGroups = item.modifierGroups.flatMap((group) => group.modifiers).map((m) => m.modifierId);
          if (modifierGroups.includes(modifier.id)) {
            unavailableItemsList.push(`${modifier?.name} is no longer an option for ${item.product.name}`);
          }
        }
      }
    }

    for (const {productId, modifierGroupId, modifierId} of modifiers) {
      const product = currentItems.find((item) => item.product.id === productId)?.product;
      const modifierGroup = product?.modifierGroups.find((group) => group.id === modifierGroupId);
      const modifier = modifierGroup?.modifiers.find((modifier) => modifier.id === modifierId);
      unavailableItemsList.push(`${modifier?.name} is no longer an option for ${product?.name}`);
    }

    return [...new Set(unavailableItemsList)];
  };

  const removeUnavailableItemsFromCart = () => {
    if (!!!unavailableItems) {
      return;
    }
    const currentItems = cart?.items ?? [];
    for (const {product, modifierGroups} of currentItems) {
      if (unavailableItems!.products.find((p) => p.productId === product.id)) {
        cartReducer.removeProduct(product.id);
      } else {
        for (const {modifierGroupId, modifiers} of modifierGroups) {
          if (unavailableItems!.modifierGroups.find((mg) => mg.modifierGroupId === modifierGroupId)) {
            cartReducer.removeModifierGroup(product.id, modifierGroupId);
          } else {
            for (const {modifierId} of modifiers) {
              if (unavailableItems!.modifiers.find((m) => m.modifierId === modifierId)) {
                cartReducer.removeModifier(product.id, modifierGroupId, modifierId);
              }
            }
          }
        }
      }
    }
  };

  const onClickProduct = (productId: number, menuCategoryProductId: number) => {
    setSelectedProduct({productId, menuCategoryProductId});
    setIsProductDialogOpen(true);
  };

  const renderOrderSummary = () => {
    if (!cart) {
      return null;
    }

    return (
      <section>
        <ActiveDiscountNotification
          discounts={availableDiscounts}
          onFinished={() => {
            queryClient.invalidateQueries(['order-summary']);
            cartReducer.removeDiscounts();
          }}
        />

        {/* Header */}
        <header className="tw-mb-4 tw-flex tw-items-center tw-justify-between">
          <h2 className="tw-truncate tw-text-lg tw-font-bold tw-text-slate-900">Your order</h2>
          {!isCreatingOrder && (
            <Link to={menuHref} replace className="tw-underline-offset-4 hover:tw-underline tw-text-blue-800 tw-text-sm">
              Add more items
            </Link>
          )}
        </header>

        {/* Divider */}
        <div className="tw-mt-4 tw-h-px tw-bg-slate-200" />

        {/* Order items */}
        <div className="tw-space-y-4 tw-text-slate-800">
          <Collapsible
            className="tw-overflow-hidden tw-text-sm"
            open={isOpenOrderItemsCollapse}
            onOpenChange={(value) => {
              setIsOpenOrderItemsCollapse(value);
            }}
          >
            <CollapsibleTrigger
              asChild
              className="[&[data-state=open]>svg]:tw-rotate-90 tw-w-full tw-pt-4 tw-font-medium tw-cursor-pointer"
            >
              <div className="tw-flex tw-flex-1 tw-items-center tw-justify-between ">
                <div className="tw-flex tw-items-start tw-text-sm tw-font-bold">
                  <ShoppingCart className="tw-mr-2 tw-h-4" />
                  Order Items ({cart.itemsQuantity})
                </div>
                <ChevronRight className="tw-h-4 tw-w-4 tw-transition-transform tw-duration-200 " />
              </div>
            </CollapsibleTrigger>
            <CollapsibleContent className="tw-px-2">
              {productItems &&
                productItems.map((item, index) => (
                  <OrderItem
                    key={`${index}-${item.product.id}`}
                    menuCategoryProductId={item.menuCategoryProductId}
                    product={item.product}
                    itemQuantity={item.quantity}
                    itemModifiers={getOrderItemModifiers(item.modifierGroups, item.product)}
                    itemIndex={index}
                  />
                ))}
            </CollapsibleContent>
          </Collapsible>
        </div>

        {/* Divider */}
        <div className="tw-my-4 tw-h-px tw-bg-slate-200" />

        {/* Totals */}
        <dl className="tw-space-y-4 tw-text-slate-800">
          {isFetchingOrderSummary ? (
            [1, 2].map((i) => (
              <div key={i} className="tw-flex tw-items-center tw-justify-between tw-text-sm">
                <dt className="tw-bg-gray-200 tw-animate-pulse tw-w-24 tw-h-4 tw-rounded" />
                <dd className="tw-bg-gray-200 tw-animate-pulse tw-w-8 tw-h-4 tw-rounded" />
              </div>
            ))
          ) : (
            <>
              <div className="tw-flex tw-items-center tw-justify-between tw-text-sm">
                <dt className="tw-truncate tw-pr-2">Subtotal</dt>
                <dd className="tw-font-bold tw-text-gray-900">{getPriceText(totals.productsSubtotal)}</dd>
              </div>

              <div className="tw-flex tw-items-center tw-justify-between tw-text-sm">
                <dt className="tw-flex tw-items-center tw-gap-1 tw-overflow-hidden tw-pr-2">
                  <span className="tw-truncate">Taxes and fees</span>
                  <Popover>
                    <PopoverTrigger>
                      <InformationCircleIcon className="tw-h-4 tw-w-4 tw-cursor-pointer tw-text-slate-400" />
                    </PopoverTrigger>
                    <PopoverContent className="tw-w-60 tw-p-3">
                      <dl className="tw-space-y-3 tw-text-sm">
                        <div className="tw-flex tw-items-center tw-justify-between">
                          <dt className="tw-flex tw-items-center tw-gap-1">Taxes</dt>
                          <dd className="tw-font-bold tw-text-gray-900">{getPriceText(totals.tax)}</dd>
                        </div>
                        <div className="tw-flex tw-items-center tw-justify-between">
                          <dt className="tw-flex tw-items-center tw-gap-1">Convenience fee</dt>
                          <dd className="tw-font-bold tw-text-gray-900">{getPriceText(totals.feesSubtotal)}</dd>
                        </div>
                      </dl>
                    </PopoverContent>
                  </Popover>
                </dt>
                <dd className="tw-font-bold tw-text-gray-900">{getPriceText(totals.tax + totals.feesSubtotal)}</dd>
              </div>

              {additionalCharges.map((surcharge, index) => (
                <div className="tw-flex tw-items-center tw-justify-between tw-text-sm" key={index}>
                  <dt className="tw-flex tw-items-center tw-gap-1 tw-overflow-hidden tw-pr-2">
                    <span className="tw-truncate">{surcharge.title}</span>
                    <Popover>
                      <PopoverTrigger>
                        <InformationCircleIcon className="tw-h-4 tw-w-4 tw-cursor-pointer tw-text-slate-400" />
                      </PopoverTrigger>
                      <PopoverContent className="tw-prose-sm" style={{minWidth: 'auto'}}>
                        This location charges an additional of {getPriceText(surcharge.subtotal)} for this service. <br /> This
                        fee is allocated to the venue and staff.
                      </PopoverContent>
                    </Popover>
                  </dt>
                  <dd className="tw-font-bold tw-text-gray-900 ">{getPriceText(surcharge.subtotal)}</dd>
                </div>
              ))}

              {appliedDiscounts.map((discount, index) => (
                <div key={index} className="tw-flex tw-items-center tw-justify-between tw-text-sm">
                  <dt>
                    Discount
                    <span className="tw-ml-2 tw-rounded-full tw-bg-gray-100 tw-py-0.5 tw-px-2 tw-text-xs tw-tracking-wide tw-text-gray-600">
                      {discount.title}
                    </span>
                  </dt>
                  <dd className="tw-font-bold tw-text-gray-900">{getPriceText(discount.subtotal)}</dd>
                </div>
              ))}
            </>
          )}

          {/* Divider */}
          <div className="tw-h-px tw-bg-slate-200" />

          {/* Coupon input */}
          {canEnterCouponCode && (
            <>
              <CouponCodeForm onSubmit={({couponCode}) => cartReducer.setCouponCode(couponCode)} />

              {/* Divider */}
              <div className="tw-h-px tw-bg-slate-200" />
            </>
          )}

          {/* Tips */}
          {areTipsEnabled && (
            <>
              <div>
                <div className="tw-flex tw-items-center tw-justify-between tw-text-sm">
                  <dt>{tipOptions.length ? 'Add a tip' : 'Tip'}</dt>
                  {isFetchingOrderSummary ? (
                    <dd className="tw-bg-gray-200 tw-animate-pulse tw-w-8 tw-h-4 tw-rounded" />
                  ) : (
                    <dd className="tw-font-bold tw-text-gray-900">{getPriceText(totals.tipsSubtotal)}</dd>
                  )}
                </div>

                {Boolean(tipOptions.length) && (
                  <TipSelector
                    disabled={isCreatingOrder}
                    options={tipOptions}
                    onChange={cartReducer.setSelectedTip}
                    value={cart.selectedTip}
                    orderAmount={totals.productsSubtotal}
                  />
                )}
              </div>
              {/* Divider */}
              <div className="tw-h-px tw-bg-slate-200" />
            </>
          )}

          {/* Upselling */}
          {Boolean(upsellingItems.length) && <Carousel products={upsellingItems} onClickProduct={onClickProduct} />}

          {/* Total */}
          {isFetchingOrderSummary ? (
            <div className="tw-flex tw-items-center tw-justify-between tw-text-sm">
              <dt className="tw-bg-gray-200 tw-animate-pulse tw-w-24 tw-h-6 tw-rounded" />
              <dd className="tw-bg-gray-200 tw-animate-pulse tw-w-8 tw-h-6 tw-rounded" />
            </div>
          ) : (
            <div className="tw-flex tw-items-center tw-justify-between">
              <dt className="tw-text-base tw-font-medium">Total</dt>
              <dd className="tw-text-base tw-font-bold tw-text-gray-900">{getPriceText(totals.total)}</dd>
            </div>
          )}
        </dl>

        <ProductDialog
          open={isProductDialogOpen}
          productId={selectedProduct?.productId}
          menuCategoryProductId={selectedProduct?.menuCategoryProductId}
          discounts={discounts}
          pricesIncludeTax={pricesIncludeTax}
          viewOnly={false}
          isUpsellingContext={true}
          onOpenChange={(open) => {
            setSelectedProduct(undefined);
            setIsProductDialogOpen(open);
          }}
        />
      </section>
    );
  };

  const renderPickupDetails = () => {
    if (!isCheckoutStepRequired('set-pickup-info')) {
      return null;
    }

    const isPickupinfoComplete = isCheckoutStepComplete('set-pickup-info');

    if (isMobile && !isPickupinfoComplete) {
      return null;
    }

    const {pickupName, pickupInfo} = cart!;

    return isMobile ? (
      <section className="tw-flex tw-items-center tw-justify-between tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-p-4 tw-shadow-sm">
        <div className="tw-overflow-hidden tw-pr-2">
          <h3 className="tw-truncate tw-text-sm tw-font-bold tw-leading-6 tw-text-gray-900 sm:tw-text-base">Pickup Info</h3>

          <dl className="tw-mt-2 tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-4 sm:tw-grid-cols-2 md:tw-grid-cols-3 xl:tw-grid-cols-4">
            <div className="sm:tw-col-span-1">
              <dt className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-500">Name</dt>
              <dd className="tw-mt-1 tw-truncate tw-text-sm tw-text-gray-900"> {pickupName}</dd>
            </div>
            <div className="sm:tw-col-span-1">
              <dt className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-500">Additional info</dt>
              <dd className="tw-mt-1 tw-truncate tw-text-sm tw-text-gray-900">{pickupInfo}</dd>
            </div>
          </dl>
        </div>
        <div>
          <Button variant="subtle" onClick={() => setOverrideCurrentStep('set-pickup-info')}>
            Edit
          </Button>
        </div>
      </section>
    ) : (
      <Collapsible
        open={!isMobile && isPickupDetailsStepActive}
        className={cn(
          "tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-p-4 tw-shadow-sm tw-transition-shadow data-[state='open']:tw-shadow-lg",
          !isPickupDetailsStepActive && !isPickupinfoComplete && 'tw-shadow-none tw-opacity-60'
        )}
      >
        <div className="tw-flex tw-select-none tw-items-center tw-justify-between tw-overflow-hidden tw-pr-2">
          <div>
            <h3 className="tw-truncate tw-text-sm tw-font-bold tw-leading-6 tw-text-gray-900 sm:tw-text-base">Pickup Info</h3>
            {isPickupinfoComplete && !isPickupDetailsStepActive ? (
              <dl className="tw-mt-2 tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-4 sm:tw-grid-cols-2 md:tw-grid-cols-3 xl:tw-grid-cols-4">
                <div className="sm:tw-col-span-1">
                  <dt className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-500">Name</dt>
                  <dd className="tw-mt-1 tw-truncate tw-text-sm tw-text-gray-900"> {pickupName}</dd>
                </div>
                <div className="sm:tw-col-span-1">
                  <dt className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-500">Additional info</dt>
                  <dd className="tw-mt-1 tw-truncate tw-text-sm tw-text-gray-900">{pickupInfo}</dd>
                </div>
              </dl>
            ) : (
              <div className="tw-text-sm tw-text-gray-500">Complete the pickup details for your order</div>
            )}
          </div>

          {!isPickupDetailsStepActive && isPickupinfoComplete && (
            <CollapsibleTrigger asChild>
              <Button variant="subtle" onClick={() => setOverrideCurrentStep('set-pickup-info')}>
                Edit
              </Button>
            </CollapsibleTrigger>
          )}
        </div>
        <CollapsibleContent className="tw-mt-3 tw-animate-in data-[state=open]:tw-fade-in-50 data-[state=open]:tw-slide-in-from-top-2 tw-duration-200 tw-ease-in-out">
          <PickupDetailsForm id="pickup-details-form" onSubmitted={() => setOverrideCurrentStep(null)} />
          <div className="tw-px-2 tw-flex tw-items-center tw-gap-2 tw-border-t tw-border-gray-200 tw-pt-4">
            <Button type="submit" form="pickup-details-form">
              Confirm
            </Button>
          </div>
        </CollapsibleContent>
      </Collapsible>
    );
  };

  const renderPaymentMethod = () => {
    if (!isCheckoutStepRequired('select-payment-method')) {
      return null;
    }

    if (isMobile && !isPaymentInfoComplete) {
      return null;
    }

    return isMobile ? (
      // Mobile version
      <section className="tw-flex tw-items-center tw-justify-between tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-p-4 tw-shadow-sm">
        <div className="tw-overflow-hidden tw-pr-2">
          <h3 className="tw-mb-2 tw-truncate tw-text-sm tw-font-bold tw-leading-6 tw-text-gray-900 sm:tw-text-base">Payment</h3>
          {!!cart?.payment && (
            <PaymentMethodItem cardBrand={cart.payment.cardBrand ?? ''} lastFourDigits={cart.payment.cardDigits} />
          )}

          {cart?.roomGuestLastName && cart.roomNumber && (
            <dl className="tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-4 sm:tw-grid-cols-2 md:tw-grid-cols-3 xl:tw-grid-cols-4">
              <div className="sm:tw-col-span-1">
                <dt className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-500">Room number</dt>
                <dd className="tw-mt-1 tw-truncate tw-text-sm tw-text-gray-900"> {cart.roomNumber}</dd>
              </div>
              <div className="sm:tw-col-span-1">
                <dt className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-500">Last name</dt>
                <dd className="tw-mt-1 tw-truncate tw-text-sm tw-text-gray-900">{cart.roomGuestLastName}</dd>
              </div>
            </dl>
          )}
        </div>
        <div>
          <Button variant="subtle" onClick={() => setOverrideCurrentStep('select-payment-method')} disabled={isCreatingOrder}>
            Edit
          </Button>
        </div>
      </section>
    ) : (
      // Desktop version
      <Collapsible
        open={!isMobile && isSelectPaymentMethodStepActive}
        className={cn(
          "tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-p-4 tw-shadow-sm tw-transition-shadow data-[state='open']:tw-shadow-lg",
          !isSelectPaymentMethodStepActive && !isPaymentInfoComplete && 'tw-shadow-none tw-opacity-60'
        )}
      >
        <div className="tw-flex tw-select-none tw-items-center tw-justify-between tw-overflow-hidden tw-pr-2">
          <div>
            <h3 className="tw-truncate tw-text-sm tw-font-bold tw-leading-6 tw-text-gray-900 sm:tw-text-base">Payment</h3>
            {isPaymentInfoComplete && !isSelectPaymentMethodStepActive ? (
              (!!cart?.payment && (
                <div className="tw-mt-1">
                  <PaymentMethodItem cardBrand={cart.payment.cardBrand ?? ''} lastFourDigits={cart.payment.cardDigits} />
                </div>
              )) ||
              (cart?.roomGuestLastName && cart.roomNumber && (
                <dl className="tw-mt-1 tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-4 sm:tw-grid-cols-2 md:tw-grid-cols-3 xl:tw-grid-cols-4">
                  <div className="sm:tw-col-span-1">
                    <dt className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-500">Room number</dt>
                    <dd className="tw-mt-1 tw-truncate tw-text-sm tw-text-gray-900"> {cart.roomNumber}</dd>
                  </div>
                  <div className="sm:tw-col-span-1">
                    <dt className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-500">Last name</dt>
                    <dd className="tw-mt-1 tw-truncate tw-text-sm tw-text-gray-900">{cart.roomGuestLastName}</dd>
                  </div>
                </dl>
              ))
            ) : (
              <div className="tw-text-sm tw-text-gray-500">Choose or add a payment method</div>
            )}
          </div>

          {!isSelectPaymentMethodStepActive && isPaymentInfoComplete && (
            <CollapsibleTrigger asChild>
              <Button variant="subtle" onClick={() => setOverrideCurrentStep('select-payment-method')} disabled={isCreatingOrder}>
                Edit
              </Button>
            </CollapsibleTrigger>
          )}
        </div>
        <CollapsibleContent className="tw-animate-in data-[state=open]:tw-fade-in-50 data-[state=open]:tw-slide-in-from-top-2 tw-duration-200 tw-ease-in-out">
          <div className="tw-mt-2">
            <SelectPaymentMethodTabs
              activeTab={selectPaymentMethodTabs.activeTab}
              setActiveTab={selectPaymentMethodTabs.setActiveTab}
              roomChargeFormController={selectPaymentMethodTabs.roomChargeFormController}
              selectPaymentMethodFormController={selectPaymentMethodTabs.selectPaymentMethodFormController}
              paymentMethods={paymentMethods}
              allowsRoomPayment={allowsRoomPayment}
              paymentGateway={paymentGateway}
            />
          </div>
          {/* Divider */}
          <div className="tw-my-4 tw-h-px tw-bg-slate-200" />
          <div className="tw-px-2">
            {selectPaymentMethodTabs.activeTab === 'card' && (
              <div className="tw-flex tw-gap-2">
                <div className="tw-flex tw-gap-2">
                  <Button form={selectPaymentMethodTabs.selectPaymentMethodFormController.formId} type="submit">
                    Confirm
                  </Button>
                </div>
                <Button onClick={() => setIsCreatePaymentMethodDialogOpen(true)} variant="ghost">
                  Add new payment method
                </Button>
              </div>
            )}

            {selectPaymentMethodTabs.activeTab === 'room-charge' && (
              <Button form={selectPaymentMethodTabs.roomChargeFormController.formId}>Confirm</Button>
            )}
          </div>
        </CollapsibleContent>
      </Collapsible>
    );
  };

  const renderLocationDetails = () => {
    if (!isCheckoutStepRequired('set-location-info')) {
      return null;
    }

    const isLocationAttributesComplete = isCheckoutStepComplete('set-location-info');

    return isMobile ? (
      <section className="tw-flex tw-items-center tw-justify-between tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-p-4 tw-shadow-sm tw-mb-4">
        <div className="tw-overflow-hidden tw-pr-2">
          <h3 className="tw-truncate tw-text-sm tw-font-bold tw-leading-6 tw-text-gray-900 sm:tw-text-base">Location details</h3>

          <dl className="tw-mt-2 tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-4 sm:tw-grid-cols-2 md:tw-grid-cols-3 xl:tw-grid-cols-4">
            <div className="sm:tw-col-span-1">
              <dt className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-500">Name</dt>
              <dd className="tw-mt-1 tw-truncate tw-text-sm tw-text-gray-900">{getCustomerFullName(customer)}</dd>
            </div>
            <div className="sm:tw-col-span-1">
              <dt className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-500">Location</dt>
              <dd className="tw-mt-1 tw-truncate tw-text-sm tw-text-gray-900"> {getLocationName(cart?.locationDetails)}</dd>
            </div>
          </dl>
        </div>
        <div>
          <Button variant="subtle" onClick={() => setOverrideCurrentStep('set-location-info')}>
            Edit
          </Button>
        </div>
      </section>
    ) : (
      <Collapsible
        open={!isMobile && isLocationAttributesStepActive}
        className={cn(
          "tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-p-4 tw-shadow-sm tw-transition-shadow data-[state='open']:tw-shadow-lg",
          !isLocationAttributesStepActive && !isLocationAttributesComplete && 'tw-shadow-none tw-opacity-60'
        )}
      >
        <div className="tw-flex tw-select-none tw-items-center tw-justify-between tw-overflow-hidden tw-pr-2">
          <div>
            <h3 className="tw-truncate tw-text-sm tw-font-bold tw-leading-6 tw-text-gray-900 sm:tw-text-base">
              Location details
            </h3>
            {isLocationAttributesComplete && !isLocationAttributesStepActive ? (
              <dl className="tw-mt-2 tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-4 sm:tw-grid-cols-2 md:tw-grid-cols-3 xl:tw-grid-cols-4">
                <div className="sm:tw-col-span-1">
                  <dt className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-500">Name</dt>
                  <dd className="tw-mt-1 tw-truncate tw-text-sm tw-text-gray-900">{getCustomerFullName(customer)}</dd>
                </div>
                <div className="sm:tw-col-span-1">
                  <dt className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-500">Location</dt>
                  <dd className="tw-mt-1 tw-truncate tw-text-sm tw-text-gray-900"> {getLocationName(cart?.locationDetails)}</dd>
                </div>
              </dl>
            ) : (
              <div className="tw-text-sm tw-text-gray-500">Complete the location details for your order</div>
            )}
          </div>

          {!isLocationAttributesStepActive && isLocationAttributesComplete && (
            <CollapsibleTrigger asChild>
              <Button variant="subtle" onClick={() => setOverrideCurrentStep('set-location-info')}>
                Edit
              </Button>
            </CollapsibleTrigger>
          )}
        </div>
        {locationType && (
          <CollapsibleContent className="tw-mt-3 tw-animate-in data-[state=open]:tw-fade-in-50 data-[state=open]:tw-slide-in-from-top-2 tw-duration-200 tw-ease-in-out">
            <LocationDetailsForm
              id="location-details-form"
              onSubmitted={() => setOverrideCurrentStep(null)}
              locationType={locationType?.name}
              locationAttributes={locationType?.attributes}
              prefixInputLabels
            />
            <div className="tw-px-2 tw-flex tw-items-center tw-gap-2 tw-border-t tw-border-gray-200 tw-pt-4">
              <Button type="submit" form="location-details-form">
                Confirm
              </Button>
            </div>
          </CollapsibleContent>
        )}
      </Collapsible>
    );
  };

  const renderOrderAttributes = () => {
    if (!isCheckoutStepRequired('set-order-custom-attributes') || !orderAttributeGroup) {
      return null;
    }

    const areOrderAttributesComplete = isCheckoutStepComplete('set-order-custom-attributes');

    if (isMobile && !areOrderAttributesComplete) {
      return null;
    }

    const attributes = cart?.orderGroupsWithAttributes?.[0]?.attributes ?? [];

    return isMobile ? (
      <section className="tw-flex tw-items-center tw-justify-between tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-p-4 tw-shadow-sm">
        <div className="tw-w-full tw-overflow-hidden tw-pr-2">
          <h3 className="tw-truncate tw-text-sm tw-font-bold tw-leading-6 tw-text-gray-900 sm:tw-text-base">
            {orderAttributeGroup.name}
          </h3>

          {areOrderAttributesComplete && !isOrderAttributesStepActive ? (
            <dl className="tw-mt-2 tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-4 sm:tw-grid-cols-2 md:tw-grid-cols-3 xl:tw-grid-cols-4">
              <div className="sm:tw-col-span-1">
                <dt className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-500">Your name</dt>
                <dd className="tw-mt-1 tw-truncate tw-text-sm tw-text-gray-900">{getCustomerFullName(customer)}</dd>
              </div>
              {attributes.map(({name, orderAttributeId, value}) => (
                <div className="sm:tw-col-span-1" key={orderAttributeId}>
                  <dt className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-500">{name}</dt>
                  <dd className="tw-mt-1 tw-truncate tw-text-sm tw-text-gray-900">{value}</dd>
                </div>
              ))}
            </dl>
          ) : (
            <p className="tw-text-sm tw-text-gray-500">{orderAttributeGroup.prompt}</p>
          )}
        </div>
        <div>
          <Button variant="subtle" onClick={() => setOverrideCurrentStep('set-order-custom-attributes')}>
            Edit
          </Button>
        </div>
      </section>
    ) : (
      // Desktop version
      <Collapsible
        open={!isMobile && isOrderAttributesStepActive}
        className={cn(
          "tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-p-4 tw-shadow-sm tw-transition-shadow data-[state='open']:tw-shadow-lg",
          !isOrderAttributesStepActive && !areOrderAttributesComplete && 'tw-shadow-none tw-opacity-60'
        )}
      >
        <div className="tw-flex tw-select-none tw-items-center tw-justify-between tw-overflow-hidden tw-pr-2 ">
          <div>
            <h3 className="tw-truncate tw-text-sm tw-font-bold tw-leading-6 tw-text-gray-900 sm:tw-text-base">
              {orderAttributeGroup.name}
            </h3>
            {areOrderAttributesComplete && !isOrderAttributesStepActive ? (
              <dl className="tw-mt-2 tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-4 sm:tw-grid-cols-2 md:tw-grid-cols-3 xl:tw-grid-cols-4">
                <div className="sm:tw-col-span-1">
                  <dt className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-500">Your name</dt>
                  <dd className="tw-mt-1 tw-truncate tw-text-sm tw-text-gray-900">{getCustomerFullName(customer)}</dd>
                </div>
                {attributes.map(({name, orderAttributeId, value}) => (
                  <div className="sm:tw-col-span-1" key={orderAttributeId}>
                    <dt className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-500">{name}</dt>
                    <dd className="tw-mt-1 tw-truncate tw-text-sm tw-text-gray-900">{value}</dd>
                  </div>
                ))}
              </dl>
            ) : (
              <p className="tw-text-sm tw-text-gray-500">{orderAttributeGroup.prompt}</p>
            )}
          </div>

          {!isOrderAttributesStepActive && areOrderAttributesComplete && (
            <CollapsibleTrigger asChild>
              <Button variant="subtle" onClick={() => setOverrideCurrentStep('set-order-custom-attributes')}>
                Edit
              </Button>
            </CollapsibleTrigger>
          )}
        </div>

        <CollapsibleContent className="tw-animate-in data-[state=open]:tw-fade-in-50 data-[state=open]:tw-slide-in-from-top-2 tw-duration-200 tw-ease-in-out">
          <div className="tw-mt-2">
            <OrderAttributesForm
              id="order-custom-attributes-form"
              onSubmitted={() => setOverrideCurrentStep(null)}
              orderAttributeGroup={orderAttributeGroup}
            />
          </div>
          <div className="tw-px-2 tw-flex tw-items-center tw-gap-2 tw-border-t tw-border-gray-200 tw-pt-4">
            <Button disabled={isUpdatingCustomer} type="submit" form="order-custom-attributes-form">
              Confirm
            </Button>
          </div>
        </CollapsibleContent>
      </Collapsible>
    );
  };

  const renderScheduleOrder = () => {
    if (!areSchedulableOrdersAllowed) {
      return null;
    }

    const formatDateOptionLabel = (date: DateTime) => `
      ${capitalize(date.toRelativeCalendar()!)},
      ${date.toFormat('EEE. MMM dd hh:mm a')}
    `;

    const getRadioGroupValue = (scheduleDate?: string) => {
      if (!isOrderingAsapAllowed || (!!scheduleDate && scheduleDate !== 'asap')) {
        return 'scheduled';
      }

      if (!scheduleDate || scheduleDate === 'asap') {
        return 'asap';
      }
    };

    return (
      <section className="tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-shadow-sm tw-pt-4">
        <header className="tw-overflow-hidden tw-px-4 tw-mb-2">
          <h3 className="tw-truncate tw-text-base tw-font-bold tw-leading-6 tw-text-gray-900 sm:tw-text-base">
            When should your order be ready?
          </h3>
        </header>
        <div className="tw-overflow-hidden">
          <RadioGroup
            disabled={isCreatingOrder}
            value={getRadioGroupValue(cart?.scheduleDate)}
            onValueChange={(value) => {
              if (value === 'asap') {
                cartReducer.setScheduleOrderDetails('asap');
              }
              if (value === 'scheduled') {
                // show schedule order dialog

                setIsScheduleOrderDialogOpen(true);
              }
            }}
            className="tw-divide-y tw-divide-gray-200"
          >
            {isOrderingAsapAllowed && (
              <label className="tw-flex tw-cursor-pointer tw-items-center tw-gap-4 tw-px-4 tw-py-3 hover:tw-bg-gray-50">
                <RadioGroupItemWithIndicator value="asap" />
                <div className="tw-truncate tw-text-sm">
                  <p className="tw-truncate tw-font-medium tw-text-gray-900">
                    {/* <ZapIcon className="tw-h-5 tw-w-5 tw-mr-4 tw-text-gray-400" /> */}
                    As soon as possible
                  </p>
                  <div className="tw-flex tw-items-center tw-text-gray-500">
                    <p>20 - 30 min</p>
                  </div>
                </div>
              </label>
            )}

            <label className="tw-flex tw-cursor-pointer tw-items-center tw-px-4 tw-py-3 hover:tw-bg-gray-50">
              <div className="tw-flex tw-items-center tw-gap-4 tw-overflow-hidden">
                <RadioGroupItemWithIndicator value="scheduled" />
                <div className="tw-overflow-hidden tw-text-sm">
                  <p className="tw-truncate tw-font-medium tw-text-gray-900">
                    {/* <ZapIcon className="tw-h-5 tw-w-5 tw-mr-4 tw-text-gray-400" /> */}
                    Schedule
                  </p>
                  <p className="tw-truncate tw-text-gray-500">
                    {cart?.scheduleDate && cart?.scheduleDate !== 'asap'
                      ? formatDateOptionLabel(DateTime.fromISO(cart?.scheduleDate))
                      : 'Select a time'}
                  </p>
                </div>
              </div>
              {((!!cart?.scheduleDate && cart?.scheduleDate !== 'asap') || !isOrderingAsapAllowed) && (
                <Button variant="subtle" className="tw-ml-auto" onClick={() => setIsScheduleOrderDialogOpen(true)}>
                  Edit
                </Button>
              )}
            </label>
          </RadioGroup>
        </div>
      </section>
    );
  };

  const onWalletPayentButtonSuccess = (walletPaymentIntentData: WalletPaymentIntentData, cardholderName?: string) => {
    // cartReducer.setPayment({
    //   ...cart?.payment!,
    //   cardName: cardholderName!,
    //   walletPaymentIntentData,
    // });
    createOrder({
      ...orderCreateRequest,
      payment: {
        ...cart?.payment!,
        cardName: cardholderName!,
        walletPaymentIntentData,
      },
      roomNumber: undefined,
      roomGuestLastName: undefined,
    });
  };

  const renderPaymentButton = () => {
    // TODO: Move both Stripe and Freedompay wallet payment buttons into a component that decides which one to render
    if (cart?.payment?.isWallet) {
      if (isFetchingOrderSummary) {
        return null;
      }

      if (paymentGateway === 'stripe') {
        return (
          <div className="wallet-button">
            <StripeProvider>
              <StripeWalletPaymentButton
                amount={totals.total}
                currency={currency}
                country={country}
                onPaymentFailed={() => alert('order failed')}
                onPaymentSuccess={onWalletPayentButtonSuccess}
                fee={totals.feesSubtotal}
              />
            </StripeProvider>
          </div>
        );
      }

      if (paymentGateway === 'freedompay') {
        return (
          <div className="freedompay-wallet-button">
            <FreedompayWalletPaymentButton
              onPaymentFailed={() => alert('order failed')}
              onPaymentSuccess={onWalletPayentButtonSuccess}
              orderItems={orderItems}
              orderTotals={totals}
            />
          </div>
        );
      }
    }

    return isMobile ? (
      <div className="tw-bg-white tw-pb-6">
        <Button
          size="xl"
          variant="accent"
          onClick={() => (isReadyToPlaceOrder ? createOrder(orderCreateRequest) : setOverrideCurrentStep(null))}
          disabled={isCreatingOrder || isFetchingOrderSummary}
          className="tw-w-full tw-text-lg !tw-font-bold"
        >
          {isReadyToPlaceOrder ? 'Place Order' : 'Continue'}
          {isCreatingOrder && <Loader2 className="tw-ml-2 tw-w-4 tw-h-4 tw-animate-spin tw-text-white" />}
        </Button>
      </div>
    ) : (
      <div className="tw-bg-white tw-pb-6">
        <Button
          size="xl"
          variant="accent"
          onClick={() => createOrder(orderCreateRequest)}
          disabled={!isReadyToPlaceOrder || isCreatingOrder || isFetchingOrderSummary}
          className="tw-w-full tw-text-lg !tw-font-bold"
        >
          Place Order
          {isCreatingOrder && <Loader2 className="tw-ml-2 tw-w-4 tw-h-4 tw-animate-spin tw-text-white" />}
        </Button>
      </div>
    );
  };

  const renderAgeVerification = () => {
    if (isMobile || !isCheckoutStepRequired('verify-age')) {
      return null;
    }

    const isAgeVerificationComplete = isCheckoutStepComplete('verify-age');

    return (
      <Collapsible
        open={!isMobile && isAgeVerificationStepActive}
        className={cn(
          "tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-p-4 tw-shadow-sm tw-transition-shadow data-[state='open']:tw-shadow-lg",
          !isAgeVerificationStepActive && !isAgeVerificationComplete && 'tw-shadow-none tw-opacity-60'
        )}
      >
        <div className="tw-flex tw-select-none tw-items-center tw-justify-between tw-overflow-hidden tw-pr-2">
          <div>
            <h3 className="tw-truncate tw-text-sm tw-font-bold tw-leading-6 tw-text-gray-900 sm:tw-text-base">
              Are you 21 or older?
            </h3>
            {isAgeVerificationComplete && !isAgeVerificationStepActive ? (
              <p>Yes</p>
            ) : (
              <p className="tw-text-sm tw-text-gray-500">
                Some items in your cart require you to be at least 21 years old to place your order.
              </p>
            )}
          </div>
        </div>
        <CollapsibleContent className="tw-mt-3 tw-animate-in data-[state=open]:tw-fade-in-50 data-[state=open]:tw-slide-in-from-top-2 tw-duration-200 tw-ease-in-out">
          <Button
            onClick={() => {
              cartReducer.setIsAgeVerified(true);
              setOverrideCurrentStep(null);
            }}
          >
            Confirm
          </Button>
        </CollapsibleContent>
      </Collapsible>
    );
  };

  const renderTermAndConditions = () => {
    if (!termsAndConditionsMessage || !isReadyToPlaceOrder) {
      return null;
    }

    return <section className="terms-and-conditions" dangerouslySetInnerHTML={{__html: termsAndConditionsMessage || ''}} />;
  };

  const renderSignIn = () => {
    if (isCheckoutStepComplete('sign-in')) {
      return (
        <div className="tw-flex tw-select-none tw-items-center tw-p-4 tw-space-x-2 tw-font-bold tw-bg-white tw-border tw-rounded-md tw-pr-2 tw-text-sm sm:tw-text-base tw-shadow-sm">
          <p>Signed in</p>
          <CheckCircleIcon className="tw-flex tw-h-6 tw-w-6 tw-text-teal-500" />
          <p>{formatPhoneNumberIntl(customer?.phone!)}</p>
        </div>
      );
    }

    return (
      <Collapsible
        open={!isMobile && isSignInStepActive}
        className={cn(
          "tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-p-4 tw-shadow-sm tw-transition-shadow data-[state='open']:tw-shadow-lg",
          !isSignInStepActive && 'tw-shadow-none tw-opacity-60'
        )}
      >
        <div className="tw-flex tw-select-none tw-items-center tw-justify-between tw-overflow-hidden tw-pr-2">
          <div>
            <h3 className="tw-truncate tw-text-sm tw-font-bold tw-leading-6 tw-text-gray-900 sm:tw-text-base">
              Sign in to receive order updates
            </h3>

            <p className="tw-text-sm tw-text-gray-500">We will send you a SMS with a verification code.</p>
          </div>
        </div>
        <CollapsibleContent className="tw-mt-3 tw-animate-in data-[state=open]:tw-fade-in-50 data-[state=open]:tw-slide-in-from-top-2 tw-duration-200 tw-ease-in-out">
          {signInState.activeStep === 'send-verification-code' && (
            <>
              <SendVerificationCodeForm
                id="send-verification-code-form"
                defaultValues={{phone: signInState.numberBeingVerified}}
                onSubmitted={({phone}) => {
                  signInState.setNumberBeingVerified(phone);
                  signInState.setActiveSteop('verify-code');
                }}
              />

              <div className="tw-px-2 tw-flex tw-items-center tw-gap-2 tw-border-t tw-border-gray-200 tw-pt-4">
                <Button disabled={signInState.isSendingVerificationCode} type="submit" form="send-verification-code-form">
                  Send verification code
                  {signInState.isSendingVerificationCode && <Loader2 className="tw-ml-2 tw-h-4 tw-w-4 tw-animate-spin" />}
                </Button>
              </div>
            </>
          )}

          {/* Verify code */}
          {signInState.activeStep === 'verify-code' && (
            <>
              <div className="">
                <p className="tw-mb-2 tw-text-sm">
                  <span>We have sent the code to</span>
                  <button
                    onClick={() => signInState.setActiveSteop('send-verification-code')}
                    className="tw-ml-2 tw-inline-flex tw-gap-1 tw-rounded tw-bg-blue-100 tw-px-2 tw-py-0.5 tw-text-xs tw-font-medium tw-text-blue-800"
                  >
                    <span>{formatPhoneNumberIntl(signInState.numberBeingVerified)}</span> <PencilIcon className="tw-h-3 tw-w-3" />
                  </button>
                </p>

                <VerifyCodeForm
                  id="verify-code-form"
                  defaultValues={{phone: signInState.numberBeingVerified}}
                  onSubmitted={() => {
                    console.log('code verified');
                  }}
                  className="tw-mb-4"
                />
              </div>

              <div className="tw-hidden">
                <SendVerificationCodeForm
                  id="resend-verification-code-form"
                  defaultValues={{phone: signInState.numberBeingVerified}}
                />
              </div>

              <div className="tw-px-2 tw-flex tw-items-center tw-gap-2 tw-border-t tw-border-gray-200 tw-pt-4">
                <Button disabled={signInState.isVerifyingCode} form="verify-code-form">
                  Verify code
                  {signInState.isVerifyingCode && <Loader2 className="tw-ml-2 tw-h-4 tw-w-4 tw-animate-spin" />}
                </Button>
                <Button disabled={signInState.isSendingVerificationCode} variant="ghost" form="resend-verification-code-form">
                  Resend code
                  {signInState.isSendingVerificationCode && <Loader2 className="tw-ml-2 tw-h-4 tw-w-4 tw-animate-spin" />}
                </Button>
              </div>
            </>
          )}
        </CollapsibleContent>
      </Collapsible>
    );
  };

  return (
    <>
      <main className="tw-flex tw-flex-1 tw-flex-col tw-items-stretch tw-overflow-y-auto md:tw-flex-row md:tw-overflow-hidden">
        <div className="tw-order-last tw-scroll-pt-12 md:tw-order-1 md:tw-flex-1 md:tw-overflow-y-auto tw-mb-5">
          <div className="tw-mx-auto tw-my-6 tw-flex tw-min-w-0 tw-max-w-2xl tw-flex-1 tw-flex-col tw-space-y-4 tw-px-4 tw-pb-12 md:tw-space-y-6">
            {renderSignIn()}
            {renderAgeVerification()}
            {renderScheduleOrder()}
            {renderPickupDetails()}
            {renderLocationDetails()}
            {renderOrderAttributes()}
            {renderPaymentMethod()}
            {renderTermAndConditions()}
          </div>
        </div>
        <section className="tw-border-b tw-border-solid tw-border-gray-200 tw-bg-white tw-p-6 md:tw-relative md:tw-order-2 md:tw-w-[450px] md:tw-overflow-y-auto md:tw-border-l">
          <div className="md:tw-pb-6">{renderOrderSummary()}</div>
          <div className="tw-absolute tw-inset-x-0 tw-bottom-0 tw-mx-4 tw-rounded-full tw-bg-white tw-shadow-lg tw-shadow-white md:tw-sticky md:tw-m-0">
            {renderPaymentButton()}
          </div>
        </section>
      </main>
      <ErrorAlert
        isOpen={!!requestError}
        title={requestError?.title}
        message={capitalize(requestError?.message.replace(/\_/g, ' '))}
        onClose={() => setRequestError(undefined)}
      />
      <PickupDetailsDialog
        open={isMobile && isPickupDetailsStepActive}
        onOpenChange={(open) => {
          !open && setOverrideCurrentStep('idle');
        }}
        onContinue={() => {
          setOverrideCurrentStep(null);
        }}
      />
      <SignInDialog
        open={isMobile && isSignInStepActive}
        onOpenChange={(open) => !open && setOverrideCurrentStep('idle')}
        onContinue={() => setOverrideCurrentStep(null)}
      />
      <SelectPaymentMethodDialog
        open={isMobile && isSelectPaymentMethodStepActive}
        onOpenChange={(open) => !open && setOverrideCurrentStep('idle')}
        paymentMethods={paymentMethods}
        onContinue={() => setOverrideCurrentStep(null)}
      />
      {locationType && (
        <LocationDetailsDialog
          location={locationType}
          open={isMobile && isLocationAttributesStepActive}
          onOpenChange={(open) => !open && setOverrideCurrentStep('idle')}
          onContinue={() => setOverrideCurrentStep(null)}
        />
      )}
      {!!orderAttributeGroup && (
        <OrderAttributesDialog
          orderAttributeGroup={orderAttributeGroup}
          open={isMobile && isOrderAttributesStepActive}
          onOpenChange={(open) => !open && setOverrideCurrentStep('idle')}
          onContinue={() => setOverrideCurrentStep(null)}
        />
      )}
      <ScheduleOrderDialog
        open={isScheduleOrderDialogOpen}
        onOpenChange={(open) => {
          if (!open) {
            setIsScheduleOrderDialogOpen(false);
            cartReducer.setScheduleOrderDetails(cart?.scheduleDate);
          }
        }}
        onContinue={() => setIsScheduleOrderDialogOpen(false)}
      />
      <AgeVerificationDialog
        open={isMobile && isAgeVerificationStepActive}
        onOpenChange={(open) => !open && setOverrideCurrentStep('idle')}
        onContinue={() => setOverrideCurrentStep(null)}
      />
      <CreatePaymentMethodDialog
        open={isCreatePaymentMethodDialogOpen}
        onOpenChange={setIsCreatePaymentMethodDialogOpen}
        onCreated={(paymentMethod: PaymentMethod) => {
          cartReducer.setPayment({
            cardBrand: paymentMethod.cardBrand,
            cardDigits: paymentMethod.cardLastFourDigits,
            cardName: paymentMethod.cardholderName,
            stripePaymentMethodId: paymentGateway === 'stripe' ? paymentMethod.stripePaymentMethodId : undefined,
            freedompayPaymentMethodId: paymentGateway === 'freedompay' ? paymentMethod.freedompayToken : undefined,
            isWallet: false,
          });
          setIsCreatePaymentMethodDialogOpen(false);
        }}
      />
      <Alert
        isOpen={!!unavailableItems}
        showCloseButton={false}
        title="Some items were removed from your cart"
        body={
          !!unavailableItems ? (
            <>
              <br />
              <ul>
                {getUnavailableItemsMessages().map((message) => (
                  <li key={message}>{message}</li>
                ))}
              </ul>
              <Button onClick={() => removeUnavailableItemsFromCart()} className="tw-w-full tw-mt-4">
                Ok
              </Button>
            </>
          ) : undefined
        }
      />
    </>
  );
}
