import React from 'react';
// import {useQuery} from 'react-query';

import {useAppContext} from 'contexts/app.context';
// import {fetchPaymentMethods} from 'api/payment.api';

// import {StripeProvider} from 'contexts/stripe.context';
import {PaymentMethod} from 'models/payment-method.model';

// import {FreedompayWalletPaymentMethodOption} from 'components/freedompay-wallet-payment-method-option.component';
// import {StripeWalletPaymentMethodOption} from 'components/stripe-wallet-payment-method-option.component';

import {PaymentMethodItem} from 'payment-methods/components/PaymentMethodItems';
import {Controller, UseFormProps, UseFormReturn, useForm} from 'react-hook-form';

import {RadioGroup, RadioGroupItemWithIndicator} from 'common/components/RadioGroup';
import {cn} from 'common/utils';

// Select payment method form
export interface SelectPaymentMethodFormFields {
  paymentMethod: PaymentMethod;
}

export function useSelectPaymentMethodFormController(
  {onSubmitted, ...props}: UseFormProps<SelectPaymentMethodFormFields> & {onSubmitted?: () => void} = {mode: 'onBlur'}
) {
  const {cartReducer} = useAppContext();

  const formController = useForm<SelectPaymentMethodFormFields>({...props});

  const onSubmit = (pickupDetailsFormData: SelectPaymentMethodFormFields) => {
    const {paymentMethod} = pickupDetailsFormData;

    cartReducer.setRoomChargeDetails('', '');
    cartReducer.setPayment({
      cardBrand: paymentMethod.cardBrand,
      cardDigits: paymentMethod.cardLastFourDigits,
      stripePaymentMethodId: paymentMethod.stripePaymentMethodId,
      freedompayPaymentMethodId: paymentMethod.freedompayToken,
      cardName: paymentMethod.cardholderName,
      isWallet: paymentMethod.isWallet,
    });

    onSubmitted?.();
  };

  const submit = formController.handleSubmit(onSubmit);

  return {submit, formId: 'select-payment-method-form', ...formController};
}

export interface SelectPaymentMethodFormProps extends React.HTMLProps<HTMLFormElement> {
  formController: UseFormReturn<SelectPaymentMethodFormFields>;
  paymentMethods: PaymentMethod[];
}

export const SelectPaymentMethodForm = React.forwardRef<HTMLFormElement, SelectPaymentMethodFormProps>(
  ({formController: {formState, register, control}, paymentMethods, ...formProps}, ref) => (
    <form ref={ref} {...formProps}>
      <Controller
        control={control}
        rules={{required: 'Please select a payment method'}}
        name="paymentMethod"
        render={({field}) => {
          return (
            <RadioGroup
              className={cn(
                'tw-flex tw-flex-col tw-w-full tw-divide-y tw-divide-gray-200',
                !!formState.errors.paymentMethod && 'tw-border tw-border-red-500 tw-rounded'
              )}
              value={field.value?.stripePaymentMethodId || field.value?.freedompayToken}
              onValueChange={(paymentMethodId: string) => {
                field.onChange(
                  paymentMethods.find(
                    (paymentMethod) =>
                      paymentMethod.stripePaymentMethodId === paymentMethodId || paymentMethod.freedompayToken === paymentMethodId
                  )
                );
              }}
            >
              {paymentMethods.map((paymentMethod) => {
                return (
                  <label
                    className="tw-flex tw-cursor-pointer tw-items-center tw-gap-2 tw-rounded tw-px-2 tw-py-2.5 hover:tw-bg-gray-50"
                    key={paymentMethod.stripePaymentMethodId || paymentMethod.freedompayToken}
                  >
                    <RadioGroupItemWithIndicator
                      value={(paymentMethod.freedompayToken || paymentMethod.stripePaymentMethodId)!}
                    />
                    <PaymentMethodItem
                      cardBrand={paymentMethod.cardBrand ?? ''}
                      lastFourDigits={paymentMethod.cardLastFourDigits}
                    />
                    <span className="tw-sr-only">
                      {paymentMethod.cardBrand} ending in {paymentMethod.cardLastFourDigits}
                    </span>
                  </label>
                );
              })}
            </RadioGroup>
          );
        }}
      />
      {!!formState.errors.paymentMethod && (
        <p className="tw-mt-1 tw-text-sm tw-text-red-500">{formState.errors.paymentMethod?.message}</p>
      )}
    </form>
  )
);
