import * as React from 'react';

import {cn, tw} from 'common/utils';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({className, ...props}, ref) => {
  return (
    <input
      className={cn(
        tw`tw-flex tw-w-full tw-shadow-sm tw-rounded-md tw-border tw-border-gray-300 tw-bg-transparent tw-py-2 tw-px-3 tw-text-sm placeholder:tw-text-slate-400 focus:tw-outline-none focus:tw-ring-blue-600 disabled:tw-cursor-not-allowed disabled:tw-opacity-50`,
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = 'Input';

export {Input};
