import React from 'react';

import {capitalize} from 'lodash';
import {cn} from 'common/utils';
import {AsteriskIcon} from 'lucide-react';
import {CardBrandIcon} from 'common/components/CardBrandIcon';

interface PaymentMethodItemProps {
  cardBrand: string;
  lastFourDigits?: string;
  className?: string;
  onClick?: () => void;
}

export function PaymentMethodItem({cardBrand, lastFourDigits, onClick, className}: PaymentMethodItemProps) {
  return (
    <div onClick={onClick} className={cn('tw-flex tw-items-center tw-gap-3 tw-text-gray-900', className)}>
      <CardBrandIcon className="tw-w-14" brand={cardBrand} />
      <div className="tw-text-left">
        {['apple-pay', 'google-pay'].includes(cardBrand) ? (
          <p className="tw-flex tw-items-center tw-gap-1 tw-text-sm">{cardBrand.split('-').map(capitalize).join(' ')}</p>
        ) : (
          <>
            <p className="tw-flex tw-items-center tw-gap-1 tw-text-sm">
              <span className="tw-flex">
                {[0, 1, 2, 3].map((i) => (
                  <AsteriskIcon key={i} className="tw-w-3" />
                ))}
              </span>
              <span className="tw-text-sm tw-tracking-widest">{lastFourDigits}</span>
            </p>
            <p className="tw-text-xs tw-text-gray-500">{cardBrand.split('-').map(capitalize).join(' ')}</p>
          </>
        )}
      </div>
    </div>
  );
}
