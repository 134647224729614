import {Dialog, DialogContent, DialogProps} from 'common/components/Dialog';

interface ProductImageDialogProps extends DialogProps {
  productImageUrl?: string;
  productName?: string;
}
export default function ProductImageDialog({productImageUrl, productName, ...dialogProps}: ProductImageDialogProps) {
  return (
    <Dialog {...dialogProps}>
      <DialogContent className="tw-bg-black tw-p-0">
        <div className="tw-flex tw-h-full">
          <img className="tw-my-auto tw-w-full" src={productImageUrl} alt={productName} />
        </div>
      </DialogContent>
    </Dialog>
  );
}
