import {ClassValue, clsx} from 'clsx';
import {twMerge} from 'tailwind-merge';

// Taken from: https://ui.shadcn.com/docs/installation#add-a-cn-helper
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * This function is used to enable TailwindCSS IntelliSense outside the className prop
 *
 * Example:
 * const someClasses = tw`text-green-500 bg-danger`
 */
export const tw = String.raw;
