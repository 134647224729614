import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

const stripePromise = process.env.REACT_APP_PUBLIC_STRIPE_KEY
  ? loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY, {locale: 'en'})
  : null;

export const StripeProvider: React.FC = ({children}) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};
