import * as React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import {cn} from 'common/utils';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverArrow = PopoverPrimitive.Arrow;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({className, align = 'center', sideOffset = 4, children, ...props}, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        'tw-z-50 tw-max-w-[100vw] tw-rounded-md tw-border tw-border-gray-200 tw-bg-white tw-p-4 tw-drop-shadow-md tw-outline-none tw-animate-in tw-fade-in data-[side=bottom]:tw-slide-in-from-top-2 data-[side=top]:tw-slide-in-from-bottom-2',
        className
      )}
      {...props}
    >
      {children}
      <PopoverArrow className="tw-fill-white tw-stroke-gray-300" />
    </PopoverPrimitive.Content>
  </PopoverPrimitive.Portal>
));

PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export {Popover, PopoverTrigger, PopoverContent, PopoverArrow};
