import React from 'react';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';

import {useAppContext} from 'contexts/app.context';

import {Label} from 'common/components/Label';
import {OrderAttributeGroup} from 'models/order-custom-attribute-group.model';
import {capitalize} from 'lodash';
import {getCustomerFullName} from 'utils/helpers.utils';
import {Customer, CustomerUpdateRequest} from 'models/customer.model';
import {useMutation} from 'react-query';
import {updateCustomerRequest} from 'api/customer.api';
import {useToast} from 'hooks/use-toast.hook';
import {Cart} from 'models/cart.model';
import {Input} from 'common/components/Input';

export interface OrderAttributesFormFields {
  customerName: string;
  [key: string]: string; // This is used to represent the dynamic inputs generated from the location attributes
}

interface OrderAttributesFormProps extends React.HTMLProps<HTMLFormElement> {
  onSubmitted: () => void;
  orderAttributeGroup: OrderAttributeGroup;
}

function getFormDefaultValues(customer?: Customer, cart?: Cart) {
  const defaultValues: OrderAttributesFormFields = {customerName: getCustomerFullName(customer)};
  if (cart?.orderGroupsWithAttributes && cart?.orderGroupsWithAttributes.length > 0) {
    const [{attributes}] = cart?.orderGroupsWithAttributes ?? [];

    for (const {name, value} of attributes) {
      defaultValues[name] = value;
    }
  }

  return defaultValues;
}

export const OrderAttributesForm = React.forwardRef<HTMLFormElement, OrderAttributesFormProps>(
  ({orderAttributeGroup, onSubmitted, ...formProps}, ref) => {
    const {cart, customer, setContextState} = useAppContext();
    const toast = useToast();

    const defaultValues = getFormDefaultValues(customer, cart);

    const {formState, reset, register, handleSubmit} = useForm<OrderAttributesFormFields>({defaultValues});

    const updateCustomerMutation = useMutation<Customer, Error, CustomerUpdateRequest>({
      mutationFn: (body) => updateCustomerRequest(customer!.token, body),
      mutationKey: 'update-customer',
    });

    const {attributes} = orderAttributeGroup;

    const onSubmit = async (form: OrderAttributesFormFields) => {
      try {
        const {customerName} = form;
        const fullName = customerName.split(' ');
        let updatedCustomer: Customer = {
          ...customer!,
        };

        if (customerName !== getCustomerFullName(customer)) {
          updatedCustomer = await updateCustomerMutation.mutateAsync({
            ...customer,
            firstName: capitalize(fullName[0]),
            lastName: fullName.length > 1 ? capitalize(fullName[fullName.length - 1]) : '',
          });
        }

        setContextState({
          cart: {
            ...cart!,
            orderGroupsWithAttributes: [
              {
                group: orderAttributeGroup,
                attributes: Object.entries(form)
                  .filter(([name, value]) => name !== 'customerName' && value !== '')
                  .map(([name, value]) => ({
                    name,
                    orderAttributeId: attributes.find((attribute) => attribute.name === name)!.id,
                    value,
                  })),
              },
            ],
          },
          customer: {...customer!, ...updatedCustomer},
        });

        reset(
          attributes.reduce((values, attribute) => ({...values, [attribute.name]: ''}), {
            customerName: getCustomerFullName(updatedCustomer),
          })
        );

        onSubmitted();
      } catch (error) {
        console.error(error);
        toast('There was an error. Please try again.', 'error');
      }
    };

    return (
      <form ref={ref} onSubmit={handleSubmit(onSubmit)} {...formProps}>
        <div className={classNames('kl-input-group', {'kl-input-group-danger': formState.errors.customerName})}>
          <div className="tw-mb-1">
            <Label htmlFor="customerName">Name</Label>
          </div>
          <Input id="customerName" autoFocus type="text" {...register('customerName', {required: true})} />
          {formState.errors.customerName ? <p className="kl-input-helper">Customer name is required</p> : null}
        </div>

        {orderAttributeGroup.attributes.map(({name, id, isRequired}) => (
          <div key={id} className={classNames('kl-input-group', {'kl-input-group-danger': formState.errors[name]})}>
            <div className="tw-mb-1">
              <Label htmlFor={name}>{capitalize(name)}</Label>
            </div>

            <Input id={name} type="text" {...register(name, {required: isRequired})} />
            {formState.errors[name] ? <p className="kl-input-helper">{`${capitalize(name)} is required`}</p> : null}
          </div>
        ))}
      </form>
    );
  }
);
OrderAttributesForm.displayName = 'OrderAttributesForm';
