import {UpsellingItem} from 'models/upselling-item.model';
import {useRef} from 'react';
import {Icon} from '@blueprintjs/core';
import {getPriceText} from 'utils/helpers.utils';
import {useWindowSize} from 'hooks/use-window-size.hook';
import {MOBILE_BREAKPOINT_WIDTH} from 'utils/constants';

interface CarouselProps {
  products: UpsellingItem[];
  onClickProduct: (productId: number, menuCategoryProductId: number) => void;
}

export default function Carousel({products, onClickProduct}: CarouselProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {windowWidth} = useWindowSize();
  const isMobile = windowWidth < MOBILE_BREAKPOINT_WIDTH;

  const moveCarousel = (direction: 'left' | 'right') => {
    if (wrapperRef && wrapperRef.current) {
      wrapperRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
        left: direction === 'left' ? wrapperRef.current.scrollLeft - 120 : wrapperRef.current.scrollLeft + 120,
      });
    }
  };

  const areButtonsVisible = (wrapperRef: React.RefObject<HTMLDivElement>) => {
    if (wrapperRef && wrapperRef.current && !isMobile) {
      return wrapperRef.current.scrollWidth > wrapperRef.current.clientWidth;
    }
    return false;
  };

  return (
    <div>
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
        <h3 className="tw-text-base tw-text-gray-900">Last chance!</h3>
        <div className="tw-space-x-2">
          {areButtonsVisible(wrapperRef) && (
            <>
              <button
                className="tw-w-8 tw-h-8 tw-bg-white tw-text-gray-900 tw-shadow tw-rounded-full"
                onClick={() => moveCarousel('left')}
              >
                <Icon icon="chevron-left" className="tw-flex tw-justify-center tw-items-center" />
              </button>
              <button
                className="tw-w-8 tw-h-8 tw-bg-white tw-text-gray-900 tw-shadow tw-rounded-full"
                onClick={() => moveCarousel('right')}
              >
                <Icon icon="chevron-right" className="tw-flex tw-justify-center tw-items-center" />
              </button>
            </>
          )}
        </div>
      </div>
      <div
        ref={wrapperRef}
        className="tw-grid tw-grid-flow-col tw-auto-cols-min tw-gap-4 tw-overflow-x-auto no-scrollbar tw-overflow-y-hidden tw-snap-mandatory"
      >
        {products.map((product, index) => (
          <div
            key={index}
            onClick={() => onClickProduct(product.id, product.menuCategoryProductId)}
            className="tw-cursor-pointer tw-flex tw-flex-col tw-justify-center tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-snap-center tw-w-28 tw-h-40"
          >
            {!!product.images.length && (
              <div className="tw-w-full">
                <img src={product.images[0].url} className="tw-object-cover tw-aspect-square tw-rounded-t" />
              </div>
            )}
            <div className="tw-my-auto tw-px-2 tw-py-1">
              <p className="tw-text-sm tw-text-center tw-text-gray-900 tw-truncate">{product.name}</p>
              <p className="tw-text-sm tw-text-center tw-text-gray-900 tw-font-bold">{getPriceText(product.price)}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
