import React, {useEffect, useState} from 'react';

import {formatPhoneNumberIntl} from 'react-phone-number-input';
import {useIsMutating} from 'react-query';
import {ReactComponent as PhoneIcon} from 'assets/icons/phone.icon.svg';
import {Button} from 'common/components/Button';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
} from 'common/components/Dialog';
import {PencilIcon} from '@heroicons/react/24/outline';

import {SendVerificationCodeForm} from './SendVerificationCodeForm';
import {Loader2} from 'lucide-react';
import {VerifyCodeForm} from './VerifyCodeForm';

interface SignInDialogProps extends DialogProps {
  onContinue?: () => void;
}

type LoginStep = 'send-verification-code' | 'verify-code';

export function useSignInState() {
  const [activeStep, setActiveSteop] = useState<LoginStep>('send-verification-code');
  const [numberBeingVerified, setNumberBeingVerified] = useState('');
  const isSendingVerificationCode = Boolean(useIsMutating({mutationKey: 'send-verification-code'}));
  const isVerifyingCode = Boolean(useIsMutating({mutationKey: 'verify-code'}));

  return {
    activeStep,
    setActiveSteop,
    numberBeingVerified,
    setNumberBeingVerified,
    isSendingVerificationCode,
    isVerifyingCode,
  };
}

export function SignInDialog({onContinue, ...dialogProps}: SignInDialogProps) {
  const {
    activeStep,
    setActiveSteop,
    numberBeingVerified,
    setNumberBeingVerified,
    isSendingVerificationCode,
    isVerifyingCode,
  } = useSignInState();

  useEffect(() => {
    if (!dialogProps.open) {
      setActiveSteop('send-verification-code');
    }
  }, [dialogProps.open]);

  return (
    <Dialog {...dialogProps}>
      <DialogContent>
        <DialogHeader className="tw-items-center tw-mb-4">
          <div className="tw-pt-4 tw-pb-6">
            <PhoneIcon className="icon" />
          </div>
          <DialogTitle>Sign in to receive order updates</DialogTitle>
          <DialogDescription>We will send you a SMS with a verification code.</DialogDescription>
        </DialogHeader>

        {/* Send verification code */}
        {activeStep === 'send-verification-code' && (
          <>
            <SendVerificationCodeForm
              id="send-verification-code-form"
              defaultValues={{phone: numberBeingVerified}}
              onSubmitted={({phone}) => {
                setNumberBeingVerified(phone);
                setActiveSteop('verify-code');
              }}
            />

            <DialogFooter>
              <RecaptchaAttributionMessage />
              <Button disabled={isSendingVerificationCode} size="lg" type="submit" form="send-verification-code-form">
                Send verification code
                {isSendingVerificationCode && <Loader2 className="tw-ml-2 tw-h-4 tw-w-4 tw-animate-spin" />}
              </Button>
            </DialogFooter>
          </>
        )}

        {/* Verify code */}
        {activeStep === 'verify-code' && (
          <>
            <div className="tw-flex tw-flex-col tw-items-center">
              <p className="tw-space-y-0.5 tw-text-center tw-text-sm tw-mb-4">
                <span className="tw-block">We have sent the code to</span>
                <button
                  onClick={() => setActiveSteop('send-verification-code')}
                  className="tw-inline-flex tw-items-center tw-gap-1 tw-rounded tw-bg-blue-100 tw-px-2 tw-py-0.5 tw-text-xs tw-font-medium tw-text-blue-800"
                >
                  <span>{formatPhoneNumberIntl(numberBeingVerified)}</span> <PencilIcon className="tw-h-3 tw-w-3" />
                </button>
              </p>

              <VerifyCodeForm
                id="verify-code-form"
                defaultValues={{phone: numberBeingVerified}}
                onSubmitted={() => onContinue?.()}
              />
            </div>

            <div className="tw-hidden">
              <SendVerificationCodeForm id="resend-verification-code-form" defaultValues={{phone: numberBeingVerified}} />
            </div>

            <DialogFooter>
              <Button
                size="lg"
                disabled={isSendingVerificationCode}
                variant="ghost"
                form="resend-verification-code-form"
                className="tw-mb-2"
              >
                Resend code
                {isSendingVerificationCode && <Loader2 className="tw-ml-2 tw-h-4 tw-w-4 tw-animate-spin" />}
              </Button>

              <Button size="lg" form="verify-code-form" disabled={isVerifyingCode}>
                Verify code
                {isVerifyingCode && <Loader2 className="tw-ml-2 tw-h-4 tw-w-4 tw-animate-spin" />}
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

function RecaptchaAttributionMessage() {
  return (
    <p className="tw-mb-4 tw-text-center tw-text-xs tw-text-gray-400">
      This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </p>
  );
}
