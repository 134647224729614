import React from 'react';

import imgAmex from 'assets/card-brands/amex.png';
import imgJcb from 'assets/card-brands/jcb.png';
import imgApplePay from 'assets/card-brands/apple-pay.png';
import imgDiners from 'assets/card-brands/diners.png';
import imgGeneric from 'assets/card-brands/generic.svg';
import imgGooglePay from 'assets/card-brands/google-pay.png';
import imgMasterCard from 'assets/card-brands/mastercard.png';
import imgVisa from 'assets/card-brands/visa.png';

interface OrdercardBrandFigureProps {
  brand: string | null;
  className?: string;
}

export const CardBrandIcon: React.FC<OrdercardBrandFigureProps> = ({brand, className}) => {
  const {name, img} = getCardBrandIcon(brand);

  return <img src={img} alt={name} title={name} className={className} />;
};

export const getCardBrandIcon = (cardBrand: string | null) => {
  if (!cardBrand) {
    return {name: '', img: imgGeneric};
  }

  switch (cardBrand.toLowerCase()) {
    case 'visa':
      return {name: 'Visa', img: imgVisa};
    case 'mastercard':
      return {name: 'Mastercard', img: imgMasterCard};
    case 'amex':
      return {name: 'American Express', img: imgAmex};
    case 'diners':
      return {name: 'Diners', img: imgDiners};
    case 'jcb':
      return {name: 'JCB', img: imgJcb};
    case 'google-pay':
      return {name: 'Google Pay', img: imgGooglePay};
    case 'apple-pay':
      return {name: 'Apple Pay', img: imgApplePay};
    default:
      return {name: cardBrand, img: imgGeneric};
  }
};
