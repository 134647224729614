import React from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      retry: (failureCount, error) => {
        return false;
      },
    },
  },
});

export const ReactQueryProvider: React.FC = ({children}) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {process.env.REACT_APP_SHOW_REACT_QUERY_DEVTOOLS === 'true' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
};
