// General
export const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;

// Dates
export const TIME_FORMAT = 'h:mm a' as const;
export const DATETIME_FORMAT = 'MM/dd/yy h:mm a' as const;

// Orders
export const ORDER_ITEM_TYPES = ['product', 'tax', 'fee', 'tip', 'surcharge', 'discount', 'refund'] as const;
export const ORDER_TYPES = ['dine-in', 'takeaway', 'pre-ordering'] as const;
export const ORDER_STATUSES = [
  'received',
  'preparing',
  'ready',
  'delivered',
  'cancelled-by-customer',
  'cancelled-by-store',
  'refunded',
  'partially-refunded',
] as const;
export const ORDERING_FLOW_TYPES = ['delivery-to-location', 'pickup', 'single-location'] as const;
export const DISCOUNT_TYPES = ['coupon-flat', 'coupon-percentage', 'promotion-flat-price'] as const;

// Responsiveness
export const MOBILE_BREAKPOINT_WIDTH = 768;
export const TABLET_BREAKPOINT_WIDTH = 1440;

export const CURRENCIES = ['USD', 'CAD'] as const;
export const COUNTRY_CODES = ['US', 'CA'] as const;

export const WALLET_BRANDS = ['google-pay', 'apple-pay'] as const;
