import React from 'react';
import {Product} from 'models/product.model';
import {Discount} from 'models/discount.model';
import {getApplyingPromotion, getPriceWithDiscount} from 'utils/helpers.utils';

import ProductPrice from './ProductPrice';
import {AspectRatio} from 'common/components/AspectRatio';
import {ProductAtributte} from './ProductAttribute';

interface ProductCardProps {
  product: Product;
  menuCategoryProductId: number;
  showProductImagePlaceholder: boolean;
  discounts?: Discount[];
  pricesIncludeTax: boolean;
  onClick?: (product: Product) => void;
}

export default function MenuItem({
  product,
  menuCategoryProductId,
  discounts,
  pricesIncludeTax,
  onClick,
  showProductImagePlaceholder = true,
}: ProductCardProps) {
  const applyingPromotion = getApplyingPromotion(discounts ?? [], menuCategoryProductId);
  const priceWithDiscount = applyingPromotion ? getPriceWithDiscount(product, applyingPromotion) : undefined;

  return (
    <div
      className="tw-cursor-pointer hover:tw-shadow tw-transition-shadow tw-shadow-sm tw-h-32 tw-flex tw-rounded-lg tw-items-center tw-border tw-border-solid tw-border-gray-200 tw-bg-white tw-p-2"
      onClick={() => onClick?.(product)}
    >
      {/* Image */}
      {Boolean(product.images[0]) && (
        <div className="tw-w-28 tw-shrink-0">
          <AspectRatio ratio={1}>
            <img className="tw-w-full tw-h-full tw-object-cover tw-rounded-md" src={product.images[0].url} />
          </AspectRatio>
        </div>
      )}
      <div className="tw-flex tw-w-full tw-justify-center tw-flex-col tw-pl-2 tw-space-y-0.5 tw-overflow-hidden">
        <h3 className="tw-text-base tw-font-bold tw-line-clamp-1">{product.name}</h3>
        {product.description && <p className="tw-text-sm tw-text-gray-500 tw-line-clamp-1">{product.description}</p>}
        <ProductPrice
          price={product.price}
          pricesIncludeTax={pricesIncludeTax}
          priceWithDiscount={priceWithDiscount}
          taxValue={product.taxValue}
        />
        {product.attributes.length > 0 && (
          <div className="tw-space-x-1 tw-w-full tw-line-clamp-1">
            {product.attributes.map(({id, name}) => (
              <ProductAtributte name={name} key={id} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
