import React from 'react';

import {useAppContext} from 'contexts/app.context';

import {Button} from 'common/components/Button';
import {getPriceText} from 'utils/helpers.utils';
import OrderItem from './OrderItem';
import {CartModifierGroup} from 'models/cart.model';
import {Product} from 'models/product.model';

interface CartSidebarProps {
  onViewOrder: () => void;
}
export function getOrderItemModifiers(modifierGroups: CartModifierGroup[], product: Product) {
  const productModifiersGroups = modifierGroups.map((modifierGroup) =>
    product.modifierGroups.find((productModifierGroup) => productModifierGroup.id === modifierGroup.modifierGroupId)
  );

  const itemModifiers: {name: string; price: number}[] = [];
  modifierGroups.forEach((modifierGroup) => {
    const productModifierGroup = productModifiersGroups.find(
      (productModifierGroup) => productModifierGroup?.id === modifierGroup.modifierGroupId
    );
    modifierGroup.modifiers.forEach((modifier) => {
      const itemModifier = productModifierGroup?.modifiers.find((productModifier) => productModifier.id === modifier.modifierId);
      if (itemModifier) {
        itemModifiers.push({name: itemModifier.name, price: itemModifier.price});
      }
    });
  });

  return itemModifiers;
}
export default function CartSidebar({onViewOrder}: CartSidebarProps) {
  const {cart} = useAppContext();

  return (
    <div className="tw-flex tw-h-full tw-flex-col tw-justify-between tw-border-gray-200 tw-bg-white">
      <div className="tw-overflow-y-auto">
        <header>
          <p className="tw-text-xs tw-font-bold tw-text-slate-500">Your cart</p>
        </header>
        {cart && cart?.items.length > 0 ? (
          cart?.items.map((item, index) => (
            <div key={index} className="tw-py-1 tw-border-b tw-border-gray-200 last:tw-border-b-0">
              <OrderItem
                menuCategoryProductId={item.menuCategoryProductId}
                product={item.product}
                itemQuantity={item.quantity}
                itemModifiers={getOrderItemModifiers(item.modifierGroups, item.product)}
                itemIndex={index}
              />
            </div>
          ))
        ) : (
          /*Empty cart image*/
          <h1>Your cart is empty</h1>
        )}
      </div>

      {cart && cart?.items.length > 0 && (
        <div className="tw-flex tw-justify-center tw-pb-6">
          {cart && (
            <Button onClick={() => onViewOrder()} size="xl" className="tw-w-full">
              <span className="tw-mr-2">Go to checkout</span>({getPriceText(cart?.totalWithDiscount ?? cart?.total)})
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
