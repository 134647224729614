import {cn} from 'common/utils';
import {ReactComponent as EmptyOrdersIcon} from 'assets/icons/empty-orders.icon.svg';
import {useAppContext} from 'contexts/app.context';
import {Dialog, Transition} from '@headlessui/react';
import {sortBy} from 'lodash';
import {Discount} from 'models/discount.model';
import {Menu} from 'models/menu.model';
import {Product} from 'models/product.model';
import {Store} from 'models/store.model';
import {Fragment, ReactFragment, useCallback, useEffect, useRef, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router';
import {NonIdealState} from 'shared/non-ideal-state.component';
import CartSidebar from './CartSidebar';
import ProductDialog from './ProductDialog';
import MenuItem from './MenuItem';
import {paths} from 'utils/paths';
import {Button} from 'common/components/Button';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {Input} from 'common/components/Input';

import {MagnifyingGlassIcon} from '@heroicons/react/24/solid';
import * as ReactScroll from 'react-scroll';
import {config, useSpring} from '@react-spring/core';
import {animated} from '@react-spring/web';

interface StorefrontProps {
  discounts?: Discount[];
  menus?: Menu[];
  store?: Store;
  subtitle?: string;
  coverImage?: string;
  avatarUrl: string;
  title?: string;
  eventDate?: string;
  pricesIncludeTax: boolean;
  renderNotifications?: () => ReactFragment;
  showProductImagePlaceholder?: boolean;
  viewOnly?: boolean;
}

export function Storefront({
  discounts,
  menus,
  store,
  title,
  subtitle,
  eventDate,
  pricesIncludeTax,
  showProductImagePlaceholder = false,
  renderNotifications,
  coverImage,
  avatarUrl,
  viewOnly = false,
}: StorefrontProps) {
  const categoryNavListRef = useRef<HTMLDivElement | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<{productId: number; menuCategoryProductId: number} | undefined>();
  const [isProductDialogOpen, setIsProductDialogOpen] = useState(false);
  const [isCartSidebarOpen, setIsCartSidebarOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [isScrollingNav, setIsScrollingNav] = useState(false);
  const {location, cart} = useAppContext();
  const {url} = useRouteMatch();
  const history = useHistory();

  // Categories scrolling animation
  const [springs, springApi] = useSpring(
    () => ({
      from: {scroll: 0},
      to: {scroll: 0},
      delay: 100,
      config: config.default,
    }),
    []
  );

  const scrollCategoryAnchorIntoView = useCallback(
    (categoryElementId: string) => {
      const categoryAnchorElement = document.getElementById(`${categoryElementId}-anchor`) as HTMLAnchorElement;
      const currentCategoryNavListRef = categoryNavListRef.current;

      if (categoryAnchorElement && currentCategoryNavListRef) {
        springApi.start({
          from: {scroll: currentCategoryNavListRef.scrollLeft},
          to: {scroll: categoryAnchorElement.offsetLeft - 30},
        });
      }
    },
    [springApi]
  );

  useEffect(() => {
    ReactScroll.Events.scrollEvent.register('begin', function (to, element) {
      setIsScrollingNav(true); // Block scrolling the categories nav while scrolling to a category
      scrollCategoryAnchorIntoView(to);
    });

    ReactScroll.Events.scrollEvent.register('end', function (to, element) {
      setIsScrollingNav(false);
    });

    ReactScroll.scrollSpy.update();

    return () => {
      ReactScroll.Events.scrollEvent.remove('begin');
      ReactScroll.Events.scrollEvent.remove('end');
    };
  }, [scrollCategoryAnchorIntoView]);

  const sortedCategories =
    menus
      ?.flatMap(({menuCategories}) => sortBy(menuCategories, ({order}) => order))
      .filter(({menuCategoryProducts}) => menuCategoryProducts.length > 0) ?? [];

  const onViewMyOrderClick = () => {
    history.push('/checkout');
  };

  const storeHasProducts = menus?.some(({menuCategories}) =>
    menuCategories?.some((menuCategory) => menuCategory.menuCategoryProducts.length > 0)
  );

  if (!storeHasProducts) {
    return (
      <NonIdealState
        icon={<EmptyOrdersIcon />}
        title="No products found"
        description="There are no products available at this moment"
        action={{
          text: 'Scan another QR',
          callback: () => history.push(paths.qrScanner.route),
        }}
      />
    );
  }

  const filteredCategories = sortedCategories.filter((category) =>
    category.menuCategoryProducts.some((product) => product.product.name.toLowerCase().includes(search.toLowerCase()))
  );

  return (
    <>
      <div className="tw-flex tw-flex-1 tw-items-stretch tw-overflow-hidden">
        <main id="main-container" className="tw-flex-1 tw-scroll-pt-46 tw-overflow-y-auto tw-bg-white">
          <section className="tw-mx-auto tw-flex tw-min-w-0 tw-max-w-4xl tw-flex-1 tw-flex-col">
            {/* Header */}
            <header>
              {coverImage && (
                <div className="tw-w-full tw-mt-6">
                  <img
                    className="tw-w-full tw-h-full tw-rounded-xl tw-object-cover tw-aspect-[11/5]"
                    src={coverImage}
                    alt="Landscape photograph by Tobias Tullius"
                  />
                </div>
              )}
              <div className="tw-mx-auto tw-px-4 sm:tw-px-6">
                <div
                  className={cn('sm:tw-flex sm:tw-items-end sm:tw-space-x-5', coverImage ? '-tw-mt-12 sm:-tw-mt-16' : 'tw-mt-4')}
                >
                  <div className="tw-flex">
                    <img
                      className="tw-h-20 tw-w-20 tw-rounded-full tw-ring-4 tw-ring-white tw-drop-shadow-sm sm:tw-h-32 sm:tw-w-32 tw-object-cover"
                      src={avatarUrl}
                      alt=""
                    />
                  </div>
                </div>
                <div className="tw-mt-4 tw-block tw-min-w-0 tw-flex-1">
                  <h1 className="tw-truncate tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-font-bold tw-text-gray-900">{title}</h1>
                  <h2 className="tw-truncate tw-mt-1 tw-text-base md:tw-text-lg lg:tw-text-xl tw-font-medium tw-text-gray-900">
                    {subtitle}
                  </h2>
                  {!!eventDate && <p className="tw-truncate tw-mt-1 tw-text-base tw-font-medium tw-text-gray-500">{eventDate}</p>}
                </div>

                {!!renderNotifications && <div className="tw-mt-2">{renderNotifications()}</div>}
              </div>
            </header>

            {/* Categories nav list */}
            <div className="tw-sticky tw-top-0 tw-z-10 tw-mx-0 tw-border-b tw-border-solid tw-border-gray-200 tw-bg-white md:tw-mx-6">
              <div className="tw-px-4 md:tw-px-0 tw-mt-4 tw-mb-2">
                <div className="tw-relative tw-mt-2 tw-rounded-md tw-shadow-sm">
                  <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-2">
                    <MagnifyingGlassIcon className="tw-h-5 tw-w-5 tw-text-gray-400" />
                  </div>
                  <Input
                    className="tw-pl-8 tw-ml-0"
                    placeholder="Search products"
                    type="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>

              <div className="tw-flex">
                <animated.nav
                  scrollLeft={springs.scroll}
                  ref={categoryNavListRef}
                  className="no-scrollbar tw-m-0 tw-flex tw-items-center tw-gap-4 tw-overflow-auto"
                >
                  {filteredCategories.map((category) => (
                    <ReactScroll.Link
                      containerId="main-container"
                      key={category.id}
                      id={`category-${category.id}-anchor`}
                      to={`category-${category.id}`}
                      offset={-120}
                      spy
                      onSetActive={(to) => {
                        if (!isScrollingNav) {
                          scrollCategoryAnchorIntoView(to);
                        }
                      }}
                      smooth
                      duration={250}
                      activeClass="!tw-font-bold !tw-border-blue-500"
                      className={cn(
                        'first:tw-ml-4 last:tw-mr-4 md:first:tw-ml-0 md:last:tw-mr-0 tw-m-0 tw-border-b-2 tw-border-solid tw-border-transparent tw-shrink-0 tw-py-2 tw-cursor-pointer tw-text-slate-900 tw-text-sm'
                      )}
                    >
                      {category.name}
                    </ReactScroll.Link>
                  ))}
                </animated.nav>
              </div>
            </div>

            <div className="tw-h-full">
              <div className="tw-block tw-h-full tw-w-full">
                {/* Product grid */}
                <div className="tw-mt-6 tw-px-4 md:tw-px-6 ">
                  {filteredCategories.map((category) => (
                    <ReactScroll.Element name={`category-${category.id}`} className="tw-pb-6 last:tw-pb-0" key={category.id}>
                      <header className="tw-relative tw-mb-2 tw-w-full ">
                        <h3 className="tw-text-xl tw-font-bold">{category.name}</h3>
                      </header>
                      <div className="tw-grid tw-gap-4 sm:tw-grid-cols-1 md:tw-grid-cols-2">
                        {sortBy(category.menuCategoryProducts, ({order}) => order)
                          .filter(({product}) => product.name.toLowerCase().includes(search.toLowerCase()))
                          .map(({id, product}, index) => (
                            <MenuItem
                              key={index}
                              product={product}
                              menuCategoryProductId={id}
                              discounts={discounts ?? []}
                              pricesIncludeTax={pricesIncludeTax}
                              showProductImagePlaceholder={showProductImagePlaceholder}
                              onClick={(product: Product) => {
                                history.push(url);
                                setSelectedProduct({productId: product.id, menuCategoryProductId: id});
                                setIsProductDialogOpen(true);
                              }}
                            />
                          ))}
                      </div>
                    </ReactScroll.Element>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </main>

        {!viewOnly && (
          <aside className="tw-hidden tw-w-96 tw-p-4 tw-overflow-y-auto tw-border-l tw-border-gray-200 tw-bg-white lg:tw-block">
            <CartSidebar onViewOrder={onViewMyOrderClick} />
          </aside>
        )}
      </div>

      {!!cart?.items.length && (
        <>
          <div className="tw-absolute tw-inset-x-0 tw-bottom-0 tw-m-4 tw-rounded-full tw-shadow-xl tw-shadow-white lg:tw-hidden">
            <Button onClick={() => setIsCartSidebarOpen(true)} size="xl" className="tw-w-full tw-shadow-lg">
              <span className="tw-mr-2">View my order</span>({cart.itemsQuantity})
              {/* ({getPriceText(cart.totalWithDiscount ?? cart.total)}) */}
            </Button>
          </div>

          <Transition.Root show={isCartSidebarOpen && Boolean(cart.items.length)} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-20" onClose={() => setIsCartSidebarOpen(false)}>
              <Transition.Child
                as={Fragment}
                enter="tw-transition-opacity tw-ease-linear tw-duration-200"
                enterFrom="tw-opacity-0"
                enterTo="tw-opacity-100"
                leave="tw-transition-opacity tw-ease-linear tw-duration-200"
                leaveFrom="tw-opacity-100"
                leaveTo="tw-opacity-0"
              >
                <div className="tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-75" />
              </Transition.Child>

              <div className="tw-fixed tw-inset-0 tw-z-40 tw-flex tw-origin-right">
                <Transition.Child
                  as={Fragment}
                  enter="tw-transition tw-ease-in-out tw-duration-200 tw-transform"
                  enterFrom="tw-translate-x-full"
                  enterTo="tw-translate-x-0"
                  leave="tw-transition tw-ease-in-out tw-duration-200 tw-transform"
                  leaveFrom="tw-translate-x-0"
                  leaveTo="tw-translate-x-full"
                >
                  <Dialog.Panel className="tw-absolute tw-right-0 tw-inset-y-0 tw-flex tw-w-full tw-max-w-md tw-flex-1 tw-flex-col tw-bg-white">
                    <Transition.Child
                      as={Fragment}
                      enter="tw-ease-in-out tw-duration-200"
                      enterFrom="tw-opacity-0"
                      enterTo="tw-opacity-100"
                      leave="tw-ease-in-out tw-duration-200"
                      leaveFrom="tw-opacity-100"
                      leaveTo="tw-opacity-0"
                    >
                      <div className="tw-h-full tw-p-4">
                        <button
                          onClick={() => setIsCartSidebarOpen(false)}
                          className="tw-bg-white tw-absolute tw-top-3 tw-right-4 tw-rounded-full tw-p-1 tw-transition-opacity focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-slate-400 focus:tw-ring-offset-2 disabled:tw-pointer-events-none data-[state=open]:tw-bg-slate-100 dark:focus:tw-ring-slate-400 dark:focus:tw-ring-offset-slate-900 dark:data-[state=open]:tw-bg-slate-800"
                        >
                          <XMarkIcon className="tw-h-5 tw-w-5" />
                          <span className="tw-sr-only">Close</span>
                        </button>
                        <CartSidebar onViewOrder={onViewMyOrderClick} />
                      </div>
                    </Transition.Child>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </>
      )}

      <ProductDialog
        open={isProductDialogOpen}
        productId={selectedProduct?.productId}
        menuCategoryProductId={selectedProduct?.menuCategoryProductId}
        discounts={discounts}
        pricesIncludeTax={location?.pricesIncludeTax}
        viewOnly={viewOnly}
        onOpenChange={(open) => {
          setSelectedProduct(undefined);
          setIsProductDialogOpen(open);
        }}
      />
    </>
  );
}
