import {Checkbox, Radio} from '@material-ui/core';
import {useEffect, useState} from 'react';
import {useAppContext} from 'contexts/app.context';
import {Product} from 'models/product.model';
import {Icon} from 'shared/icon/icon.component';
import {CartModifierGroup} from 'models/cart.model';
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogProps} from 'common/components/Dialog';
import {useQuery} from 'react-query';
import {fetchProductRequest} from 'api/product.api';

import {Discount} from 'models/discount.model';
import {getApplyingPromotion, getPriceText, getPriceWithDiscount} from 'utils/helpers.utils';
import {Badge} from 'shared/badge.component';
import ProductImageDialog from './ProductImageDialog';
import {Button} from 'common/components/Button';
import {QuantityInput} from './QuantityInput';
import {AspectRatio} from 'common/components/AspectRatio';

import {ExclamationTriangleIcon} from '@heroicons/react/20/solid';
import {ProductDialogPlacheolder} from 'components/product-dialog-placeholder.component';
import {cn} from 'common/utils';
import {Textarea} from 'common/components/Textarea';
import {Label} from 'common/components/Label';
import ProductPrice from './ProductPrice';
import {ProductAtributte} from './ProductAttribute';

interface ProductDialogProps extends DialogProps {
  productId?: number;
  menuCategoryProductId?: number;
  discounts?: Discount[];
  pricesIncludeTax?: boolean;
  viewOnly: boolean;
  isUpsellingContext?: boolean;
}

interface CartItemForm {
  product?: Product;
  quantity: number;
  total: number;
  totalWithDiscount?: number;
  customerNotes: string;
  modifierGroups: CartModifierGroupForm[];
  menuCategoryProductId?: number;
}

interface CartModifierGroupForm extends CartModifierGroup {
  validationError: string;
}

export default function ProductDialog({
  productId,
  menuCategoryProductId,
  discounts,
  pricesIncludeTax,
  viewOnly,
  isUpsellingContext = false,
  ...dialogProps
}: ProductDialogProps) {
  const [cartItem, setCartItem] = useState<CartItemForm>({
    product: undefined,
    quantity: 1,
    total: 0,
    customerNotes: '',
    modifierGroups: [],
    menuCategoryProductId,
  });

  const {cartReducer, location, cart} = useAppContext();
  const [isProductImageDialogOpen, setIsProductImageDialogOpen] = useState(false);

  const productQuery = useQuery(['products', productId], {
    queryFn: () => fetchProductRequest(+productId!),
    refetchInterval: 1000 * 30,
    enabled: !!productId,
  });

  const applyingPromotion = menuCategoryProductId ? getApplyingPromotion(discounts ?? [], menuCategoryProductId) : undefined;
  const priceWithDiscount =
    productQuery.data && applyingPromotion ? getPriceWithDiscount(productQuery.data, applyingPromotion) : undefined;

  useEffect(() => {
    if (productQuery.data) {
      setCartItem({
        product: productQuery.data,
        quantity: 1,
        customerNotes: '',
        modifierGroups: [],
        total: 0,
        menuCategoryProductId,
      });
    }
  }, [menuCategoryProductId, productQuery.data]);

  useEffect(() => {
    if (cartItem.product) {
      const {total, totalWithDiscount} = getTotal(cartItem);
      if (total !== cartItem.total || totalWithDiscount !== cartItem.totalWithDiscount) {
        setCartItem({...cartItem, total, totalWithDiscount});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItem]);

  useEffect(() => {
    if (!dialogProps.open) {
      setIsProductImageDialogOpen(false);
    }
  }, [dialogProps.open]);

  const validateCartItem = (cartItem: CartItemForm): boolean => {
    const modifierGroupForms = cartItem.modifierGroups;
    let hasError = false;

    for (const modifierGroup of cartItem.product?.modifierGroups ?? []) {
      const modifierGroupForm = modifierGroupForms.find(({modifierGroupId}) => modifierGroupId === modifierGroup.id);

      if (modifierGroup.minimumRequired > 0) {
        if (!modifierGroupForm) {
          // Validate minimum (no modifiers selected)
          const itemText = modifierGroup.minimumRequired === 1 ? 'item' : 'items';
          modifierGroupForms.push({
            modifierGroupId: modifierGroup.id,
            modifiers: [],
            validationError: `At least ${modifierGroup.minimumRequired} ${itemText} must be selected`,
          });
          hasError = true;
        } else if (modifierGroupForm.modifiers.length < modifierGroup.minimumRequired) {
          // Validate minimum (modifiers not sufficient)
          const itemText = modifierGroup.minimumRequired === 1 ? 'item' : 'items';
          modifierGroupForm.validationError = `At least ${modifierGroup.minimumRequired} ${itemText} must be selected`;
          hasError = true;
        }
      }

      // Validate maximum
      if (
        modifierGroupForm &&
        modifierGroup.maximumAllowed &&
        modifierGroupForm.modifiers.length > modifierGroup.maximumAllowed
      ) {
        const itemText = modifierGroup.maximumAllowed === 1 ? 'item' : 'items';
        modifierGroupForm.validationError = `Up to ${modifierGroup.maximumAllowed} ${itemText} can be selected`;
        hasError = true;
      }
    }

    setCartItem({...cartItem, modifierGroups: modifierGroupForms});

    return hasError;
  };

  const onAddProductClick = () => {
    const freeItemsCount =
      cart?.items?.reduce((freeItemsCount, item) => (item.total === 0 ? freeItemsCount + item.quantity : freeItemsCount), 0) ?? 0;
    if (
      productQuery.data?.price === 0 &&
      location?.section.freeItemsLimit !== null &&
      freeItemsCount >= location?.section.freeItemsLimit!
    ) {
      alert(`Product could not be added. You've exceeded the maximum amount of complimentary items.`);
      return;
    }

    const hasError = validateCartItem(cartItem);

    if (!hasError) {
      cartReducer.addItem({
        product: {...cartItem.product!, priceWithDiscount},
        quantity: cartItem.quantity,
        total: cartItem.total,
        totalWithDiscount: cartItem.totalWithDiscount,
        customerNotes: cartItem.customerNotes,
        modifierGroups: cartItem.modifierGroups,
        addedInUpselling: isUpsellingContext,
        menuCategoryProductId: cartItem.menuCategoryProductId!,
      });

      dialogProps.onOpenChange?.(false);
    }
  };

  const getTotal = (cartItem: CartItemForm): {total: number; totalWithDiscount?: number} => {
    let total = cartItem.product?.price! * cartItem.quantity;
    if (pricesIncludeTax) {
      total = (cartItem.product?.price! + cartItem.product?.taxValue!) * cartItem.quantity;
    }
    let totalWithDiscount = applyingPromotion && priceWithDiscount ? priceWithDiscount * cartItem.quantity : undefined;

    for (const modifierGroup of cartItem.product?.modifierGroups ?? []) {
      for (const modifier of modifierGroup.modifiers) {
        if (isChecked(modifierGroup.id, modifier.id)) {
          if (pricesIncludeTax) {
            total += (modifier.price + modifier.taxValue) * cartItem.quantity;
          } else {
            total += modifier.price * cartItem.quantity;
          }

          if (totalWithDiscount) {
            totalWithDiscount += applyingPromotion!.includesModifiers ? 0 : modifier.price * cartItem.quantity;
          }
        }
      }
    }

    return {total, totalWithDiscount};
  };

  const setModifier = (modifierGroupId: number, modifierId: number, checked: boolean, isRadio: boolean = false) => {
    const modifierGroup = cartItem.modifierGroups.find(({modifierGroupId: id}) => id === modifierGroupId);

    if (modifierGroup) {
      modifierGroup.validationError = '';

      if (checked) {
        modifierGroup.modifiers = isRadio
          ? [{modifierId}]
          : modifierGroup.modifiers.filter(({modifierId: id}) => id !== modifierId).concat([{modifierId}]);
      } else {
        modifierGroup.modifiers = modifierGroup.modifiers.filter(({modifierId: id}) => id !== modifierId);
      }

      setCartItem({
        ...cartItem,
        modifierGroups: cartItem.modifierGroups.filter(({modifierGroupId: id}) => id !== modifierGroupId).concat([modifierGroup]),
      });
    } else {
      const modifierGroup: CartModifierGroupForm = {
        modifierGroupId,
        validationError: '',
        modifiers: checked ? [{modifierId}] : [],
      };

      setCartItem({
        ...cartItem,
        modifierGroups: cartItem.modifierGroups.concat([modifierGroup]),
      });
    }
  };

  const isChecked = (modifierGroupId: number, modifierId: number): boolean => {
    const modifierGroup = cartItem.modifierGroups.find(({modifierGroupId: id}) => id === modifierGroupId);
    const modifier = modifierGroup?.modifiers.find(({modifierId: id}) => id === modifierId);

    return !!modifier;
  };

  const isDisabled = (modifierGroupId: number, modifierId: number, maximumAllowed: number | null): boolean => {
    if (!maximumAllowed) {
      return false;
    }

    const modifierGroup = cartItem.modifierGroups.find(({modifierGroupId: id}) => id === modifierGroupId);

    if (modifierGroup) {
      const modifier = modifierGroup.modifiers.find(({modifierId: id}) => id === modifierId);

      return !modifier && modifierGroup.modifiers.length >= maximumAllowed;
    }

    return false;
  };

  const getValidationError = (modifierGroupId: number): string => {
    const modifierGroup = cartItem.modifierGroups.find(({modifierGroupId: id}) => id === modifierGroupId);

    return modifierGroup?.validationError ?? '';
  };

  const product = productQuery.data;
  let productPrice = product?.price;

  if (pricesIncludeTax) {
    productPrice = (product?.price ?? 0) + (product?.taxValue ?? 0);
  }

  return (
    <Dialog {...dialogProps}>
      <DialogContent className={cn('!tw-p-0 no-scrollbar', productQuery.isLoading && 'tw-overflow-y-hidden')}>
        {productQuery.isLoading ? (
          <ProductDialogPlacheolder />
        ) : (
          <>
            <DialogHeader className="tw-items-start tw-border-b tw-bg-white tw-border-gray-100">
              {!!product?.images?.[0]?.url && (
                <AspectRatio ratio={16 / 9}>
                  <img
                    className="tw-h-full tw-w-full tw-object-cover sm:tw-rounded-t-lg tw-cursor-pointer"
                    src={product?.images[0].url}
                    alt={product?.name}
                    onClick={() => setIsProductImageDialogOpen(true)}
                  />
                </AspectRatio>
              )}

              <div className="tw-p-4 tw-w-full">
                <h1 className="tw-text-xl sm:tw-text-2xl tw-font-bold">{product?.name}</h1>
                <p className="tw-text-sm tw-text-gray-500">{product?.description}</p>
                <h4 className="tw-mt-2 tw-font-bold tw-text-base tw-mb-2">
                  {priceWithDiscount != null ? (
                    <ProductPrice
                      price={productPrice ?? 0}
                      pricesIncludeTax={pricesIncludeTax}
                      priceWithDiscount={priceWithDiscount}
                      taxValue={product?.taxValue ?? 0}
                    />
                  ) : (
                    <span>
                      {getPriceText(productPrice ?? 0)}
                      {pricesIncludeTax && <Badge text="Tax incl." />}
                    </span>
                  )}
                </h4>
                {product?.attributes && product?.attributes.length > 0 && (
                  <div className="tw-flex tw-flex-wrap tw-gap-1">
                    {product?.attributes.map(({id, name}) => (
                      <ProductAtributte name={name} key={id} />
                    ))}
                  </div>
                )}
                {!!product?.needsAgeVerification && (
                  <div className="tw-mt-2">
                    <AgeVerificationAlert />
                  </div>
                )}
              </div>
            </DialogHeader>

            <div className="tw-px-6 tw-py-2">
              <div className="">
                {/* Modifiers */}
                {product?.modifierGroups.map((modifierGroup) => {
                  const isRadio = modifierGroup.minimumRequired <= 1 && modifierGroup.maximumAllowed === 1;
                  return (
                    <div className="tw-text-gray-900 tw-py-4" key={modifierGroup.id}>
                      {/* Modifier group title */}
                      <div className="tw-mb-2">
                        <p className="tw-font-bold tw-text-base tw-mr-2">{modifierGroup.name}</p>
                        {!!modifierGroup.description && (
                          <p className="tw-text-gray-500 tw-text-xs">{modifierGroup.description}</p>
                        )}
                      </div>
                      {!!getValidationError(modifierGroup.id) && (
                        <div className="kl-callout kl-callout-warning modifier-group-error">
                          <Icon icon="warning-sign" />
                          <div className="kl-callout-body">{getValidationError(modifierGroup.id)}</div>
                        </div>
                      )}
                      <ul className="tw-divide-y">
                        {modifierGroup.modifiers.map((modifier) => {
                          let modifierPrice = modifier.price;
                          if (pricesIncludeTax) {
                            modifierPrice = modifier.price + modifier.taxValue;
                          }
                          return (
                            <li key={modifier.id}>
                              <label className="tw-flex tw-items-center tw-justify-between tw-cursor-pointer tw-py-1 hover:tw-bg-gray-50">
                                <div className="tw-flex tw-flex-1 tw-items-center">
                                  {!viewOnly && (
                                    // Render checkbox or radio
                                    <div className="-tw-ml-2">
                                      {isRadio ? (
                                        <Radio
                                          id={`modifier-${modifierGroup.id}-${modifier.id}`}
                                          size="small"
                                          checked={isChecked(modifierGroup.id, modifier.id)}
                                          onChange={({currentTarget: {checked}}) =>
                                            setModifier(modifierGroup.id, modifier.id, checked, true)
                                          }
                                        />
                                      ) : (
                                        <Checkbox
                                          id={`modifier-${modifierGroup.id}-${modifier.id}`}
                                          size="small"
                                          checked={isChecked(modifierGroup.id, modifier.id)}
                                          onChange={({currentTarget: {checked}}) =>
                                            setModifier(modifierGroup.id, modifier.id, checked)
                                          }
                                          disabled={isDisabled(modifierGroup.id, modifier.id, modifierGroup.maximumAllowed)}
                                        />
                                      )}
                                    </div>
                                  )}
                                  <span className="tw-font-medium tw-text-sm">{modifier.name}</span>
                                </div>
                                {modifierPrice > 0 ? (
                                  <span className="tw-flex tw-items-center tw-font-medium tw-text-sm">
                                    +
                                    {applyingPromotion?.includesModifiers ? (
                                      <>
                                        <span className="old-price">{getPriceText(modifierPrice)}</span>
                                        <span className="price-with-discount">{getPriceText(0)}</span>
                                      </>
                                    ) : (
                                      getPriceText(modifierPrice)
                                    )}
                                  </span>
                                ) : null}
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}

                {/* Notes */}
                {!viewOnly && (
                  <div className="tw-my-4">
                    <div className="tw-mb-2">
                      <Label htmlFor="customer-notes">Add special instructions</Label>
                    </div>
                    <Textarea
                      id="customer-notes"
                      rows={4}
                      maxLength={120}
                      value={cartItem.customerNotes}
                      onChange={({target: {value}}) => setCartItem({...cartItem, customerNotes: value})}
                    />
                  </div>
                )}

                {/* Image dialog */}
                {!!product?.images?.[0]?.url && (
                  <ProductImageDialog
                    open={isProductImageDialogOpen}
                    productImageUrl={product.images[0].url}
                    productName={product?.name}
                    onOpenChange={(isOpen) => setIsProductImageDialogOpen(isOpen)}
                  />
                )}
              </div>
            </div>

            {!viewOnly && (
              <DialogFooter className="tw-sticky tw-p-2 tw-pt-2 tw-bottom-0 tw-border-t tw-bg-white">
                <div className="tw-flex tw-items-center tw-justify-center tw-gap-6">
                  <QuantityInput
                    allowDelete={false}
                    value={cartItem.quantity}
                    onChange={(value) => setCartItem({...cartItem, quantity: value})}
                  />

                  <Button
                    size="lg"
                    className="tw-font-bold"
                    disabled={productQuery.isLoading || !productId || !menuCategoryProductId}
                    onClick={onAddProductClick}
                  >
                    Add to cart ({getPriceText(cartItem.totalWithDiscount ?? cartItem.total)})
                  </Button>
                </div>
              </DialogFooter>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

function AgeVerificationAlert() {
  return (
    <div className="tw-bg-yellow-50 tw-p-3 tw-rounded tw-border tw-border-yellow-400">
      <div className="tw-flex tw-items-center">
        <div className="tw-shrink-0">
          <ExclamationTriangleIcon className="tw-h-5 tw-w-5 tw-text-yellow-400" aria-hidden="true" />
        </div>
        <div className="tw-ml-3 tw-text-sm">
          <p className="tw-font-medium tw-text-yellow-700">Age verification required</p>
          <span className=" tw-text-yellow-700 ">Your ID will be requested when receiving the order</span>
        </div>
      </div>
    </div>
  );
}
