import React from 'react';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';

import {useAppContext} from 'contexts/app.context';

import {Label} from 'common/components/Label';
import {Input} from 'common/components/Input';
import {Textarea} from 'common/components/Textarea';

export interface PickupDetailsFormFields {
  pickupName: string;
  pickupInfo: string;
}

interface PickupDetailsFormProps extends React.HTMLProps<HTMLFormElement> {
  onSubmitted?: () => void;
}

export const PickupDetailsForm = React.forwardRef<HTMLFormElement, PickupDetailsFormProps>(({onSubmitted, ...formProps}, ref) => {
  const {cart, cartReducer} = useAppContext();

  const {formState, handleSubmit, reset, register} = useForm<PickupDetailsFormFields>({
    defaultValues: {pickupName: cart?.pickupName, pickupInfo: cart?.pickupInfo},
  });

  const onSubmit = (pickupDetailsFormData: PickupDetailsFormFields) => {
    const {pickupName, pickupInfo} = pickupDetailsFormData;
    cartReducer.setPickupDetails(pickupName, pickupInfo);

    onSubmitted?.();
  };

  const onReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    reset({pickupName: cart?.pickupName, pickupInfo: cart?.pickupInfo});
  };

  return (
    <form ref={ref} onReset={onReset} onSubmit={handleSubmit(onSubmit)} {...formProps}>
      <div className={classNames('kl-input-group', {'kl-input-group-danger': formState.errors.pickupName})}>
        <Label htmlFor="pickupName">Name</Label>
        <div className="tw-mt-1">
          <Input autoFocus id="pickupName" type="text" {...register('pickupName', {required: true})} />
        </div>
        {formState.errors.pickupName ? <p className="kl-input-helper">Name is required</p> : null}
      </div>

      <div className={classNames('kl-input-group', {'kl-input-group-danger': formState.errors.pickupInfo})}>
        <Label htmlFor="pickupInfo">Additional info</Label>
        <div className="tw-mt-1">
          <Textarea rows={3} id="pickupInfo" {...register('pickupInfo')} />
        </div>
      </div>
    </form>
  );
});

PickupDetailsForm.displayName = 'PickupDetailsForm';
