import {PaymentGateway, PaymentMethod} from 'models/payment-method.model';

import {CartPayment} from 'models/cart.model';

export function buildWalletPaymentMethod(paymentGateway: PaymentGateway, cardBrand: string) {
  return {
    stripePaymentMethodId: paymentGateway === 'stripe' ? 'wallet' : undefined,
    freedompayToken: paymentGateway === 'freedompay' ? 'wallet' : undefined,
    cardBrand,
    cardCountry: 'US', // TODO: This might have to be gathered
    cardExpirationYear: 0,
    cardExpirationMonth: 0,
    cardLastFourDigits: '0000',
    cardholderName: '',
    isWallet: true,
  };
}

export function findPaymentMethod(paymentMethods: PaymentMethod[], paymentMethodId: string) {
  return paymentMethods.find(
    (paymentMethod) =>
      paymentMethod.stripePaymentMethodId === paymentMethodId || paymentMethod.freedompayToken === paymentMethodId
  );
}

export function getPaymentMethodActiveTab(payment?: CartPayment, roomGuestLastName?: string, roomNumber?: string) {
  if (!payment && (roomGuestLastName || roomNumber)) {
    return 'room-charge';
  }

  return 'card';
}
