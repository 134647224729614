interface ProductAtributteProps {
  name: string;
}
export function ProductAtributte({name}: ProductAtributteProps) {
  return (
    <span className="tw-inline-flex tw-items-center tw-rounded-full tw-bg-green-100 tw-px-1.5 tw-py-0.5 tw-text-xs sm:tw-text-xs tw-text-green-700">
      {name}
    </span>
  );
}
