import {callApi} from 'api/api';
import {Event} from 'models/event.model';

export const fetchEventsRequest = async (storeId: number): Promise<Event[]> => {
  return callApi<Event[]>(`/events`, {params: {storeId}}).then(({data}) => data);
};

export const fetchEventRequest = async (eventId: number, token?: string): Promise<Event> => {
  return callApi<Event>(`/events/${eventId}`, {token}).then(({data}) => data);
};
