import {OrderSummary, OrderSummaryItem} from 'models/order.model';
import {PaymentGateway, PaymentMethod} from 'models/payment-method.model';

import {Currency} from 'utils/types';
import {callApi} from './api';

export const fetchPaymentMethods = async (token: string, paymentGateway: PaymentGateway): Promise<PaymentMethod[]> => {
  return callApi<PaymentMethod[]>(`/payments/payment-methods`, {token, params: {paymentGateway}}).then(({data}) => data);
};

export const deletePaymentMethod = async (
  token: string,
  paymentGateway: string,
  paymentMethodId: string
): Promise<PaymentMethod> => {
  return callApi<PaymentMethod>(`/payments/payment-methods/${paymentMethodId}`, {
    token,
    method: 'DELETE',
    params: {paymentGateway},
  }).then(({data}) => data);
};

export interface SaveStripePaymentMethodRequest {
  paymentMethodId: string;
}

export const saveStripePaymentMethod = async (token: string, payload: SaveStripePaymentMethodRequest): Promise<PaymentMethod> => {
  return callApi<PaymentMethod>(`/payments/stripe/payment-methods`, {
    token,
    method: 'POST',
    body: JSON.stringify(payload),
  }).then(({data}) => data);
};

interface CreateStripeWalletPaymentIntentRequest {
  amount: number;
  currency: Currency;
  fee: number;
  storeId: number;
}

export const createStripeWalletPaymentIntent = async (
  token: string,
  payload: CreateStripeWalletPaymentIntentRequest
): Promise<PaymentMethod> => {
  return callApi<PaymentMethod>(`/payments/stripe/wallet-payment-intent`, {
    token,
    method: 'POST',
    body: JSON.stringify(payload),
  }).then(({data}) => data);
};

// Freedompay
export interface CreateFreedompayPaymentMethodIframeRequest {
  iframeStyles: string;
  storeId: number;
}

export const createFreedompayPaymentMethodIframe = async (
  token: string,
  payload: CreateFreedompayPaymentMethodIframeRequest
): Promise<any> => {
  return callApi<any>(`/payments/freedompay/iframes/payment-method-form`, {
    token,
    method: 'POST',
    body: JSON.stringify(payload),
  }).then(({data}) => data);
};

export interface SaveFreedompayPaymentMethodRequest {
  sessionKey: string;
  paymentKey: string;
  cardBrand: string;
  cardholderName: string;
  storeId: number;
}

export const saveFreedompayPaymentMethod = async (
  token: string,
  payload: SaveFreedompayPaymentMethodRequest
): Promise<PaymentMethod> => {
  return callApi<PaymentMethod>(`/payments/freedompay/payment-methods`, {
    token,
    method: 'POST',
    body: JSON.stringify(payload),
  }).then(({data}) => data);
};

export interface FetchFreedompayWalletPaymentButtonIframeRequest {
  walletBrand: 'google-pay' | 'apple-pay';
  amount: number;
  storeId: number;
}

export const fetchFreedompayWalletPaymentButtonIframe = async (
  token: string,
  payload: FetchFreedompayWalletPaymentButtonIframeRequest
): Promise<any> => {
  return callApi<PaymentMethod>(`/payments/freedompay/iframes/wallet-payment-button`, {
    token,
    method: 'POST',
    body: JSON.stringify(payload),
  }).then(({data}) => data);
};

export interface CreateFreedompayWalletPaymentIntentRequest {
  paymentKey: string;
  sessionKey: string;
  cardholderName: string;
  orderItems: OrderSummaryItem[];
  orderTotals: Pick<OrderSummary, 'total' | 'discountsSubtotal' | 'feesSubtotal' | 'surchargesSubtotal' | 'tipsSubtotal' | 'tax'>;
  storeId: number;
}

export interface FreedompayPaymentIntentData {
  requestId: string;
  invoiceNumber: string;
  merchantReferenceCode: string;
}

export const createFreedompayWalletPaymentIntent = async (
  token: string,
  payload: CreateFreedompayWalletPaymentIntentRequest
): Promise<FreedompayPaymentIntentData> => {
  return callApi<FreedompayPaymentIntentData>(`/payments/freedompay/wallet-payment-intent`, {
    token,
    method: 'POST',
    body: JSON.stringify(payload),
  }).then(({data}) => data);
};
