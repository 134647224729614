import * as React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {cn} from 'common/utils';

const Dialog = DialogPrimitive.Root;

export type DialogProps = DialogPrimitive.DialogProps;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = ({className, children, ...props}: DialogPrimitive.DialogPortalProps) => (
  <DialogPrimitive.Portal className={cn(className)} {...props}>
    <div className="tw-fixed tw-inset-0 tw-z-50 tw-flex tw-items-start tw-justify-center sm:tw-items-center">{children}</div>
  </DialogPrimitive.Portal>
);
DialogPortal.displayName = DialogPrimitive.Portal.displayName;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({className, children, ...props}, ref) => (
  <DialogPrimitive.Overlay
    className={cn(
      'tw-fixed tw-inset-0 tw-z-50 tw-bg-black/50 tw-backdrop-blur-sm tw-transition-all tw-duration-200 data-[state=closed]:tw-animate-out data-[state=open]:tw-fade-in data-[state=closed]:tw-fade-out',
      className
    )}
    {...props}
    ref={ref}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({className, children, ...props}, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        'tw-fixed tw-z-50 tw-flex tw-flex-col tw-w-full tw-overflow-y-auto tw-h-full sm:h-auto sm:tw-max-h-[95vh] tw-bg-white tw-p-6 tw-animate-in data-[state=open]:tw-fade-in-90 data-[state=open]:tw-slide-in-from-bottom-full tw-duration-200 tw-ease-in-out sm:tw-max-w-lg sm:tw-rounded-lg sm:tw-zoom-in-90 data-[state=open]:sm:tw-slide-in-from-bottom-0',
        'dark:bg-slate-900',
        className
      )}
      {...props}
    >
      {children}
      <DialogPrimitive.Close className="tw-bg-white tw-absolute tw-top-4 tw-right-4 tw-rounded-full tw-p-1 tw-transition-opacity focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-slate-400 focus:tw-ring-offset-2 disabled:tw-pointer-events-none data-[state=open]:tw-bg-slate-100 dark:focus:tw-ring-slate-400 dark:focus:tw-ring-offset-slate-900 dark:data-[state=open]:tw-bg-slate-800">
        <XMarkIcon className="tw-h-5 tw-w-5" />
        <span className="tw-sr-only">Close</span>
      </DialogPrimitive.Close>
    </DialogPrimitive.Content>
  </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({className, ...props}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('tw-flex tw-flex-col', className)} {...props} />
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({className, ...props}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('tw-flex tw-mt-auto tw-flex-col sm:tw-justify-end', className)} {...props} />
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({className, ...props}, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn('tw-text-lg tw-font-semibold tw-text-slate-900', 'dark:text-slate-50', className)}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({className, ...props}, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn('tw-text-sm tw-text-slate-500 tw-text-center', 'dark:text-slate-400', className)}
    {...props}
  />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {Dialog, DialogTrigger, DialogContent, DialogHeader, DialogFooter, DialogTitle, DialogDescription};
