import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
} from 'common/components/Dialog';
import {Label} from 'common/components/Label';
import {Input} from 'common/components/Input';
import {Button} from 'common/components/Button';
import {useForm} from 'react-hook-form';
import {Tip} from 'checkout/components/TipSelector';

export interface CustomTipFormFields {
  customTip: number;
}

interface CustomTipDialogProps extends DialogProps {
  onChange: (tip: Tip) => void;
  orderAmount: number;
  onCloseDialog: () => void;
}

export default function CustomTipDialog({orderAmount, onChange, onCloseDialog, ...dialogProps}: CustomTipDialogProps) {
  const {register, handleSubmit} = useForm<CustomTipFormFields>();

  const onSaveCustomTip = (customTipFormData: CustomTipFormFields) => {
    const {customTip} = customTipFormData;
    const tipPercentaje = (customTip * 100) / orderAmount;
    onChange({value: tipPercentaje / 100, type: 'custom'});
    onCloseDialog();
  };

  return (
    <Dialog {...dialogProps}>
      <DialogContent className="tw-h-full">
        <DialogHeader className="tw-items-center tw-mb-10">
          <DialogTitle>Add custom tip</DialogTitle>
          <DialogDescription>Your order subtotal is ${orderAmount}</DialogDescription>
        </DialogHeader>
        <div className="tw-mb-10">
          <form onSubmit={handleSubmit(onSaveCustomTip)} id="custom-tip-form">
            <Label className="tw-mb-1">Enter tip amount</Label>
            <div className="tw-relative tw-mt-2 tw-rounded-md tw-shadow-sm">
              <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3">
                <span className="tw-text-gray-500 sm:tw-text-sm">$</span>
              </div>
              <Input
                autoFocus
                type="number"
                required
                placeholder="0.00"
                min={0}
                {...register('customTip')}
                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-pl-7 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
              />
            </div>
          </form>
        </div>

        <DialogFooter className="tw-justify-end">
          <Button type="submit" form="custom-tip-form">
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
