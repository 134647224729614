import * as React from 'react';
import {VariantProps, cva} from 'class-variance-authority';
import {cn, tw} from 'common/utils';

const buttonVariants = cva(
  tw`tw-inline-flex tw-items-center tw-border tw-border-transparent tw-whitespace-nowrap tw-justify-center tw-font-medium tw-transition-colors focus:tw-outline-none focus:tw-ring-2  focus:tw-ring-offset-2 disabled:tw-opacity-20  disabled:tw-pointer-events-none data-[state=open]:tw-bg-blue-100`,
  {
    variants: {
      variant: {
        default: tw`tw-bg-blue-700 tw-text-white hover:tw-bg-blue-500 focus:tw-ring-blue-600`,
        accent: tw`tw-bg-rose-500 tw-text-white hover:tw-bg-rose-400 focus:tw-ring-rose-500`,
        destructive: tw`tw-bg-red-500 tw-text-white hover:tw-bg-red-600 focus:tw-ring-red-500`,
        warning: tw`tw-bg-yellow-500 tw-text-white hover:tw-bg-yellow-600 focus:tw-ring-yellow-500`,
        outline: tw`tw-bg-transparent tw-border tw-border-blue-200 hover:bg-blue-100`,
        subtle: tw`tw-bg-slate-100 tw-text-slate-700 hover:tw-bg-slate-300 focus:tw-ring-blue-600`,
        ghost: tw`tw-bg-transparent tw-text-blue-800 hover:tw-bg-blue-200 tw-data-[state=open]:bg-transparent`,
        link: tw`tw-bg-transparent tw-underline-offset-4 hover:tw-underline tw-text-blue-800 hover:tw-bg-transparent`,
      },
      rounded: {
        true: tw`tw-rounded-full`,
        false: tw`tw-rounded-md`,
      },
      size: {
        xs: tw`tw-px-2.5 tw-py-1.5 tw-text-xs`,
        sm: tw`tw-px-3 tw-py-2 tw-text-sm`,
        md: tw`tw-px-4 tw-py-2 tw-text-sm`,
        lg: tw`tw-px-4 tw-py-2 tw-text-base`,
        xl: tw`tw-px-6 tw-py-3 tw-text-base`,
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'md',
      rounded: false,
    },
  }
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({className, variant, size, rounded, ...props}, ref) => {
  return <button className={cn(buttonVariants({variant, size, rounded}), className)} ref={ref} {...props} />;
});

Button.displayName = 'Button';

export {Button, buttonVariants};
