import React from 'react';

// NOTE: Copied from https://www.freecodecamp.org/news/make-react-apps-responsive/

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  });

  function changeWindowSize() {
    setWindowSize({windowWidth: window.innerWidth, windowHeight: window.innerHeight});
  }

  React.useEffect(() => {
    window.addEventListener('resize', changeWindowSize);

    return () => {
      window.removeEventListener('resize', changeWindowSize);
    };
  }, []);

  return windowSize;
};
