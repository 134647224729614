import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import ReactDOM from 'react-dom';
import {AppWrapper} from 'app-wrapper';
import {isSentryEnabled} from 'utils/helpers.utils';
import {App} from 'app';
import * as Sentry from '@sentry/react';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

library.add(fab);

import './index.scss';

if ((module as any).hot && process.env.NODE_ENV === 'development') {
  (module as any).hot.accept();
}

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.NODE_ENV,
  enabled: isSentryEnabled(),
});

ReactDOM.render(
  <BrowserRouter basename="customer">
    <AppWrapper>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!} scriptProps={{async: true, defer: true}}>
        <App />
      </GoogleReCaptchaProvider>
    </AppWrapper>
  </BrowserRouter>,
  document.getElementById('root')
);
