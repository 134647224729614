import {Location} from 'models/location.model';
import {ApiError, callApi} from 'api/api';

import {initialCart, useAppContext} from 'contexts/app.context';

import {useQuery} from 'react-query';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {slugify} from 'utils/helpers.utils';
import {paths} from 'utils/paths';

export const fetchLocationRequest = async (qrCode: string, token?: string, isScan?: boolean): Promise<Location> => {
  return callApi<Location>(`/locations/${qrCode}`, {token, params: isScan ? {isScan: '1'} : undefined}).then(({data}) => data);
};

export const useLocationQuery = (qrCode?: string, isScan?: boolean) => {
  const {setContextState, location: currentLocation, cart: currentCart, customer: currentCustomer} = useAppContext();
  const history = useHistory();
  const {path} = useRouteMatch();

  return useQuery<Location, ApiError<{location: Location}>>(
    ['location', qrCode],
    () => fetchLocationRequest(qrCode!, currentCustomer?.token, isScan),
    {
      onSuccess: (newLocation) => {
        setContextState({
          location: newLocation,
          event: undefined,
          store: undefined,
          navbar: {
            title: newLocation.section?.store?.name ?? '',
            subtitle: newLocation.name,
            logoUrl:
              (newLocation.section?.imageUrl ||
                newLocation.section?.store?.imageUrl ||
                newLocation.section?.store?.company?.imageUrl) ??
              '',
          },
          paymentGateway: newLocation.section.store?.settings?.paymentGateway,
          // If loading a new location, reset cart
          cart: newLocation?.qrCode !== currentLocation?.qrCode ? initialCart : currentCart,
        });

        if (([paths.qrScanner.route, paths.qrResolver.route] as string[]).includes(path)) {
          history.replace(paths.location.generatePath(slugify(newLocation.section.store?.name || '')));
        }
      },
      onError: (error) => {
        switch (error.error?.message) {
          case 'store_closed': {
            const location = error.data!.location;
            const storeName = slugify(location.section.store?.name || '');
            setContextState({
              location,
              event: undefined,
              store: undefined,
              navbar: {
                title: location.section?.store?.name ?? '',
                subtitle: location.name,
                logoUrl:
                  (location.section?.imageUrl ||
                    location.section?.store?.imageUrl ||
                    location.section?.store?.company?.imageUrl) ??
                  '',
              },
            });
            history.replace(paths.storeOpeningHours.generatePath(storeName));
            break;
          }
          case 'section_closed': {
            const location = error.data!.location;
            const storeName = slugify(location.section.store?.name || '');
            const sectionName = slugify(location.section.name);
            setContextState({
              location,
              event: undefined,
              store: undefined,
              navbar: {
                title: location.section?.store?.name ?? '',
                subtitle: location.name,
                logoUrl:
                  (location.section?.imageUrl ||
                    location.section?.store?.imageUrl ||
                    location.section?.store?.company?.imageUrl) ??
                  '',
              },
            });
            history.replace(paths.sectionOpeningHours.generatePath(storeName, sectionName));
            break;
          }
          case 'location_not_found': {
            history.replace(paths.notFound.generatePath());
            break;
          }
          default:
            // NOTE: In the future we may want to redirect to an error page
            history.replace(paths.notFound.generatePath());
            break;
        }
      },
      enabled: !!qrCode,
      initialData: currentLocation,
      // staleTime: 30 * 5,
    }
  );
};
