/**
 * This component was created based on @blueprintjs/core/icon
 * 
 * Want to add a new icon? 
 * Copy the SVG path and icon name from "node_modules\@blueprintjs\icons\lib\esm\generated\iconSvgPaths.js"
 * and paste it in icon-paths.ts. Make sure you copy it from the "IconSvgPaths20" object.
 * */
import React from 'react';
import classNames from 'classnames';
import { IconName, SvgPaths } from './icon-paths';

export interface IconProps extends Omit<React.HTMLAttributes<HTMLElement>, 'title'> {
  children?: never;
  color?: string;
  htmlTitle?: string;
  icon: IconName;
  iconSize?: number;
  title?: string | false | null;
  tagName?: keyof JSX.IntrinsicElements;
}

export const Icon: React.FC<IconProps> = ({
  icon,
  className,
  children,
  htmlTitle,
  iconSize = 16,
  title,
  tagName,
  color,
  ...htmlProps
}) => {
  if (icon == null || typeof icon === 'boolean') {
    return null;
  } else if (typeof icon !== 'string') {
    return icon;
  }

  if (!tagName) {
    tagName = 'span';
  }

  return React.createElement(
    tagName,
    {
      ...htmlProps,
      className: classNames('kl-icon', icon, className),
      title: htmlTitle,
    },
    <svg fill={color} data-icon={icon} width={iconSize} height={iconSize} viewBox="0 0 20 20">
      {title && <desc>{title}</desc>}
      {renderSvgPaths(icon)}
    </svg>
  );
};

const renderSvgPaths = (iconName: IconName): JSX.Element[] | null => {
  const svgPath = SvgPaths[iconName];
  if (svgPath == null) {
    return null;
  }
  return svgPath.map((d, i) => <path key={i} d={d} fillRule="evenodd" />);
};
