import {AspectRatio} from '@radix-ui/react-aspect-ratio';
import {useAppContext} from 'contexts/app.context';
import {Product, ProductVersion} from 'models/product.model';

import {getApplyingPromotion, getPriceWithDiscount} from 'utils/helpers.utils';

import ProductPrice from './ProductPrice';
import {QuantityInput} from './QuantityInput';

interface OrderItemProps {
  product: Product | ProductVersion;
  itemModifiers: {name: string; price: number}[];
  itemQuantity: number;
  itemIndex: number;
  menuCategoryProductId?: number;
  pricesIncludeTax?: boolean;
  isQuantityEditable?: boolean;
}

export default function OrderItem({
  product,
  itemModifiers,
  itemQuantity,
  itemIndex,
  menuCategoryProductId,
  pricesIncludeTax,
  isQuantityEditable = true,
}: OrderItemProps) {
  const {cartReducer, location} = useAppContext();
  const applyingPromotion = menuCategoryProductId
    ? getApplyingPromotion(location?.section.discounts ?? [], menuCategoryProductId)
    : undefined;

  const modifiersPrice = itemModifiers.reduce((acc, modifier) => acc + modifier.price, 0);
  const priceWithDiscount = product && applyingPromotion ? getPriceWithDiscount(product, applyingPromotion) : undefined;

  let productPrice = product?.price + modifiersPrice;
  if (pricesIncludeTax) {
    productPrice = (product?.price + modifiersPrice ?? 0) + (product?.taxValue ?? 0);
  }

  if (!product) {
    return null;
  }

  return (
    <div className="tw-flex tw-h-24 tw-items-center">
      {/* Image */}
      {!!product.images && !!product.images[0] && (
        <div className="tw-w-16 tw-shrink-0 tw-mr-2">
          <AspectRatio ratio={1}>
            <img className="tw-w-full tw-h-full tw-object-cover tw-rounded-md" src={product.images[0].url} />
          </AspectRatio>
        </div>
      )}
      <div className="tw-flex tw-h-full tw-w-full tw-gap-2 tw-items-center tw-pl-2 tw-space-y-0.5 tw-overflow-hidden">
        <div className="tw-overflow-hidden">
          <p className="tw-text-base tw-truncate tw-font-bold">{product.name}</p>
          <p className="tw-text-sm tw-text-gray-500 tw-line-clamp-2">
            {itemModifiers.map((modifier) => modifier.name).join(', ')}
          </p>
          <ProductPrice
            price={productPrice}
            pricesIncludeTax={location?.pricesIncludeTax}
            priceWithDiscount={priceWithDiscount}
            taxValue={product.taxValue}
          />
        </div>

        <div className="tw-flex tw-flex-col tw-items-end tw-shrink-0 tw-justify-between tw-ml-auto">
          {isQuantityEditable ? (
            <QuantityInput value={itemQuantity} onChange={(value) => cartReducer.editProductQuantity(itemIndex, value)} />
          ) : (
            <span className="tw-items-center tw-rounded-full tw-bg-gray-100 tw-px-3 tw-py-0.5 tw-text-sm tw-font-medium tw-text-gray-800">
              x {itemQuantity}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
