import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

// This hook creates a scroll stack in order to preserve
// the scroll position while the users navigate the app

export const useScrollStack = () => {
  const [scrollStack, setScrollStack] = useState<number[]>([]);
  const history = useHistory<{scroll: number} | undefined>();

  React.useEffect(() => {
    // Listen for location changes and set the scroll position accordingly.
    const unregister = history.listen((location, action) => {
      const root = document.getElementById('root');

      switch (action) {
        case 'PUSH':
          setScrollStack(scrollStack.concat(root?.scrollTop || 0));
          break;
        case 'POP':
          if (scrollStack[scrollStack.length - 1] != null) {
            root?.scrollTo(0, scrollStack[scrollStack.length - 1]);
            setScrollStack(scrollStack.slice(0, -1));
          }
          break;
      }
    });

    // Unregister listener when component unmounts.
    return () => {
      unregister();
    };
  }, [history, scrollStack]);
};
