import React from 'react';

import {ReactComponent as AsapPickupIcon} from 'assets/icons/asap-pickup.icon.svg';

import {Button} from 'common/components/Button';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
} from 'common/components/Dialog';

import {PickupDetailsForm} from './PickupDetailsForm';

interface PickupDetailsDialogProps extends DialogProps {
  onContinue?: () => void;
}

export function PickupDetailsDialog({onContinue, ...dialogProps}: PickupDetailsDialogProps) {
  return (
    <Dialog {...dialogProps}>
      <DialogContent>
        <DialogHeader className="tw-items-center tw-mb-6">
          <div className="tw-pt-4 tw-pb-6">
            <AsapPickupIcon />
          </div>
          <DialogTitle>Pickup details</DialogTitle>
          <DialogDescription>Who will pickup this order?</DialogDescription>
        </DialogHeader>

        <PickupDetailsForm id="pickup-details-form" onSubmitted={() => onContinue?.()} />

        <DialogFooter>
          <Button size="lg" form="pickup-details-form">
            Continue
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
