import React, {useMemo} from 'react';
import {useAppContext} from 'contexts/app.context';
import {Redirect} from 'react-router-dom';
import {paths} from 'utils/paths';
import {DAYS_OF_WEEK} from 'utils/constants';
import {TimeframeRow} from 'components/timeframes/timeframe-row.component';

const SectionOpeningHoursScene: React.FC = () => {
  const {location} = useAppContext();

  if (!location) {
    return <Redirect to={paths.qrScanner.route} />;
  }

  const groupedTimeframes = useMemo(() => {
    const timeframes = location.section.openingHours ?? [];

    return DAYS_OF_WEEK.map((day) => {
      return {
        day,
        timeframes: timeframes.filter(({daysOfWeek}) => !daysOfWeek || !daysOfWeek.length || daysOfWeek.includes(day)),
      };
    });
  }, []);

  return (
    <section className="scene scene-store-opening-hours">
      <h1 className="scene-title">We are closed!</h1>
      <p className="scene-description">Take a look to our opening hours</p>
      {groupedTimeframes.map(({day, timeframes}) => <TimeframeRow key={day} day={day} timeframes={timeframes} />) ?? null}
    </section>
  );
};

export default SectionOpeningHoursScene;
