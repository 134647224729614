import {MinusIcon, PlusIcon, Trash2Icon} from 'lucide-react';

interface QuantityInputProps {
  value: number;
  onChange: (value: number) => void;
  allowDelete?: boolean;
  size?: 'md' | 'lg';
}

export function QuantityInput({value, onChange, allowDelete = true, size = 'md'}: QuantityInputProps) {
  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-rounded-full tw-gap-2 tw-py-0.5 tw-px-1 tw-text-slate-600 tw-border tw-border-slate-300 tw-shadow-sm">
      <button
        disabled={value === 1 && !allowDelete}
        className="tw-p-1 -tw-ml-px tw-rounded-full hover:tw-bg-blue-700 hover:tw-text-white tw-transition-all"
        onClick={() => onChange(value - 1)}
      >
        {value === 1 && allowDelete ? <Trash2Icon className="tw-h-4 tw-w-4" /> : <MinusIcon className="tw-h-4 tw-w-4" />}
      </button>

      <span className="tw-text-xs">{value}</span>
      <button
        className="tw-p-1 -tw-mr-px tw-rounded-full hover:tw-bg-blue-700 hover:tw-text-white tw-transition-all"
        onClick={() => onChange(value + 1)}
      >
        <PlusIcon className="tw-h-4 tw-w-4" />
      </button>
    </div>
  );
}
