import React, {ChangeEvent, FormEvent, useState} from 'react';

import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {PaymentMethod} from 'models/payment-method.model';
import {useMutation, useQueryClient} from 'react-query';
import {SaveStripePaymentMethodRequest, saveStripePaymentMethod} from 'api/payment.api';
import {useAppContext} from 'contexts/app.context';

import {useToast} from 'hooks/use-toast.hook';
import {ApiError} from 'api/api';
import {Input} from 'common/components/Input';
import {Label} from 'common/components/Label';
import {Button} from 'common/components/Button';
import {cn} from 'common/utils';

interface StripeFormProps {
  onCreated: (paymentMethod: PaymentMethod) => void;
}

type StripeFormErrorCode =
  | 'parameter_invalid_empty'
  | 'invalid_number'
  | 'incomplete_number'
  | 'incomplete_expiry'
  | 'invalid_expiry_year_past'
  | 'invalid_expiry_month'
  | 'invalid_cvc'
  | 'incomplete_cvc';

interface StripeFormError {
  code: StripeFormErrorCode;
  message: string;
}

interface StripeFormErrors {
  cardNumber?: StripeFormError;
  expirationDate?: StripeFormError;
  cvc?: StripeFormError;
  cardholderName?: StripeFormError;
}

type StripeFormErrorsKey = keyof StripeFormErrors;

const toast = useToast();

export function StripePaymentMethodForm({onCreated}: StripeFormProps) {
  const {customer} = useAppContext();
  const stripe = useStripe();
  const elements = useElements();

  const [cardholderName, setCardholderName] = useState('');
  const [errors, setErrors] = useState<StripeFormErrors>({});

  const queryClient = useQueryClient();

  const attachPaymentMethodMutation = useMutation<PaymentMethod, ApiError<PaymentMethod>, SaveStripePaymentMethodRequest>({
    mutationFn: (body) => saveStripePaymentMethod(customer!.token, body),
    mutationKey: 'attach-payment-method',
    onSuccess: (paymentMethod) => {
      queryClient.invalidateQueries('payment-methods');
      onCreated?.(paymentMethod);
    },
    onError: (error) => {
      console.log(error);
      toast(error.error?.message ?? 'There was an error with your payment method.', 'error');
    },
  });

  const handleError = (error: StripeFormError) => {
    if (['incomplete_number', 'invalid_number'].includes(error.code)) {
      setErrors((errors) => ({...errors, cardNumber: error}));
    }
    if (['incomplete_expiry', 'invalid_expiry_month', 'invalid_expiry_year_past'].includes(error.code)) {
      setErrors((errors) => ({...errors, expirationDate: error}));
    }
    if (['incomplete_cvc', 'invalid_cvc'].includes(error.code)) {
      setErrors((errors) => ({...errors, cvc: error}));
    }
    if (['parameter_invalid_empty'].includes(error.code)) {
      setErrors((errors) => ({...errors, cardholderName: {code: error.code, message: "You must input the cardholder's name"}}));
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement!,
      billing_details: {
        name: cardholderName,
      },
    });

    if (error) {
      handleError(error as StripeFormError);
    } else if (paymentMethod) {
      attachPaymentMethodMutation.mutate({paymentMethodId: paymentMethod.id});
    }
  };

  const clearError = (fieldName: StripeFormErrorsKey) => {
    setErrors((errors) => {
      const newErrors = {...errors};
      newErrors[fieldName] = undefined;

      return newErrors;
    });
  };

  const onCardholderNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    if (name) {
      clearError('cardholderName');
    }
    setCardholderName(name);
  };

  return (
    <form onSubmit={handleSubmit} id="create-stripe-payment-method-form" className="tw-flex tw-flex-col tw-h-full">
      <div className={cn('kl-input-group', {'kl-input-group-danger': errors.cardholderName})}>
        <div className="tw-mb-1">
          <Label htmlFor="cardholder-name">Cardholder name</Label>
        </div>
        <Input
          placeholder="Your name"
          id="cardholder-name"
          disabled={attachPaymentMethodMutation.isLoading}
          value={cardholderName}
          onChange={onCardholderNameChange}
        />
        {errors.cardholderName ? <p className="kl-input-helper">{errors.cardholderName.message}</p> : null}
      </div>
      <div className={cn('kl-input-group', {'kl-input-group-danger': errors.cardNumber})}>
        <div className="tw-mb-1">
          <Label htmlFor="card-number">Card number</Label>
        </div>
        <CardNumberElement
          options={{showIcon: true, disabled: attachPaymentMethodMutation.isLoading}}
          onBlur={() => clearError('cardNumber')}
          id="card-number"
          className="tw-border tw-border-gray-300 tw-py-2.5 tw-px-2 tw-text-gray-300 tw-shadow-sm tw-rounded-md"
        />
        {errors.cardNumber ? <p className="kl-input-helper">{errors.cardNumber.message}</p> : null}
      </div>
      <div className="tw-flex tw-gap-4 tw-items-start">
        <div className={cn('kl-input-group tw-flex-1', {'kl-input-group-danger': errors.expirationDate})}>
          <div className="tw-mb-1">
            <Label htmlFor="card-expiration-date">Expiration date</Label>
          </div>
          <CardExpiryElement
            options={{disabled: attachPaymentMethodMutation.isLoading}}
            onBlur={() => clearError('expirationDate')}
            id="card-expiration-date"
            className="tw-border tw-border-gray-300 tw-py-2.5 tw-px-2 tw-text-gray-300 tw-shadow-sm tw-rounded-md"
          />
          {errors.expirationDate ? <p className="kl-input-helper">{errors.expirationDate.message}</p> : null}
        </div>
        <div className={cn('kl-input-group tw-flex-1', {'kl-input-group-danger': errors.cvc})}>
          <div className="tw-mb-1">
            <Label htmlFor="card-cvc">Security code</Label>
          </div>
          <CardCvcElement
            options={{disabled: attachPaymentMethodMutation.isLoading}}
            onBlur={() => clearError('cvc')}
            id="card-cvc"
            className="tw-border tw-border-gray-300 tw-py-2.5 tw-px-2 tw-text-gray-300 tw-shadow-sm tw-rounded-md"
          />
          {errors.cvc ? <p className="kl-input-helper">{errors.cvc.message}</p> : null}
        </div>
      </div>
      <div className="tw-mt-auto">
        <Button type="submit" className="tw-w-full" size="lg" disabled={!stripe || attachPaymentMethodMutation.isLoading}>
          Continue
        </Button>
      </div>
    </form>
  );
}
