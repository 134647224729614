import React from 'react';

const faqs = [
  {
    index: 1,
    title: 'What happens if I need to change my order?',
    description:
      'If the order is in received mode you can cancel your order and place your order again by going to the "My Orders" and scrolling down to the bottom where it says "Cancel Order". If it has passed this stage we recommend speaking to your server to change your order.',
  },
  {
    index: 2,
    title: 'I need to cancel my order, what do I need to do?',
    description:
      'If the order is in received mode you can cancel your order from the "My Orders" Tab and scroll down to the bottom where it says "Cancel Order". If it has passed this stage we recommend speaking to your server to see if this cancellation request can still be made.',
  },
  {
    index: 3,
    title: 'I am having problems placing the order - what should I do?',
    description:
      'Please refresh the URL or scan the QR again to start your order again. If a problem still persists then please order directly with your server. If you would like to make us aware of the issue please email support@kontactless.io',
  },
  {
    index: 4,
    title: 'Does this platform offer pay at table service as well?',
    description:
      'Yes it does however some venues choose to utilise only the menu ordering capability without the payment feature.',
  },
  {
    index: 5,
    title: "I'd like to find out more information and sign up for this service at my venue - how would I do that?",
    description:
      "We'd love to have you onboard and are signing up new venues all the time! Please sign up on our website, www.kontactless.io or email us directly at sales@kontactless.io.",
  },
];

export function FAQsPage() {
  return (
    <main className="tw-overflow-y-auto tw-py-12">
      <section className="tw-space-y-6 tw-divide-y tw-mx-auto tw-max-w-4xl tw-w-full tw-px-6">
        <h1 className="tw-text-2xl tw-font-bold tw-leading-10 tw-text-gray-900">Frequently asked questions</h1>
        {faqs.map(({title, description, index}) => (
          <div key={index} className="tw-pt-6">
            <h2 className="tw-text-base tw-font-bold tw-leading-7">{title}</h2>
            <p className="tw-mt-2 tw-text-base tw-leading-7 tw-text-gray-600">{description}</p>
          </div>
        ))}
      </section>
    </main>
  );
}
