import React from 'react';

import {Button} from 'common/components/Button';
import {Input} from 'common/components/Input';
import {cn} from 'common/utils';

import {useForm} from 'react-hook-form';

interface CouponCodeFormFields {
  couponCode: string;
}

interface CouponCodeFormProps {
  defaultValues?: CouponCodeFormFields;
  onSubmit: (formData: CouponCodeFormFields) => void;
  className?: string;
  disabled?: boolean;
}

export function CouponCodeForm({defaultValues, onSubmit, className, disabled}: CouponCodeFormProps) {
  const {register, handleSubmit, formState} = useForm<CouponCodeFormFields>({defaultValues, mode: 'onChange'});

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cn('tw-flex tw-items-center tw-justify-between tw-gap-2', className)}>
      <Input
        className="tw-uppercase"
        placeholder="Enter your promo code"
        type="text"
        {...register('couponCode', {required: true})}
      />
      <Button disabled={!formState.isValid || disabled} type="submit">
        Apply
      </Button>
    </form>
  );
}
