import {DateTime} from 'luxon';
import {useState} from 'react';
import {useQuery} from 'react-query';

export const useCountdown = (dateTime?: DateTime, callback?: () => void) => {
  const [callbackInvoked, setCallbackInvoked] = useState(false);

  const timeLeft = useQuery({
    queryFn: () => getTimeRelative(dateTime),
    refetchInterval: 1000,
    initialData: getTimeRelative(dateTime),
    enabled: !callbackInvoked && !!dateTime,
    onSuccess: () => {
      if (!callbackInvoked) {
        const diff = dateTime?.diffNow().as('minutes');

        if (diff != null && diff < 0) {
          setCallbackInvoked(true);
          callback?.();
        }
      }
    },
  });

  return timeLeft.data === 'Ended' ? null : timeLeft.data?.replace('in ', '');
};

const getTimeRelative = (dateTime?: DateTime) => {
  if (!dateTime) {
    return undefined;
  }

  if (dateTime < DateTime.now()) {
    return 'Ended';
  }

  if (dateTime.diffNow().as('hours') < 1) {
    return `in ${dateTime?.toRelative()}`;
  }

  if (dateTime < DateTime.now().endOf('day')) {
    return `at ${dateTime.toFormat('h:mm a ZZZZ')}`;
  }

  if (dateTime < DateTime.now().plus({days: 1}).endOf('day')) {
    return `${dateTime.toRelativeCalendar()} at ${dateTime.toFormat('h:mm a ZZZZ')}`;
  }

  return `on ${dateTime.toFormat("MMM dd 'at' h:mm a ZZZZ")}`;
};
