import React from 'react';

import {useScrollStack} from 'hooks/use-scroll-stack.hook';
import {AppContextProvider} from './contexts/app.context';
import {ReactQueryProvider} from './contexts/react-query.context';

export const AppWrapper: React.FC = ({children}) => {
  useScrollStack();

  if (window.location.pathname.includes('/admin')) {
    window.location.assign(`${process.env.REACT_APP_ADMIN_APP_HOST}${window.location.pathname.replace('/admin', '')}`);
    return null;
  }

  return (
    <ReactQueryProvider>
      <AppContextProvider>{children}</AppContextProvider>
    </ReactQueryProvider>
  );
};
