import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import * as yup from 'yup';
import {useAppContext} from 'contexts/app.context';
import {Label} from 'common/components/Label';
import {Input} from 'common/components/Input';
import {useToast} from 'hooks/use-toast.hook';
import {useMutation} from 'react-query';
import {Customer, CustomerUpdateRequest} from 'models/customer.model';
import {updateCustomerRequest} from 'api/customer.api';
import {useYupResolver} from 'hooks/use-yup-resolver.hook';
import {getCustomerFullName} from 'utils/helpers.utils';
import {capitalize} from 'lodash';

interface LocationDetailsForm {
  customerName: string;
  [key: string]: string;
}

interface LocationDetailsFormProps extends React.HTMLProps<HTMLFormElement> {
  onSubmitted?: () => void;
  locationAttributes: Array<{name: string; required: boolean}>;
  locationType: string;
  prefixInputLabels?: boolean;
}

export const LocationDetailsForm = React.forwardRef<HTMLFormElement, LocationDetailsFormProps>(
  ({onSubmitted, locationAttributes, locationType, prefixInputLabels = false, ...formProps}, ref) => {
    const {cart, customer, setContextState} = useAppContext();
    const toast = useToast();

    const updateCustomerMutation = useMutation<Customer, Error, CustomerUpdateRequest>((body) =>
      updateCustomerRequest(customer!.token, body)
    );

    const resolver = useYupResolver(
      yup.object().shape(
        locationAttributes.reduce(
          (shape, attribute) => {
            return {
              ...shape,
              [attribute.name]: attribute.required && yup.string().required(),
            };
          },
          {customerName: yup.string().required()}
        )
      )
    );

    const {
      handleSubmit,
      register,
      reset,
      formState: {errors},
    } = useForm<LocationDetailsForm>({resolver});

    const onSubmit = async (locationAttributeForm: LocationDetailsForm) => {
      try {
        const {customerName} = locationAttributeForm;
        const fullName = customerName.split(' ');
        let updatedCustomer: Customer = {
          ...customer!,
        };

        if (customerName !== getCustomerFullName(customer)) {
          updatedCustomer = await updateCustomerMutation.mutateAsync({
            ...customer,
            firstName: capitalize(fullName[0]),
            lastName: fullName.length > 1 ? capitalize(fullName[fullName.length - 1]) : '',
          });
        }

        setContextState({
          cart: {
            ...cart!,
            locationDetails: {
              type: locationType,
              attributes: Object.entries(locationAttributeForm)
                .filter(([name]) => name !== 'customerName')
                .map(([name, value]) => ({name, value})),
            },
          },
          customer: {...customer!, ...updatedCustomer},
        });

        reset(
          locationAttributes.reduce((values, attribute) => ({...values, [attribute.name]: ''}), {
            customerName: getCustomerFullName(updatedCustomer),
          })
        );

        onSubmitted?.();
      } catch (error) {
        toast('There was an error. Please try again.', 'error');
      }
    };

    useEffect(() => {
      const locationAttributeForm: LocationDetailsForm = {customerName: getCustomerFullName(customer)};
      for (const {name, value} of cart?.locationDetails?.attributes ?? []) {
        locationAttributeForm[name] = value;
      }
      reset(locationAttributeForm);
    }, [cart, customer]);

    const getInputLabel = (locationType: string, attributeName: string) => {
      if (prefixInputLabels) {
        return `${locationType} ${attributeName.toLowerCase()}`;
      }

      return capitalize(attributeName);
    };

    return (
      <form ref={ref} onSubmit={handleSubmit(onSubmit)} {...formProps} className="tw-mb-6">
        <div className={classNames('kl-input-group', {'kl-input-group-danger': errors.customerName})}>
          <Label htmlFor="customerName">Name</Label>
          <div className="tw-mt-1">
            <Input autoFocus id="customerName" type="text" {...register('customerName')} />
          </div>
          {errors.customerName ? <p className="kl-input-helper">Name is required</p> : null}
        </div>

        {locationAttributes.map(({name}, index) => (
          <div key={index} className={classNames('kl-input-group', {'kl-input-group-danger': errors.customerName})}>
            <Label htmlFor={name}>{getInputLabel(locationType, name)}</Label>
            <div className="tw-mt-1">
              <Input autoFocus id={name} type="text" {...register(name)} />
            </div>
            {errors[name] ? <p className="kl-input-helper">{name} is required</p> : null}
          </div>
        ))}
      </form>
    );
  }
);

LocationDetailsForm.displayName = 'L';
