import React, {useMemo} from 'react';
import {TimeframeRow} from 'components/timeframes/timeframe-row.component';
import {useAppContext} from 'contexts/app.context';
import {DAYS_OF_WEEK} from 'utils/constants';
import {Redirect} from 'react-router-dom';
import {paths} from 'utils/paths';

const StoreOpeningHoursScene: React.FC = () => {
  const {location} = useAppContext();

  if (!location) {
    return <Redirect to={paths.qrScanner.route} />;
  }

  const groupedTimeframes = useMemo(() => {
    const timeframes = location.section.store?.openingHours ?? [];

    return DAYS_OF_WEEK.map((day) => {
      return {
        day,
        timeframes: timeframes.filter(({daysOfWeek}) => !daysOfWeek || !daysOfWeek.length || daysOfWeek.includes(day)),
      };
    });
  }, []);

  return (
    <section className="scene scene-store-opening-hours">
      <header>
        <h1 className="scene-title">We are closed!</h1>
        <p className="scene-description">Take a look to our opening hours</p>
      </header>
      <div className="content">
        {groupedTimeframes.map(({day, timeframes}) => <TimeframeRow key={day} day={day} timeframes={timeframes} />) ?? null}
      </div>
    </section>
  );
};

export default StoreOpeningHoursScene;
