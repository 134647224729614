import React from 'react';
import {Callout, Spinner} from '@blueprintjs/core';
import {DateTime} from 'luxon';
import {useQuery} from 'react-query';
import {fetchOrderRequest} from '../../api/order.api';

import {getCustomerFullName, getPriceText} from '../../utils/helpers.utils';

import {Link, useParams} from 'react-router-dom';
import {useAppContext} from 'contexts/app.context';
import {ArrowLeft} from 'lucide-react';

export function OrderReceiptScene() {
  const params = useParams<{companyId: string; storeId: string; orderId: string}>();
  const {customer} = useAppContext();

  const orderQuery = useQuery(['orders', params.orderId], {
    queryFn: () => fetchOrderRequest(customer!.token, Number(params.orderId)),
  });

  const order = orderQuery.data;

  return (
    <>
      {orderQuery.isLoading && (
        <section className="tw-h-screen tw-flex tw-items-center tw-justify-center">
          <Spinner intent="primary" />
        </section>
      )}
      {orderQuery.isError && (
        <Callout className="scene-error" intent="danger">
          An error ocurred fetching the order
        </Callout>
      )}
      {order && (
        <div className="tw-max-w-2xl tw-mx-auto tw-p-4 tw-w-full tw-overflow-y-auto">
          <Link to="./" className="tw-mt-4">
            <div className="tw-flex tw-gap-2 tw-items-center tw-max-w-max tw-bg-blue-500 tw-text-white hover:tw-text-white hover:tw-no-underline tw-p-1 tw-px-2 tw-rounded tw-text-sm">
              <ArrowLeft size={16} />
              Go Back
            </div>
          </Link>
          <section className="tw-my-8 tw-rounded-lg tw-border tw-bg-white tw-border-gray-200 print:tw-shadow-none tw-shadow-lg tw-border-solid tw-p-8 tw-space-y-4">
            <div>
              <p className="tw-font-bold">Order #{order.number}</p>
              <p>{DateTime.fromISO(order.createdAt).setZone(order.store.timezone).toFormat('MMMM d, yyyy - HH:mm a')}</p>
            </div>

            <hr className="tw-border-b tw-border-solid tw-border-gray-200" />

            <div className="tw-flex tw-justify-between tw-text-sm">
              <div>
                <p className="tw-font-semibold">{order.store.name}</p>
                <p>{order.store.address?.formattedAddress}</p>
                <p>{order.store.address?.phone}</p>
              </div>
              <div className="tw-text-right">
                <p className="tw-font-semibold">{getCustomerFullName(customer) ?? order.payment?.cardName}</p>
                <p>{customer?.phone}</p>
              </div>
            </div>

            <hr className="tw-border-b tw-border-solid tw-border-gray-200" />

            <div>
              <h4 className="tw-font-semibold tw-mb-4">Summary</h4>
              <ul className="tw-space-y-3">
                {order.items?.map((item) => (
                  <li
                    key={item.id}
                    className="tw-flex tw-justify-between tw-border-solid tw-border-b tw-border-gray-200 tw-pb-3 last:tw-border-none items-center"
                  >
                    <div>
                      {item.quantity}x {item.title}
                    </div>
                    <div>{getPriceText(item.subtotal)}</div>
                  </li>
                ))}
                <li className="tw-flex tw-justify-between tw-font-semibold items-center">
                  <div>Total</div>
                  <div>{getPriceText(order.total)}</div>
                </li>
              </ul>
            </div>
          </section>
        </div>
      )}
    </>
  );
}
