import React, {useEffect, useState} from 'react';

import {createFreedompayWalletPaymentIntent, fetchFreedompayWalletPaymentButtonIframe} from 'api/payment.api';
import {useAppContext} from 'contexts/app.context';
import {useStateRef} from 'hooks/use-state-ref.hook';
import {OrderSummary, OrderSummaryItem} from 'models/order.model';
import {WalletPaymentIntentData} from 'models/payment-method.model';

import {WalletBrand} from 'utils/types';

interface FreedompayWalletPaymentButton {
  onPaymentSuccess: (walletPaymentData: WalletPaymentIntentData, cardholderName?: string) => void;
  onPaymentFailed: () => void;
  orderItems: OrderSummaryItem[];
  orderTotals: Pick<OrderSummary, 'total' | 'discountsSubtotal' | 'feesSubtotal' | 'surchargesSubtotal' | 'tipsSubtotal' | 'tax'>;
}

export const FreedompayWalletPaymentButton: React.FC<FreedompayWalletPaymentButton> = ({
  onPaymentSuccess,
  onPaymentFailed,
  orderItems,
  orderTotals,
}) => {
  const {location, customer, event} = useAppContext();

  const [iframe, setIframe] = useState('');
  const [, setSessionKey, sessionKeyRef] = useStateRef('');
  const [iframeHeight, setIframeHeight] = useState(0);

  const fetchIframe = async () => {
    try {
      const freedompaySdk = (window as any).FreedomPay;
      let isApplepaySupported = false;
      let applepayCanMakePayments = false;
      if (freedompaySdk) {
        isApplepaySupported = freedompaySdk.Apm.ApplePay.isSupported();
        applepayCanMakePayments = freedompaySdk.Apm.ApplePay.canMakePayments();
      }
      let walletBrand: WalletBrand;
      if (isApplepaySupported && applepayCanMakePayments) {
        walletBrand = 'apple-pay';
      } else {
        walletBrand = 'google-pay';
      }
      const response = await fetchFreedompayWalletPaymentButtonIframe(customer?.token!, {
        walletBrand,
        amount: orderTotals.total,
        storeId: (location?.section.storeId || event?.storeId)!,
      });
      setIframe(response.iframe);
      setSessionKey(response.sessionKey);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchIframe();
    window.addEventListener('message', handlePaymentSubmit);
    return () => {
      window.removeEventListener('message', handlePaymentSubmit);
    };
  }, [orderTotals.total]);

  const handlePaymentSubmit = (e: MessageEvent) => {
    // Since this is a handler for the "message" event of the window object, we need to check that who originated this event was Freedompay's HPC SDK or our domains
    if (
      e.origin === 'https://hpc.uat.freedompay.com' ||
      e.origin === 'https://hpc.freedompay.com' ||
      e.origin.indexOf('10.10') >= 0 ||
      // TODO: Move URLs below to a single env var
      e.origin === 'https://dev.app.kless.io' ||
      e.origin === 'https://app.kontactless.io'
    ) {
      const message = e.data;

      // message.data varies in function of the message.type
      switch (message.type) {
        case 1:
          // TODO: catch error with sentry
          alert(message.data.errors[0]?.message);
          break;
        case 2: // Iframe height
          const {height} = message.data;
          setIframeHeight(height);
          break;
        case 3: // payment successful
          const {data} = message;
          createPaymentIntent(data);
          break;
      }
    }
  };

  // TODO: Improve this typing. Check Freedompay docs.
  const createPaymentIntent = async (data: {paymentType: string; attributes: any[]; paymentKeys: string[]}) => {
    try {
      let cardholderName = '';

      if (data.paymentType === 'GooglePay') {
        const billingAddress = data.attributes.filter((attr: any) => attr.Key === 'BillingAddress')[0];
        if (billingAddress) {
          cardholderName = billingAddress.Value.name;
        }
      } else if (data.paymentType === 'ApplePay') {
        const billingAddress = data.attributes.filter((attr: any) => attr.Key === 'billingContact')[0];
        if (billingAddress) {
          const addressObject = JSON.parse(billingAddress.Value);
          cardholderName = `${addressObject.givenName} ${addressObject.familyName}`;
        }
      }

      const [paymentKey] = data.paymentKeys;
      const response = await createFreedompayWalletPaymentIntent(customer?.token!, {
        orderItems,
        orderTotals,
        cardholderName,
        storeId: (location?.section.storeId || event?.storeId)!,
        paymentKey,
        sessionKey: sessionKeyRef.current,
      });

      onPaymentSuccess(
        {
          freedompayInvoiceNumber: response.invoiceNumber,
          freedompayMerchantReferenceCode: response.merchantReferenceCode,
          freedompayRequestId: response.requestId,
        },
        cardholderName
      );
    } catch (error) {
      console.error(error);
      onPaymentFailed();
    }
  };

  if (!iframe) {
    return null;
  }

  return (
    <div
      style={{height: iframeHeight}}
      dangerouslySetInnerHTML={{
        __html: iframe,
      }}
    />
  );
};
