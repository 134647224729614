import {useEffect, useState} from 'react';

import {useStripe} from '@stripe/react-stripe-js';
import {PaymentMethod} from 'models/payment-method.model';

import {WalletBrand} from 'utils/types';

const dummyPaymentRequestPayload = {
  country: 'US',
  currency: 'usd',
  total: {
    label: 'Dummy payment',
    amount: 50,
  },
  requestPayerName: true,
  requestPayerEmail: true,
};

export function useStripeWalletPaymentMethod(): PaymentMethod | null {
  const stripe = useStripe();
  const [walletBrand, setWalletBrand] = useState<WalletBrand | undefined>();

  useEffect(() => {
    if (stripe) {
      // This payment request is only used to check the wallet availability
      const dummyPaymentRequest = stripe.paymentRequest(dummyPaymentRequestPayload);

      dummyPaymentRequest.canMakePayment().then((result) => {
        if (result?.googlePay) {
          setWalletBrand('google-pay');
        } else if (result?.applePay) {
          setWalletBrand('apple-pay');
        }
      });
    }
  }, [stripe]);

  if (!walletBrand) {
    return null;
  }

  return {
    stripePaymentMethodId: 'wallet',
    cardBrand: walletBrand,
    cardCountry: 'US', // TODO: This might have to be gathered
    cardExpirationYear: 0,
    cardExpirationMonth: 0,
    cardLastFourDigits: '0000',
    cardholderName: '',
    isWallet: true,
  };
}
