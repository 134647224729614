import {cn, tw} from 'common/utils';
import * as React from 'react';

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({className, ...props}, ref) => {
  return (
    <textarea
      className={cn(
        tw`tw-flex tw-w-full tw-shadow-sm tw-rounded-md tw-border tw-border-gray-300 tw-bg-transparent tw-py-2 tw-px-3 tw-text-sm placeholder:tw-text-slate-400 focus:tw-outline-none focus:tw-ring-blue-500 disabled:tw-cursor-not-allowed disabled:tw-opacity-50`,
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
Textarea.displayName = 'Textarea';

export {Textarea};
